
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import { useFetch } from "../../hooks/useFetch";
import HttpStatus from "../../utils/HttpStatus";

export const ApproveRecruit = ({ show, setShow, data, setData, refresh, setRefresh, allQuota }) => {
    const { fetchData } = useFetch();
    const actualQuota = allQuota.filter((quota) => quota.branch_id === data.rec_askBranch && quota.dep_id === data.rec_askDept && quota.id_jobtitle === data.rec_askPoste)

    const handleTypeClose = () => {
        setShow(false)
    }

    const updateQuota = async () => {
        const total = (parseInt(data.rec_orgEcart, 10) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0, 10)) === 0 ? data.rec_askNbreAgent : (parseInt(data.rec_orgEcart, 10) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0, 10))
        const response = await fetchData("POST", "recrut/updateQuota", {
            id_branch: actualQuota[0].branch_id, id_department: actualQuota[0].dep_id, id_jobtitle: actualQuota[0].id_jobtitle, actualQuotaValue: total
        });
        if (response.status === HttpStatus.OK) {
            handleTypeClose()
            setRefresh(!refresh)
        }
    }

    const addNewpost = async () => {
        const response = await fetchData("POST", "recrut/addNewPost", {
            data,
        });
        if (response.status === HttpStatus.OK) {
            handleTypeClose()
            setRefresh(!refresh)
        }
    }

    const addQuota = async () => {
        console.log('first')
        const quota = (parseInt(data.rec_orgEcart) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0)) === 0
            ? data.rec_askNbreAgent
            : (parseInt(data.rec_orgEcart) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0))
        const response = await fetchData("POST", "recrut/addQuota", {
            depQuota: {
                id_branch: data.rec_askBranch,
                id_department: data.rec_askDept,
                id_jobtitle: data.rec_askPoste,
                quota_value: quota
            }
        });
        if (response.status === HttpStatus.OK) {
            console.log("Quota successfully added");
        } else {
            console.log("Something went wrong");
        }
    }

    const addHr = async () => {
        const response = await fetchData("POST", "recrut/addHr", { data });
        if (response.status === HttpStatus.OK) {
            console.log(data.rec_askPosteText, data.rec_orgEcart)
            if (data.rec_askPosteText !== null)
                addNewpost()
            else if (data.rec_orgEcart !== null && actualQuota.length > 0)
                updateQuota()
            else if (actualQuota.length === 0)
                addQuota();

            handleTypeClose()
            setRefresh(!refresh)
        }
    };


    return (
        <Modal show={ show } onHide={ handleTypeClose } centered size="lg" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    APPROBATION DE LA DEMANDE
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={ { overflow: 'auto', maxHeight: '500px' } }>
                    <div className="w-full p-0">

                        <div className="d-flex p-3 w-full text-lg font-semibold mr-2  "> <div className="w-4"> <span className="fs-16">Demandeur : </span></div> <div className="w-8 p-1">{ data.e_name } </div></div>
                        <div className="d-flex p-3 w-full "> <div className="w-4"><span className="fs-16">Departement : </span> </div> <div className="w-8 p-1">{ data.dep_name } </div></div>
                        {
                            (data.rec_orgEcart && data.rec_orgEcart > 0) || data.rec_askPosteText !== null ?
                                <div className="d-flex content-center p-3 w-6 ">

                                    <div className="flex d-flex col-grid">
                                        <div className="w-8 mt-1 p-2">
                                            <div className="w-4 mt-2">
                                                <span className="fs-16 mr-2 ">Actual Quota: </span>
                                            </div>
                                            <input disabled type="number" className="p-2 w-95px" value={ actualQuota.length > 0 ? actualQuota[0].quota_value : 0 } onChange={ (e) => setData(current => {
                                                return ({
                                                    ...current,
                                                    rec_orgEcart: parseInt(e.target.value, 10),
                                                    changeCota: true
                                                })
                                            }) } />
                                        </div>

                                        <div className="w-8 mt-1 p-2"><div className="w-4 mt-2">
                                            <span className="fs-16 mr-2 ">Over Quota: </span>
                                        </div>
                                            <input type="number" min={ 1 } className="p-2 w-95px" inputmode="numeric" value={ data.rec_orgEcart === 0 ? data.rec_askNbreAgent : data.rec_orgEcart } onChange={ (e) => setData(current => {
                                                return ({
                                                    ...current,
                                                    rec_orgEcart: parseInt(e.target.value, 10),
                                                    changeCota: true
                                                })
                                            }) } />
                                        </div>

                                        <div className="w-8 mt-1 p-2"><div className="w-4 mt-2"><span className="fs-16 mr-2 ">Total: </span> </div>
                                            <input type="number" className="p-2 w-95px" value={ (parseInt(data.rec_orgEcart, 10) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0, 10)) === 0 ? data.rec_askNbreAgent : (parseInt(data.rec_orgEcart, 10) + parseInt(actualQuota.length > 0 ? actualQuota[0].quota_value : 0, 10)) } disabled />
                                        </div>
                                    </div>

                                </div> : null
                        }
                        {
                            data.rec_askPosteText !== null ?
                                <div className=" ml-3">

                                    <div className="mt-5">
                                        <div className="fs-15 mr-2 text-black">Nouveau Poste : </div>
                                        <input type='text' className="p-2" value={ data.rec_askPosteText ? data.rec_askPosteText : "" }
                                            onChange={ (e) => setData(current => {
                                                return ({
                                                    ...current,
                                                    rec_askPosteText: e.target.value
                                                })
                                            }) } />
                                    </div>

                                    <div className="mb-2 mt-5 text-danger"> Cette demande est liée à un post non existant... </div>
                                    <div className="text-info mb-2"> En approuvant, ce poste sera directement enregistré dans le systeme en cas de besoin vous pouvez l'editer... </div>

                                </div> : actualQuota.length === 0 ?

                                    <div className="text-danger ml-5"> Aucun quota n'est enregistré pour ce departement la valeur du tatal sera enregistrée comme nouveau quota... </div> : null
                        }

                    </div>
                </div>
                {/* <FicheApprove componentRef={componentRef} action={'view'} data={data} setData={setData} jobDescData={jobDescData} classnames={printContainer} /> */ }
            </Modal.Body>
            <Modal.Footer>
                <Button ref={ null } onClick={ () => {
                    // changeStatus(data.rec_id, null, 10, data.rec_orgEcart);
                    addHr()
                    // handleTypeClose();
                } } variant="success">
                    Approuver
                </Button>
                <Button ref={ null } onClick={ handleTypeClose } variant="primary">
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}