/* eslint-disable no-unused-expressions */
import MenuTestPage from "./header_mobile";
import Footer from "./footer";

import { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { RiFilterOffFill } from "react-icons/ri";
import FilterModal from "./Filter";
import { useFetch } from "./hooks/useFetch";
import HttpStatus from "./utils/HttpStatus";
import { jwtDecode } from "jwt-decode";
import UserPermission from "./UserPermission";
import DataContext from "../context/DataProvider";
import { useNavigate } from "react-router-dom";

function Users(props) {
    //Variable part
    const { fetchData } = useFetch();
    const navigate = useNavigate();
    const { perms, getPerm } = useContext(DataContext)
    const [employee, setemployee] = useState("");
    const [all_employee, setallemployee] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const linePerPage = 10;
    const [showmodal_status, setshowmodal_status] = useState(false);
    const [showmodal_permission, setshowmodal_permission] = useState(false);
    const [showmodal_resetPassword, setshowmodal_resetPassword] = useState(false);

    const [usertype, setusertype] = useState(-1);
    const [message_status, setmessage_status] = useState(-1);
    const [message_mail, setmessage_mail] = useState();
    const [message_newpassword, setmessage_newpassword] = useState();
    const [new_password, setnew_password] = useState("");
    const [actualise, setnew_password1] = useState(0);
    const [showmodal_manager, setshowmodal_manager] = useState(false);
    const [usermail, setusermail] = useState();
    const [fixed_employeeètype, setfixed_employeeètype] = useState(null);

    const [refresh, setrefresh] = useState(0);
    const [managerid, setmanagerid] = useState(0);
    const [managerName, setmanagerName] = useState();
    const [message_manager, setmessage_manager] = useState();
    const [emp_id, setemp_id] = useState();
    const [employeeInfo, setemployeeInfo] = useState();

    function refreshing() {
        setrefresh(refresh + 1);
    }

    const [perm100, setperm100] = useState(0);
    const [perm101, setperm101] = useState(0);
    const [perm102, setperm102] = useState(0);
    const [perm103, setperm103] = useState(0);
    const [perm104, setperm104] = useState(0);
    const [perm105, setperm105] = useState(0);
    const [perm106, setperm106] = useState(0);

    //SHAMS
    const [list, setList] = useState({});
    const [filterModal, setFilterModal] = useState(false);
    const [search, setSearch] = useState(null);
    const [filt_col, setFilt_col] = useState("");
    const [title, setTitle] = useState("");

    const [perm, setPerm] = useState([]);
    const [perm1, setPerm1] = useState([]);


    const filteredList = all_employee.filter((x) => {
        const val = search?.toLowerCase();
        if (!search) {
            return true;
        } else {
            const columnName = filt_col;
            const columnValue = x[columnName];
            if (columnValue || columnValue === 0) {
                if (typeof columnValue === "string") {
                    return columnValue.toLowerCase().includes(val);
                } else if (typeof columnValue === "number") {
                    return columnValue === parseInt(val, 10);
                }
                return null;
            }
            return null;
        }
    });

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const [permissions, setPermissions] = useState({
        permission: {
            perm100: false,
            perm101: false,
            perm102: false,
            perm103: false,
            perm104: false,
            perm105: false,
            perm106: false,
        },
    });

    const loadPermissionData = async () => {
        const a = jwtDecode(sessionStorage.getItem("accessToken"));
        const response = await fetchData("POST", "user/getall_empl_permisssions", {
            emp_id: a.id,
        });
        if (response.status === HttpStatus.OK) {
            var prevPerm = permissions;

            var json = response.data.result;

            Object.keys(json).forEach(function (key) {
                if (json[key].user_perm_perm_code === 100) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per100 = true;
                        setPermissions(prevPerm);
                    }
                }

                if (json[key].user_perm_perm_code === 101) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per101 = true;
                        setPermissions(prevPerm);
                    }
                }
                if (json[key].user_perm_perm_code === 102) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per102 = true;
                        setPermissions(prevPerm);
                    }
                }
                if (json[key].user_perm_perm_code === 103) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per103 = true;
                        setPermissions(prevPerm);
                    }
                }
                if (json[key].user_perm_perm_code === 104) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per104 = true;
                        setPermissions(prevPerm);
                    }
                }
                if (json[key].user_perm_perm_code === 105) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per105 = true;
                        setPermissions(prevPerm);
                    }
                }
                if (json[key].user_perm_perm_code === 106) {
                    if (json[key].user_perm_value === 1) {
                        prevPerm.permission.per106 = true;
                        setPermissions(prevPerm);
                    }
                }
            });
        } else {
        }
    };

    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = filteredList.slice(indexOfFirstPost, indexOfLastPost);

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage === totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination" style={{ cursor: "pointer" }}>
                        <li className="page-item">
                            {
                                <div onClick={() => paginate(1)} className="p-1 pl-2 pr-2">
                                    {"«"}
                                </div>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage === 1 ? (
                                <div className="p-1 pl-2 pr-2" disabled>
                                    {"<"}
                                </div>
                            ) : (
                                <div onClick={() => paginate(currentPage - 1)} className="p-1 pl-2 pr-2">
                                    {"<"}
                                </div>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage === number ? (
                                    <div onClick={() => paginate(number)} className="bg-red p-1 pl-2 pr-2">
                                        {number}
                                    </div>
                                ) : (
                                    <div onClick={() => paginate(number)} className="p-1 pl-2 pr-2">
                                        {number}
                                    </div>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage === totalPages ? (
                                <div className="p-1 pl-2 pr-2" disabled>
                                    {">"}
                                </div>
                            ) : (
                                <div onClick={() => paginate(currentPage + 1)} className="p-1 pl-2 pr-2">
                                    {">"}
                                </div>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <div onClick={() => paginate(totalPages)} className="p-1 pl-2 pr-2">
                                    {"»"}
                                </div>
                            }
                        </li>
                    </ul>
                </nav >
            </>
        );
    };

    const tableRef = useRef(null);

    function handleTypeClose() {
        setshowmodal_status(false);
        setshowmodal_permission(false);
        // setshowmodal_resetPassword(false);
        // setshowmodal_manager(false);
        // setnew_password("");
        // setusermail("");
        // setnew_password1("");
        // refreshing();
    }
    async function changeManager() {
        if (message_manager === "") {
            const response = await fetchData("POST", "employee/change_manager", {
                id: emp_id,
                manager_id: managerid,
            });
            if (response.status === HttpStatus.OK) {
                notifySuccess("Le manager a été changé");
                setmessage_manager("");
                // handleTypeClose();
            } else {
                notifyError("Probleme de connexion");
            }
        }
    }

    const change_status = async () => {
        if (usertype === "-1") {
            setmessage_status("Choose a new status");
        } else {
            setmessage_status("");
        }
        if (usertype === 1) {
            if (usermail === "") {
                setmessage_mail("Enter a email...");
            } else {
                setmessage_mail("");
            }
            if (new_password === "") {
                setmessage_newpassword("Enter the password");
            } else {
                setmessage_newpassword("");
            }
        }

        const response = await fetchData("POST", "user/change_status", {
            new_status: usertype,
            new_password: new_password,
            email: usermail,
            perm100: perm100,
            perm101: perm101,
            perm102: perm102,
            perm103: perm103,
            perm104: perm104,
            perm105: perm105,
            perm106: perm106,
            emp_id: employee,
        });

        if (response.status === HttpStatus.OK) {

            notifySuccess("User status has been changed");
            setshowmodal_status(false);
            handleTypeClose();
            setnew_password("")
            refreshing()

        }


    };
    async function change_password() {


        const response = await fetchData("POST", "user/change_password", {
            new_password: new_password,
            emp_id: employee,
        });

        if (response.status === HttpStatus.OK) {

            notifySuccess("User password has been changed");
            setshowmodal_resetPassword(false);
            handleTypeClose();

        }


    }

    const joinTables = (tableauA, tableauB, keyA, keyB) => {
        const mapA = new Map(tableauA.map((item) => [item[keyA], item]));
        return tableauB.map((itemB) => ({
            ...itemB,
            perm_name: mapA.get(itemB[keyB])?.perm_name || "N/A",
        }));
    };
    async function get_all_empl_permission(emp_id) {

        const response1 = await fetchData("POST", "user/getall_empl_permisssions", {
            emp_id: emp_id,
        });

        const response2 = await fetchData("POST", "user/getall_permisssions", {
            id: emp_id,
        });
        if (response2.status === HttpStatus.OK) {
            setPerm(response1.data.result);

            const joinedData = joinTables(
                response2.data.listPerm,
                response1.data.result,
                "prem_id",
                "user_perm_perm_code"
            );

            setPerm1(joinedData);
            for (let i = 0; i < response2.data.length; i++) {
                if (response2.data[i].user_perm_perm_code === 100)
                    setperm100(response2.data[i].user_perm_value);

                if (response2.data[i].user_perm_perm_code === 101)
                    setperm101(response2.data[i].user_perm_value);

                if (response2.data[i].user_perm_perm_code === 102)
                    setperm102(response2.data[i].user_perm_value);

                if (response2.data[i].user_perm_perm_code === 103)
                    setperm103(response2.data[i].user_perm_value);
                if (response2.data[i].user_perm_perm_code === 104)
                    setperm104(response2.data[i].user_perm_value);
                if (response2.data[i].user_perm_perm_code === 105)
                    setperm105(response2.data[i].user_perm_value);
                if (response2.data[i].user_perm_perm_code === 106)
                    setperm106(response2.data[i].user_perm_value);
            }
        }
    }

    async function handleUpdate(d) {
        await setemployee(d.e_id);
        await setshowmodal_status(true);
        await setusertype(d.e_employee_type);
        await setfixed_employeeètype(d.e_employee_type);
    }
    async function handlemanager(d) {
        await setemp_id(d.e_id);
        await setmanagerid(d.manager_id);
        await setmanagerName(d.manager_name);
        setshowmodal_manager(true);
    }
    async function handlerPermission(d) {
        await get_all_empl_permission(d.e_id);

        await setemployee(d.e_id);

        await setemployeeInfo(d);

        await setshowmodal_permission(true);
    }
    async function handlergetmanager() {
        const response = await fetchData("POST", "employee/getmanagerinfo", {
            id: managerid,
        });
        if (response.status === HttpStatus.OK) {
            setmanagerName(response.data.result.rows[0].e_name);
            setmessage_manager("");
        } else {
            setmessage_manager("Aucun employée ne correspod a l'ID");
        }
    }
    function handleresetpassword(d) {
        setemployee(d.e_id);
        setnew_password(makeid(5));
        setshowmodal_resetPassword(true);
    }
    async function update_permissions() {
        const response = await fetchData("POST", "user/updatepermission", {
            perm1,
            emp_id: employee,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("Les permissions ont étées mise à jour");
            handleTypeClose();
        } else {
        }

        // Axios.post(baseurl + "/?p=updatepermission", {123456
        //   perm1,
        //   emp_id: employee,
        // }).then((response) => {
        //   if (response.data.status) {
        //     notifySuccess("Les permissions ont étées mise à jour");
        //     handleTypeClose();
        //   } else notifyError(response.data.err);
        // });
    }

    function makeid(length) {
        let result = "";
        const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const getAllData = async () => {

        if (!perms['101'])
            return navigate('/home')
        const response = await fetchData("POST", "employee/get_all_employee");
        if (response.status === HttpStatus.OK) {
            setallemployee(response.data.result.data);
        }
        const response1 = await fetchData("POST", "employee/getDetailFilter");
        if (response1.status === HttpStatus.OK) {
            setList(response1.data);
        }
    }

    useLayoutEffect(() => {
        const load = async () => {
            await getPerm()
            await refreshing()
        }
        load()
    }, [])

    useEffect(() => {
        if (refresh > 0)
            getAllData()
    }, [actualise, refresh]);

    return (
        <>
            <MenuTestPage />
            {/* Add task modal */}
            <Modal show={showmodal_resetPassword} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to reset {employee} 's password?</p>
                    <p>
                        if yes the new password will be{" "}
                        <span className="font-weight-bold ">
                            {" "}
                            <h4 className="< mt-10 >">{new_password}</h4>
                        </span>{" "}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={change_password}>
                        Reset password
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showmodal_status} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Changer le status de l'employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Employée<span className="text-danger pl-3">*</span>
                            </label>
                            <select
                                id="task-cat"
                                value={employee}
                                disabled
                                className="form-control rounded-0 cursor-pointer readonly"
                            >
                                <option key={0} value={0}></option>
                                {all_employee ? (
                                    all_employee.map((d, index) => (
                                        <option key={d.e_id} value={d.e_id}>
                                            {" "}
                                            {d.e_id + " - " + d.e_name}{" "}
                                        </option>
                                    ))
                                ) : (
                                    <option key={0} value={""}></option>
                                )}
                            </select>

                            <label className="font-weight-bold">
                                Type<span className="text-danger pl-3">*</span>
                            </label>
                            <select
                                value={usertype}
                                className="form-control rounded-0 cursor-pointer readonly"
                                onChange={(e) => {
                                    setusertype(e.target.value);
                                }}
                            >
                                <option key={-1} value={-1}></option>
                                <option key={0} value={0}>
                                    Employee
                                </option>
                                <option key={1} value={1}>
                                    Admin
                                </option>
                            </select>
                            <span className="text-danger">{ }</span>

                            {usertype === "1" && fixed_employeeètype === 0 ? (
                                <>
                                    <label className="font-weight-bold">
                                        Mot de passe<span className="text-danger pl-3">*</span>
                                    </label>
                                    <input
                                        type={"password"}
                                        onChange={(e) => {
                                            setnew_password(e.target.value);
                                        }}
                                        value={new_password}
                                        className="form-control rounded-0"
                                    />
                                    <p className="text-danger">{message_newpassword}</p>

                                    <label className="font-weight-bold">
                                        Adresse mail<span className="text-danger pl-3"></span>
                                    </label>
                                    <input
                                        type={"mail"}
                                        onChange={(e) => {
                                            setusermail(e.target.value);
                                        }}
                                        value={usermail}
                                        className="form-control rounded-0"
                                    />

                                </>
                            ) : null}

                            <div id="ticketprio-error" className="text-danger pt-2"></div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                    {usertype === fixed_employeeètype ? (
                        <Button variant="primary" hidden onClick={change_status}>
                            Changer le type
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={change_status}>
                            Changer le type
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <Modal show={showmodal_manager} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Changer le manager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                ID du Manager<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                type={"text"}
                                onChange={(e) => {
                                    setmanagerid(e.target.value);
                                }}
                                onBlur={handlergetmanager}
                                value={managerid}
                                className="form-control rounded-0"
                            />
                            <p className="text-danger">{message_manager}</p>
                        </div>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Nom du Manager<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                type={"text"}
                                disabled
                                onChange={(e) => {
                                    setmanagerName(e.target.value);
                                }}
                                value={managerName}
                                className="form-control rounded-0"
                            />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleTypeClose}>
                        Close
                    </Button>
                    {usertype === fixed_employeeètype ? (
                        <Button variant="primary" disabled onClick={changeManager}>
                            Change manager
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={changeManager}>
                            Change manager
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>

            <UserPermission show={showmodal_permission} onHide={handleTypeClose} employeeInfo={employeeInfo} handleTypeClose={handleTypeClose} />

            <FilterModal
                open={filterModal}
                setOpen={setFilterModal}
                setVal={setSearch}
                type={filt_col}
                paginate={paginate}
                list={list}
                title={title}
            />

            <div className="col-xl-12 center-block" align="center">
                <div
                    className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px"
                    align="center"
                >
                    <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
                        <h3 className="float-left pt-2 pr-3">List of Users </h3>
                        <span className="float-left">
                            <BiRefresh
                                onClick={() => {
                                    null;
                                }}
                                className="cursor-pointer display-4"
                            />
                        </span>
                        <button
                            onClick={() => {
                                setSearch(null);
                            }}
                            id="crticket-btn"
                            className="btn btn-defaut text-white canceled font-weight-bold float-right mr-10"
                            data-toggle="modal"
                            data-target="#addticketModal"
                        >
                            <RiFilterOffFill />
                        </button>
                    </div>

                    <div className="h-550px overflow-auto">
                        <table id="customers" className="table " ref={tableRef}>
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" className="w-25px"></th>
                                    <th scope="col" className="w-100px ">
                                        <div className=" d-flex">
                                            #
                                            <i
                                                className="la la-filter"
                                                onClick={() => {
                                                    setFilterModal(true);
                                                    setFilt_col("e_id");
                                                    setTitle("ID");
                                                }}
                                            ></i>
                                        </div>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Name
                                        <i
                                            onClick={() => {
                                                setFilterModal(true);
                                                setFilt_col("e_name");
                                                setTitle("Name");
                                            }}
                                            className="la la-filter"
                                        ></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Branch
                                        <i
                                            className="la la-filter"
                                            onClick={() => {
                                                setFilterModal(true);
                                                setFilt_col("branch_id");
                                                setTitle("Branch");
                                            }}
                                        ></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Depatment
                                        <i
                                            className="la la-filter"
                                            onClick={() => {
                                                setFilterModal(true);
                                                setFilt_col("dep_id");
                                                setTitle("Depatment");
                                            }}
                                        ></i>
                                    </th>
                                    <th scope="col" className="text-left">
                                        Manager
                                        <i
                                            className="la la-filter"
                                            onClick={() => {
                                                setFilterModal(true);
                                                setFilt_col("manager_name");
                                                setTitle("Manager");
                                            }}
                                        ></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Type
                                        <i
                                            className="la la-filter"
                                            onClick={() => {
                                                setFilterModal(true);
                                                setFilt_col("e_employee_type");
                                                setTitle("Type");
                                            }}
                                        ></i>
                                    </th>

                                    <th scope="col" className="text-left"></th>
                                </tr>
                            </thead>

                            <tbody id="resultdata">
                                {currentPosts ? (
                                    currentPosts.map((d, index) => (
                                        <tr key={index}>
                                            <td>
                                                <Dropdown className="dropdown">
                                                    <Dropdown.Toggle
                                                        variant="dropdown-basic"
                                                        className="text-white p-0 m-0"
                                                    >
                                                        <i className="ki ki-bold-more-hor"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="">
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={() => {
                                                                handleUpdate(d);
                                                            }}
                                                            className="dropdown-item border-bottom border-top"
                                                        >
                                                            <i className="far fa-edit"></i>{" "}
                                                            <span className="pl-3">Changer le status</span>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href="#"
                                                            onClick={() => {
                                                                handlemanager(d);
                                                            }}
                                                            className="dropdown-item border-bottom border-top"
                                                        >
                                                            <i className="far fa-edit"></i>{" "}
                                                            <span className="pl-3">Changer le manager</span>
                                                        </Dropdown.Item>

                                                        {d.e_employee_type === 1 ? (
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => {
                                                                    handlerPermission(d);
                                                                }}
                                                                className="dropdown-item border-bottom border-top"
                                                            >
                                                                <i className="far fa-edit"></i>{" "}
                                                                <span className="pl-3">Permissions</span>
                                                            </Dropdown.Item>
                                                        ) : null}
                                                        {d.e_employee_type === 1 ? (
                                                            <Dropdown.Item
                                                                href="#"
                                                                onClick={() => handleresetpassword(d)}
                                                                className="dropdown-item border-bottom border-top"
                                                            >
                                                                <i className="far fa-edit"></i>{" "}
                                                                <span className="pl-3">Reset Password</span>
                                                            </Dropdown.Item>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>

                                            <td id="tb-assigned-to" className="text-left">
                                                {d.e_id}
                                            </td>
                                            <td id="tb-assigned-to" className="text-left">
                                                {d.e_name}
                                            </td>
                                            <td id="tb-created-date" className="text-left">
                                                {d.branch_name}
                                            </td>
                                            <td id="tb-created-date" className="text-left">
                                                {d.dep_name}
                                            </td>
                                            <td id="tb-created-date" className="text-left">
                                                {d.manager_name}
                                            </td>
                                            <td id="tb-created-date" className="text-left">
                                                {d.e_employee_type === 1 ? "User" : "Employee"}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="card-footer pt-2 pb-2 col-8">
                    <div className="d-flex justify-content-center">
                        <Pagination
                            postsPerPage={linePerPage}
                            totalPosts={filteredList.length}
                            paginate={paginate}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Users;
