
import Modal from "react-bootstrap/Modal";
import { useState } from 'react'
import Button from "react-bootstrap/esm/Button";

export default function FilterModal({ open, setOpen, type, setVal, paginate, list, title }) {
    const [searchValue, setSearchValue] = useState('')


    const handleCloseModal = () => {
        setOpen(false)
    }

    return (
        <div>
            <div >
                <Modal show={open} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{title}</label>
                        {type === 'id_status' ?
                            <select
                                className="form-control rounded-0"
                                onChange={async (e) => {
                                    setSearchValue(e.target.value)
                                }}
                            >
                                <option value={-1}>Choose ...</option>
                                {
                                    list
                                        ? list.listStatus.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id_status}>{item.status}</option>
                                            )
                                        })
                                        : null
                                }
                            </select>
                            : type === 'sanct_id1' || type === 'sanct_id' ?
                                <select
                                    className="form-control rounded-0"
                                    onChange={async (e) => {
                                        setSearchValue(e.target.value)
                                    }}
                                >
                                    <option value={-1}>Choose ...</option>
                                    {
                                        list
                                            ? list.listSanctions.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.sanct_id}>{item.sanct_value}</option>
                                                )
                                            })
                                            : null
                                    }
                                </select>
                                : type === 'branch_id' ?
                                    <select
                                        className="form-control rounded-0"
                                        onChange={async (e) => {
                                            setSearchValue(e.target.value)
                                        }}
                                    >
                                        <option value={-1}>Choose ...</option>
                                        {
                                            list
                                                ? list.listBranch.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.branch_id}>{item.branch_name}</option>
                                                    )
                                                })
                                                : null
                                        }
                                    </select>
                                    : type === 'dep_id' ?
                                        <select
                                            className="form-control rounded-0"
                                            onChange={async (e) => {
                                                setSearchValue(e.target.value)
                                            }}
                                        >
                                            <option value={-1}>Choose ...</option>
                                            {
                                                list
                                                    ? list.listDept.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.dep_id}>{item.dep_name}</option>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </select>
                                        : type === 'e_employee_type' ?
                                            <select
                                                className="form-control rounded-0"
                                                onChange={async (e) => {
                                                    setSearchValue(e.target.value)
                                                }}
                                            >
                                                <option value={-1}>Choose ...</option>
                                                <option value={1}>User</option>
                                                <option value={0}>Employee</option>

                                            </select>
                                            :
                                            <input
                                                type={type === 'comp_complaint_date' || type === 'comp_fact_date' ? "date" : "text"}
                                                onChange={(e) => { setSearchValue(e.target.value) }}
                                                className="form-control rounded-0"
                                            />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" >
                            Annuler
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setVal(searchValue)
                            setOpen(false)
                            paginate(1)
                        }}>
                            Appliquer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </div>
    )
}