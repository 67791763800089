import { useState } from "react";
import { ChevronDown, Search } from "react-bootstrap-icons";

export default function DropDownSearch({ isOpen, setIsOpen, data, text, changeValue, keyType }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredData = searchTerm ? data?.filter(x => x.designation?.toLowerCase().includes(searchTerm)) : data;
    const divStyle = { height: "450px" };

    const handleClickItem = (option) => {
        changeValue(keyType, option);
        setIsOpen(false)
    }
    const toggleDropdown = () => setIsOpen(!isOpen)

    return (
        <div className="dropdown w-100">
            <div className="btn border dropdown-toggle w-100 text-left" type="button" onClick={toggleDropdown}>
                <div className="flex d-flex justify-content-between align-items-center w-100">
                    <span className="pr-2">{text}</span>
                    <ChevronDown className="flex-grow" />
                </div>
            </div>
            <div className={`w-100 dropdown-menu overflow-auto rounded-none  ${isOpen ? "show" : ""}`} style={divStyle}>
                <div className="input-group px-3 py-2">
                    <span className="input-group-text">
                        <Search />
                    </span>
                    <input type="text" className="form-control" placeholder="Rechercher" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value); }} />
                </div>
                <div className="dropdown-menu-scrollable w-100 cursor-pointer">
                    {
                        filteredData
                            .map((option, index) => {
                                return (
                                    <div key={index} className="dropdown-item w-100 text-wrap" onClick={() => handleClickItem(option)}>
                                        <span className="">
                                            {option.designation}
                                        </span>
                                    </div>)
                            })
                    }
                </div>
            </div>
        </div>
    )
}