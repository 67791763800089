import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import logo from "../assets/img/auto-logo.jpg";
import Language from "./language";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import baseurl from "../globals";

import { MDBCheckbox } from "mdb-react-ui-kit";
import Header from "./header";

function Header_mobile() {
  const navigate = useNavigate();
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();
  const [displayMenu, setDisplayMenu] = useState("d-none");

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };
  const handleUser = () => {
    navigate("/users");
  };

  const handleNature = () => {
    navigate("/nature");
  };
  const handleSanction = () => {
    navigate("/sanction");
  };

  const change_password = () => {
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        Axios.post(baseurl + "/loginuser", {
          username: sessionStorage.getItem("username"),
        }).then((response) => {
          if (response.data.result) {
            if (oldpassword === response.data.ref) {
              Axios.post(baseurl + "/change_password", {
                username: sessionStorage.getItem("username"),
                new_password: newpassword,
                id: sessionStorage.getItem("id"),
              }).then((response) => {
                if (response.data.type_action === true) {
                  notifySuccess("Password has been changed");
                  setShowChangePassword(false);
                } else {
                  notifyError(response.data.erromsg);
                }
              });
            } else {
              notifyError("Actual password is incorrect");
            }
          }
        });
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const displayMenuHandle = () => {
    if (displayMenu === "d-none") {
      setDisplayMenu("d-flex");
    } else {
      setDisplayMenu("d-none");
    }
  };

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  };

  return (
    <>
      <Header />
      <Modal
        show={showChangePassword}
        onHide={handleModelClosechangePassword}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Old password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setOldpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageoldpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                New password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="new-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setNewpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messagenewpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Confirm password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageconfirmpassword}
              </div>
            </div>
            <MDBCheckbox
              className="ml-30"
              name="flexCheck"
              onChange={check_verif}
              value={vuepassword}
              id="shwpssw"
              label="Show password"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleModelClosechangePassword}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={change_password}>
            Change password
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        id="kt_header_mobile"
        className={"header-mobile bg-white d-print-none "}
      >
        <div className={"dropdown pt-0 mr-1"}>
          <Dropdown className="">
            <Dropdown.Toggle
              variant="dropdown-basic"
              className="w-auto h-40px text-white pt-0 pb-0"
            >
              <div
                className="btn p-0 ml-4"
                id="kt_header_mobile_toggle"
                onClick={() => displayMenuHandle()}
              >
                <span>
                  <i className="fas fa-bars"></i>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-180px">
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  Home
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleUser()}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  Users
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleSanction()}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  Sanction
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                className="navi-link border-bottom"
                onClick={() => handleNature("en")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  Nature
                </span>
                <i className="menu-arrow pb-2"></i>

                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>

              <Dropdown.Item
                href="#"
                className="navi-link"
                onClick={() => handleLogout("fr")}
              >
                <span className="text-dark-75 font-weight-bolder pl-5">
                  Logout
                </span>
                <i className="menu-arrow pb-2"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <a href="/home">
          <img alt="Logo" src={logo} className="logo-default max-h-30px" />
        </a>

        <div className="d-flex">
          <Language paddingTop="pt-0" />
          <div className="w-full dropdown mr-0 pt-0">
            <div className="menu-link">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dropdown-basic"
                  className="w-100 h-40px text-white p-0 m-0 pt-0 pb-0"
                >
                  <div
                    className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                    id="open-notification"
                  >
                    <i className="far fa-bell text-dark-75 mr-2"></i>
                    <span className="label label-rounded label-danger">
                      <div id="unreadnot">0</div>
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-full">
                  <Dropdown.Item href="#">
                    <form className="p-0">
                      <div className="d-flex pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                          {"Notifications"}
                        </div>
                      </div>

                      <div className="scroll scroll-push text-center h-100px">
                        <div id="notificationcontent" className="pt-11"></div>
                      </div>
                    </form>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header_mobile;
