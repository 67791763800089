import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/custom/css/style.bundle.css";
import "./assets/custom/plugins/global/plugins.bundle.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Users from "./components/Users";
import Nature from "./components/Nature";
import Sanction from "./components/Sanction";
import React from "react";
import ProtectedRoutes from "./protectedRoutes";
import Report from "./components/Report";
import Settings from "./components/Settings";
import Recrut from "./components/Recrut";
import AddRecrut from "./components/Recrut/views/Add";
import { DataProvider } from "./context/DataProvider";
import Quota from "./components/Recrut/views/quota";

function App() {

    return (
        <Router>
            <DataProvider>
                <div className="App">
                    <div className="d-flex flex-column flex-root">
                        <div className="d-flex flex-row flex-column-fluid page">
                            <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                                <Routes>
                                    <Route exact path="/Login" element={<Login />} />
                                    <Route element={<ProtectedRoutes />}>
                                        <Route exact path="/" element={<Home />} />
                                        {/* <Route exact path="*" element={<Home />} /> */}
                                        <Route exact path="/home" element={<Home />} />
                                        <Route exact path="/users" element={<Users />} />
                                        <Route exact path="/nature" element={<Nature />} />
                                        <Route exact path="/sanction" element={<Sanction />} />
                                        <Route exact path="/report" element={<Report />} />
                                        <Route exact path="/settings" element={<Settings />} />
                                        <Route exact path="/recrut" element={<Recrut />} />
                                        <Route exact path="/recrut/add_recrut" element={<AddRecrut />} />
                                        <Route exact path="/quota" element={<Quota />} />
                                    </Route>
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </DataProvider>
        </Router>
    );
}

export default App;
