import { Navigate, Outlet } from "react-router-dom";
import React from "react";

const useAuth = () => {
  const userid = sessionStorage.getItem("accessToken");
  var logged = false;

  if (userid && userid !== "undefined") {
    logged = true;
  }

  const user = { loggedIn: logged };
  return user && user.loggedIn;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/Login" />;
};

export default ProtectedRoutes;
