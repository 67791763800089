
import toast from "react-hot-toast";

const notifySuccess = (msg) =>
    toast.success(msg, {
        style: {
            padding: "16px",
            backgroundColor: "rgba(124, 140, 135, 0.70)",
            color: "#FFF",
            maxWidth: "280px",
            textAlign: "left",
            fontSize: "13px",
        },
    });

export default notifySuccess;