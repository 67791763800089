import axios from "axios";
import Logo from "../../assets/logo.png";
import { First } from "./step/first";
import { Second } from "./step/second";
import { useEffect, useState } from "react";
import baseurl from "../../../../globals";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { useFetch } from "../../../hooks/useFetch";
import HttpStatus from "../../../utils/HttpStatus";

export function Fiche({
  componentRef,
  action,
  data,
  setData,
  jobDescData,
  setJobDescData,
  errorData,
  classnames,
}) {
  const { fetchData } = useFetch();
  const [listPosition, setListPosition] = useState([]);
  const [listBranch, setListBranch] = useState([]);
  const [listDept, setListDept] = useState([]);
  const [descCategory, setDescCategory] = useState([]);
  const [descSubCategory, setDescSubCategory] = useState([]);
  const [allQuota, setAllQuota]= useState([])


  const getQuota = async () => {
    const response = await fetchData("POST", "recrut/getQuota");
    if (response.status === HttpStatus.OK) {
      setAllQuota(response.data.result.rows)
    } else {
        console.log("Something went wrong");
    }
};
  const styleBg = classnames
    ? "bgGrP custom-border-styleP "
    : "bgGr custom-border-style ";
  const styleBd = classnames ? "custom-border-styleP " : "custom-border-style ";

  async function getData() {
    const a = jwtDecode(sessionStorage.getItem("accessToken"));
    const response = await fetchData("POST", "recrut/getListJt", {});
    if (response.status === HttpStatus.OK) {
      setListPosition(response.data.result.position);
      setListBranch(response.data.result.branch);
      setListDept(response.data.result.department);
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetchData("POST", "recrut/getDescCategories", {});
      if (response.status === HttpStatus.OK) {
        setDescCategory(response.data.result.rows);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getSubCategories = async () => {
    try {
      const response = await fetchData(
        "POST",
        "recrut/getDescSubCategories",
        {}
      );
      if (response.status === HttpStatus.OK) {
        setDescSubCategory(response.data.result.rows);
      }
    } catch (err) {
    }
  };

  const getJobDescData = async (id) => {
    try {
      const a = jwtDecode(sessionStorage.getItem("accessToken"));
      const response = await fetchData("POST", "recrut/getListJt", { id: id });
      if (response.status === HttpStatus.OK) {
        setJobDescData(response.data.result.rows);
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    if (action === "add") {
      setData((current) => {
        const userInfo = jwtDecode(sessionStorage.getItem("accessToken"));
        return {
          ...current,
          id: userInfo.id,
          nomPrenom: userInfo.name,
          poste: userInfo.po_name,
          branch: userInfo.branch_name,
          dept: userInfo.dep_name,
          AskPoste: "",
          AskBranch: "",
          AskDept: "",
          AskDate: moment().format("YYYY-MM-DD"),
          AskUrgence: 0,
          AskNbre: 1,
          AskExistingPoste: 1,
          NewPostTask: [],
          NewPostEtude: [],
          NewPostExp: [],
          NewPostCon: [],
          NewPostQual: [],
          AskOrgQuota: 1,
          AskQuotaEcart: 0,
          AskRaisonRecrut: "",
        };
      });
    } else if (action === "view") {
      getJobDescData(data.rec_id);
      setData((current) => {
        return {
          ...current,
          id: data.rec_id,
          nomPrenom: data.e_name,
          poste: data.po_man,
          branch: data.branch_man,
          dept: data.dep_man,
          AskPoste: data.po_name,
          AskBranch: data.branch_name,
          AskDept: data.dep_name,
          AskDate: moment(data.rec_askDate).format("YYYY-MM-DD"),
          AskUrgence: data.rec_askUrgence,
          AskNbre: data.rec_askNbreAgent,
          AskExistingPoste: data.rec_existingPoste,
          NewPostTask: data.prec_taches?.split("*-*"),
          NewPostEtude: data.prec_etudes?.split("*-*"),
          NewPostExp: data.prec_experience?.split("*-*"),
          NewPostCon: data.prec_connaissance?.split("*-*"),
          NewPostQual: data.prec_qualite?.split("*-*"),
          AskOrgQuota: data.rec_orgAuota,
          AskQuotaEcart: data.rec_orgEcart,
          AskRaisonRecrut: data.rec_raisonRecr,
          HrAjoutQuota: data.rec_demandeAjoutQuota,
          HrAjoutQuotaExistPoste: data.rec_demandeAjoutQuotaExistingPoste,
          HrDecisionGlob: data.rec_decisionGlobal,
        };
      });
    } else {
      if (action !== "") {
        getJobDescData(data.rec_id);
      }
      setData((current) => {
        return {
          ...current,
          id: data.rec_id,
          nomPrenom: data.e_name,
          poste: data.po_man,
          branch: data.branch_man,
          dept: data.dep_man,
          AskPoste: data.po_name,
          AskBranch: data.branch_name,
          AskDept: data.dep_name,
          AskDate: moment(data.rec_askDate).format("YYYY-MM-DD"),
          AskUrgence: data.rec_askUrgence,
          AskNbre: data.rec_askNbreAgent,
          AskExistingPoste: data.rec_existingPoste,
          NewPostTask: data.prec_taches?.split("*-*"),
          NewPostEtude: data.prec_etudes?.split("*-*"),
          NewPostExp: data.prec_experience?.split("*-*"),
          NewPostCon: data.prec_connaissance?.split("*-*"),
          NewPostQual: data.prec_qualite?.split("*-*"),
          AskOrgQuota: data.rec_orgAuota,
          AskQuotaEcart: data.rec_orgEcart,
          AskRaisonRecrut: data.rec_raisonRecr,
          AskDescriptionPoste: [],
          HrAjoutQuota: data.rec_demandeAjoutQuota,
          HrAjoutQuotaExistPoste: data.rec_demandeAjoutQuotaExistingPoste,
          HrDecisionGlob: data.rec_decisionGlobal,
        };
      });
    }
    getCategories();
    getQuota()
    getSubCategories();
    // eslint-disable-next-line
  }, [action]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      id="checkList"
      style={{ width: "90%", marginLeft: "5%", backgroundColor: "white" }}
      className="col-10"
    >
      <div ref={componentRef}>
        <div className="" style={{ margin: "1cm" }}>
          <table id="" className="table">
            <tbody id="">
              <tr className={`${styleBd}`}>
                <td rowSpan="3" colSpan="1" className={`${styleBd}`} style={{}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      alt="Logo"
                      src={Logo}
                      className="logo-default"
                      style={{ height: "150px" }}
                    />
                  </div>
                </td>
                <td rowSpan={3} colSpan={4} className={`${styleBd}`}>
                  <div
                    className="col-md-12 text-center font-weight-bolder fs-16  "
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 135,
                    }}
                  >
                    FICHE DE DEMANDE DE RECRUTEMENT
                  </div>
                </td>
                <td className={`text-center fs-16 w-200px ${styleBd}`}>
                  Code : FR1-S1{" "}
                </td>
              </tr>
              <tr>
                <td className={`text-center ${styleBd} fs-16`}>
                  Version : 002
                </td>
              </tr>
              <tr>
                <td className={`text-center ${styleBd} fs-16`}>
                  Date de mise en application: <br />
                  04 OCT 2022
                </td>
              </tr>

              <First
                data={data}
                setData={setData}
                action={action}
                position={listPosition}
                branch={listBranch}
                dept={listDept}
                error={errorData}
                styleBd={styleBd}
                styleBg={styleBg}
                depQuota={allQuota}
              />

              {data.rec_status > 1 && (
                <Second
                  data={data}
                  jdData={jobDescData ? jobDescData : []}
                  setJdData={setJobDescData}
                  desc={{ descCategory, descSubCategory }}
                  setData={setData}
                  action={action}
                  error={errorData}
                  styleBd={styleBd}
                  styleBg={styleBg}
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
