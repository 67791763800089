import React, { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { IoEnter } from "react-icons/io5";

function TodoList({ items, setItems, action, onclick }) {
    const [inputText, setInputText] = useState("");

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    const handleEnterPress = (e) => {
        if ((!e.key || e.key === "Enter") && inputText.trim() !== "") {
            if (onclick) {
                onclick({ action: 'add', value: inputText })
                setInputText("")
            }
            else {
                const newItems = [...items, inputText];
                setItems(newItems);
                setInputText("");
            }
        }
    };

    const handleDelete = (index) => {
        if (onclick) {
            onclick({ action: 'remove', value: items[index] })
            setInputText("")
        }
        else {
            const newItems = [...items];
            newItems.splice(index, 1);
            setItems(newItems);
        }
    };

    return (
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="list-group mt-2">
                        {items?.map((item, index) => (

                            <div key={index} className="list-group-item" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px', maxWidth: '95%' }}>
                                    <div >{index + 1}.</div>
                                    <div>{onclick ? item.jd_text : item}</div>
                                </div>
                                {action !== 'view' && <BiTrash color="red" className="cursor-pointer" onClick={() => { handleDelete(index) }} />}
                            </div>
                        ))}
                    </div>
                    {action !== 'view' &&
                        <>
                            <input
                                type="text"
                                placeholder="Ajouter un élément..."
                                value={inputText}
                                onChange={handleInputChange}
                                onKeyPress={handleEnterPress}
                                className="form-control mt-5 pr-10"
                            />
                            <IoEnter size={30} style={{ position: "absolute", bottom: 3, right: 15, cursor: 'pointer' }} onClick={handleEnterPress} />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default TodoList;