import { useEffect } from "react";
import { useFetch } from "../../../../hooks/useFetch";
import HttpStatus from "../../../utils/HttpStatus";
import CustomSelect from "../../../views/Add/component/CustomSelect"
import TodoList from "../../List.js/TodoList";

export const First = ({ data, setData, action, position, branch, dept, error, styleBd, styleBg, depQuota }) => {

    const { fetchData } = useFetch();

    const updateNewPost = (columnName, newData) => {
        setData((data) => ({
            ...data,
            [columnName]: newData,
        }));
    };

    const checkQuota = async () => {
        if (data.AskDept && data.AskBranch && data.AskPoste && data.AskDept > 0 && data.AskBranch > 0 && data.AskPoste > 0) {
            // getActualDepQuota();
            const existingQuota = depQuota.filter((quota) => quota.id_jobtitle === data.AskPoste && quota.dep_id === data.AskDept && quota.branch_id === data.AskBranch)
            if (data.AskNbre > 0 && existingQuota.length > 0) {
                const response = await fetchData("POST", "recrut/getActualDepQuota", { department: data.AskDept, branch: data.AskBranch, post: data.AskPoste });
                if (response.status === HttpStatus.OK) {
                    if ((parseInt(response.data.result.rows[0].totalEmployee, 10) + parseInt(data.AskNbre, 10)) > existingQuota[0].quota_value) {
                        setData(current => {
                            return ({
                                ...current,
                                AskOrgQuota: 0,
                                AskQuotaEcart: 0
                            })
                        })
                        setData(current => { return ({ ...current, AskQuotaEcart: (parseInt(response.data.result.rows[0].totalEmployee, 10) + parseInt(data.AskNbre, 10)) - existingQuota[0].quota_value }) })
                    } else {
                        setData(current => {
                            return ({
                                ...current,
                                AskOrgQuota: 1,
                                AskQuotaEcart: 0
                            })
                        })
                    }
                }
            } else {
                setData(current => {
                    return ({
                        ...current,
                        AskOrgQuota: 0,
                        AskQuotaEcart: data.AskNbre
                    })
                })
            }

        }
    }

    useEffect(() => {
        checkQuota()
    }, [data.AskNbre, data.AskPoste, data.AskDept, data.AskBranch])

    return (
        <>
            <tr className={ `text-center  ${styleBg}` } >
                <td colSpan="6" className={ `text-center font-weight-bolder  ${styleBd} fs-16` } onClick={ () => { } }>INFORMATIONS SUR LE DEMANDEUR:</td>
            </tr>

            <tr className="text-left ">
                <td colSpan="1" className={ `${styleBd} fs-14 h-20px` } >Nom et Prénom: </td>
                <td colSpan="2" className={ `${styleBd} fs-14` }>{ data.nomPrenom }</td>

                <td colSpan="2" className={ `${styleBd} fs-14` }>Branche:</td>
                <td colSpan="2" className={ `${styleBd} fs-14 h-20px text-center` }> { data.branch } </td>
            </tr>

            <tr>
                <td colSpan="1" className={ `text-left align-middle  ${styleBd} fs-14 h-20px` } >Titre du poste </td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }>{ data.poste }</td>

                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }>Département:</td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14 text-center` }>{ data.dept }</td>
            </tr>

            <tr className={ `text-center  ${styleBg}` }>
                <td colSpan="6" className={ `text-center font-weight-bolder  ${styleBd} fs-14` }>INFORMATIONS SUR LE POSTE A POUVOIR:</td>
            </tr>

            <tr className="text-left ">
                <td colSpan="1" className={ `${styleBd} fs-14 h-20px` } >Titre du Poste: </td>
                <td colSpan="2" className={ `${styleBd} fs-14` }>
                    { action !== 'add' ?
                        data.po_name ? data.po_name : data.rec_askPosteText
                        :
                        <CustomSelect
                            id={ 'po_id' }
                            list={ position }
                            val={ 'po_name' }
                            setResult={ (e) => {
                                {
                                    setData(current => {
                                        return ({
                                            ...current,
                                            AskPoste: e,
                                            AskExistingPoste: typeof e === 'number' ? 1 : 0
                                        })
                                    }); checkQuota();
                                }
                            } }
                            add={ true }
                            classname={ action !== 'view' && error.AskPoste ? 'border-danger' : '' }
                        />
                    }
                </td>

                <td colSpan="2" className={ ` ${styleBd} fs-14` }>Date de demande:</td>
                <td colSpan="2" className={ ` ${styleBd} fs-14 h-20px text-left` }>

                    <input type="date"
                        value={ data.AskDate ? data.AskDate : '' }
                        onChange={ (e) => {
                            setData(current => {
                                return ({
                                    ...current,
                                    AskDate: e.target.value,
                                })
                            })
                        } }
                        disabled={ action !== 'add' }
                    />
                </td>
            </tr>

            <tr>
                <td colSpan="1" className={ `text-left align-middle  ${styleBd} fs-14 h-20px` } >Branche: </td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }>
                    { action !== 'add' ?
                        data.branch_name
                        :
                        <CustomSelect
                            id={ 'branch_id' }
                            list={ branch }
                            val={ 'branch_name' }
                            setResult={ (e) => {
                                setData(current => {
                                    return ({
                                        ...current,
                                        AskBranch: e,
                                    })
                                }); checkQuota();
                            } }
                            classname={ action !== 'view' && error.AskBranch ? 'border-danger' : '' }
                        />
                    }
                </td>

                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }>Niveau d'urgence:</td>

                <td colSpan="1" className={ `text-left align-middle  ${styleBd} fs-14` }>
                    <div className={ `row ${action !== 'view' && error.AskUrgence ? ' border border-danger' : ''}` }>
                        <div className="col-sm " style={ { display: 'flex', alignItems: 'center', gap: '2px' } }>
                            <input id="0" type="checkbox" className="w-15px h-25px cursor-pointer" value={ 3 } checked={ data.AskUrgence === 3 } onChange={ (e) => action === 'add' && setData(current => {
                                return ({
                                    ...current,
                                    AskUrgence: parseInt(e.target.value, 10),
                                })
                            }) } /> <label style={ { marginTop: '6px' } } htmlFor="0" className="cursor-pointer">Elevé</label>
                        </div>
                        <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '2px' } }>
                            <input id="1" type="checkbox" className="w-15px h-25px cursor-pointer" value={ 2 } checked={ data.AskUrgence === 2 } onChange={ (e) => action === 'add' && setData(current => {
                                return ({
                                    ...current,
                                    AskUrgence: parseInt(e.target.value, 10),
                                })
                            }) } /> <label style={ { marginTop: '6px' } } htmlFor="1" className="cursor-pointer">Moyen</label>
                        </div>
                        <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '2px' } }>
                            <input id="2" type="checkbox" className="w-15px h-25px cursor-pointer" value={ 1 } checked={ data.AskUrgence === 1 } onChange={ (e) => action === 'add' && setData(current => {
                                return ({
                                    ...current,
                                    AskUrgence: parseInt(e.target.value, 10),
                                })
                            }) } /> <label style={ { marginTop: '6px' } } htmlFor="2" className="cursor-pointer"> Faible</label>
                        </div>
                    </div>
                </td>
            </tr>

            <tr className="text-left ">
                <td colSpan="1" className={ `${styleBd} fs-14 h-20px` } >Département </td>
                <td colSpan="2" className={ `${styleBd} fs-14` }>
                    { action !== 'add' ?
                        data.dep_name
                        :
                        <CustomSelect
                            id={ 'dep_id' }
                            list={ dept }
                            val={ 'dep_name' }
                            setResult={ (e) => {
                                setData(current => {
                                    return ({
                                        ...current,
                                        AskDept: e,
                                    })
                                });
                                checkQuota();
                            } }
                            classname={ action !== 'view' && error.AskDept ? 'border-danger' : '' }
                        />
                    }
                </td>

                <td colSpan="2" className={ `${styleBd} fs-14` }>Nombre d'agent demandé:</td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14 h-20px ` }>
                    <input
                        value={ data.AskNbre ? data.AskNbre : '' }
                        type="number"
                        onChange={ (e) => {
                            setData(current => {
                                return ({
                                    ...current,
                                    AskNbre: e.target.value,
                                })
                            }); checkQuota()
                        } }
                        className={ action !== 'view' && error.AskNbre ? 'border-danger' : '' }
                        disabled={ action !== 'add' }
                    />
                </td>
            </tr >

            <tr>
                <td colSpan="1" className={ `text-left align-middle  ${styleBd} fs-14 h-20px` } >Unité: </td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }></td>

                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14` }></td>
                <td colSpan="2" className={ `text-left align-middle  ${styleBd} fs-14 text-center` }> </td>

            </tr>

            <tr className={ `text-center bgGr ${styleBd}` }>
                <td colSpan="6" className={ `text-center font-weight-bolder  ${styleBd} fs-16` }>IDENTIFICATION DU POSTE A POURVOIR:</td>
            </tr>

            <tr className="text-left ">
                <td colSpan="3" className={ `${styleBd} fs-14 h-20px` } >Le poste est-il un poste existant? </td>

                <td colSpan="2" className={ `${styleBd} fs-14` }>
                    <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                        <input type="checkbox" value={ 1 } checked={ data.AskExistingPoste === 1 } className="w-15px h-25px" /> <span>Oui</span>
                    </div>
                </td>
                <td colSpan="1" className={ `${styleBd} fs-14 h-20px text-center` }>
                    <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                        <input type="checkbox" value={ 0 } checked={ data.AskExistingPoste === 0 } className="w-15px h-25px" /> <span>Non</span>
                    </div>
                </td>
            </tr>

            {
                !data.AskExistingPoste ?
                    <>

                        <tr className="text-left ">
                            <td colSpan="6" className={ `table-active ${styleBd} fs-14 h-20px` }>
                                PRINCIPALES TACHES
                            </td>
                        </tr>
                        <tr className="text-left ">
                            <td colSpan="6" className={ `${styleBd} fs-14 h-80px` }>
                                <TodoList action={ action === 'Hr' ? 'view' : action } items={ data.NewPostTask } setItems={ (e) => updateNewPost('NewPostTask', e) } />
                            </td>
                        </tr>

                        <tr className="text-left ">
                            <td colSpan="6" className={ `table-active  ${styleBd} fs-14 h-20px` }>
                                NIVEAU D'ETUDE
                            </td>
                        </tr>
                        <tr className="text-left ">
                            <td colSpan="6" className={ ` ${styleBd} fs-14 h-80px` }>
                                <TodoList action={ action === 'Hr' ? 'view' : action } items={ data.NewPostEtude } setItems={ (e) => updateNewPost('NewPostEtude', e) } />
                            </td>
                        </tr>

                        <tr className="text-left ">
                            <td colSpan="6" className={ `table-active  ${styleBd} fs-14 h-20px` }>
                                EXPERIENCE DANS LE DOMAINE
                            </td>
                        </tr>
                        <tr className="text-left ">
                            <td colSpan="6" className={ `${styleBd} fs-14 h-80px` }>
                                <TodoList action={ action === 'Hr' ? 'view' : action } items={ data.NewPostExp } setItems={ (e) => updateNewPost('NewPostExp', e) } />
                            </td>
                        </tr>

                        <tr className="text-left ">
                            <td colSpan="6" className={ `table-active  ${styleBd} fs-14 h-20px` }>
                                CONNAISSANCES REQUISES
                            </td>
                        </tr>
                        <tr className="text-left ">
                            <td colSpan="6" className={ `${styleBd} fs-14 h-80px` }>
                                <TodoList action={ action === 'Hr' ? 'view' : action } items={ data.NewPostCon } setItems={ (e) => updateNewPost('NewPostCon', e) } />
                            </td>
                        </tr>

                        <tr className="text-left ">
                            <td colSpan="6" className={ `table-active  ${styleBd} fs-14 h-20px` }>
                                QUALITE ET APTITUDE REQUISE
                            </td>
                        </tr>
                        <tr className="text-left ">
                            <td colSpan="6" className={ `${styleBd} fs-14 h-80px` }>
                                <TodoList action={ action === 'Hr' ? 'view' : action } items={ data.NewPostQual } setItems={ (e) => updateNewPost('NewPostQual', e) } />
                            </td>
                        </tr>
                    </>
                    :
                    <></>
            }

            <tr className="text-left ">
                <td colSpan="3" className={ `  ${styleBd} fs-14 h-20px` } >La demande est-elle conforme au quota identifié dans votre organigramme? </td>

                <td colSpan="2" className={ ` ${styleBd} fs-14` }>
                    <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                        <input id="oui" type="checkbox" className="w-15px h-25px cursor-pointer" value={ 1 } checked={ data.AskOrgQuota === 1 } /> <label className="cursor-pointer" style={ { marginTop: '6px' } } htmlFor="oui">Oui</label>
                    </div>
                </td>
                <td colSpan="1" className={ `${styleBd} fs-14 h-20px text-center` }>
                    <div className="col-sm" style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                        <input id="non" type="checkbox" className="w-15px h-25px cursor-pointer" value={ 0 } checked={ data.AskOrgQuota === 0 } /> <label className="cursor-pointer" style={ { marginTop: '6px' } } htmlFor="non">Non</label>
                    </div>
                </td>
            </tr>

            { !data.AskOrgQuota &&
                <>
                    <tr className="text-left ">
                        <td colSpan="3" className={ `  ${styleBd} fs-14 h-20px` } >Si non, de combien est l'écart? </td>

                        <td colSpan="3" className={ ` ${styleBd} fs-14` }>
                            <input disabled={ action !== 'add' } className={ `${action !== 'view' && error.AskQuotaEcart ? 'border-danger' : ''}` } placeholder="Ecart ..." value={ data.AskQuotaEcart }
                                onChange={ (e) => { setData(current => { return ({ ...current, AskQuotaEcart: e.target.value }) }) } } />

                        </td>
                    </tr>
                    <tr className="text-left ">
                        <td colSpan="6" className={ `${styleBd} fs-14 h-40px` } style={ { fontStyle: 'italic' } } >

                        </td>
                    </tr>


                    <tr className="text-left ">
                        <td colSpan="6" className={ `${styleBd} fs-14 h-20px` }>
                            Raison du recrutement hors quota:
                        </td>
                    </tr>


                    <tr className="text-left ">
                        <td colSpan="6" className={ `${styleBd} fs-14 h-80px` }>
                            <textarea disabled={ action !== 'add' } className={ `form-control full-width-height ${action !== 'view' && error.AskRaisonRecrut ? 'border border-danger' : ''}` } placeholder="Enter Reason" value={ data.AskRaisonRecrut }
                                onChange={ (e) => { setData(current => { return ({ ...current, AskRaisonRecrut: e.target.value }) }) } } />
                        </td>
                    </tr>
                </>
            }


            <tr className="text-center ">
                <td colSpan="6" className={ `${styleBd} fs-14 h-80px` } >
                    Signature du Directeur: { data.nomPrenom }
                </td>
            </tr>
        </>
    )
}