import React, { useState, useRef, useEffect } from 'react';
import { BiChevronDown, BiX } from 'react-icons/bi';
import { useOutsideFinder } from '../.../../../../../useOutsideFinder';
import { SearchIco } from '../../../assets/searchIco';

const CustomSelect = ({ list, id, val, setResult, upd, isMultiple, add, classname }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [check, setCheck] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState(list);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const divRef = useRef();
    const selectRef = useRef();

    useOutsideFinder(() => {
        setIsOpen(false);
        if (filteredOptions[0]?.po_id === 0) {
            if (filteredOptions[0].length < 2) {
                handleCheckboxChange(false)
            } else {
                setCheck(true)
            }
        }
    }, selectRef);

    const handleCheckboxChange = (checked) => {
        setCheck(checked)
        if (checked) {
            const newRow = { po_id: 0, po_name: '' };
            setFilteredOptions([newRow, ...filteredOptions]);
        } else {
            setFilteredOptions(filteredOptions.slice(1));
        }
    };


    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const handleOptionClick = (option) => {
        if (isMultiple) {
            setSelectedOptions([...selectedOptions, option]);
            setResult([...selectedOptions, option].map((opt) => opt[id]));
        } else {
            setSelectedOption(option);
            setResult((check && option[id] === 0) ? option[val] : option[id]);
            setIsOpen(false);
        }

        if (isMultiple) {
            setFilteredOptions(filteredOptions.filter((opt) => opt[id] !== option[id]));
        } else {
            setIsOpen(false);
        }
    };

    const handleRemoveOption = (option) => {
        setSelectedOptions(selectedOptions.filter((opt) => opt[id] !== option[id]));
        setResult(selectedOptions.filter((opt) => opt[id] !== option[id]).map((opt) => opt[id]));
    };

    const updateFirstRowName = (newName) => {
        if (filteredOptions.length > 0) {
            const updatedFirstRow = { ...filteredOptions[0] };
            updatedFirstRow.po_name = newName;
            const updatedOptions = [...filteredOptions];
            updatedOptions[0] = updatedFirstRow;
            setFilteredOptions(updatedOptions);
        }
    };


    useEffect(() => {
        setFilteredOptions(
            list.filter((option) =>
                !selectedOptions.some((selected) => selected[id] === option[id]) &&
                option[val]?.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        // eslint-disable-next-line
    }, [searchTerm, list, val, selectedOptions]);

    return (
        <div className="position-relative w-100" ref={selectRef} onClick={(e) => { e.stopPropagation(); }}>
            <div className={`btn border w-100 ${classname} text-left`}>
                <div className="d-flex justify-content-between align-items-center py-3.5" onClick={toggleDropdown}>
                    {isMultiple && (
                        <div className="d-flex flex-wrap align-items-center">
                            {selectedOptions.map((option) => (
                                <div
                                    key={option[id]}
                                    className="bg-info rounded-pill px-2 py-1 d-flex align-items-center justify-content-between me-2 cursor-pointer"
                                >
                                    <span className="me-2">{option[val]}</span>
                                    <span onClick={() => handleRemoveOption(option)}>
                                        <BiX />
                                    </span>
                                </div>
                            ))}
                        </div>
                    )}
                    {!isMultiple && (
                        <span className="ms-2">
                            {selectedOption ? selectedOption[val] : upd ? upd : 'Sélectionner'}
                        </span>
                    )}
                    <span className="ms-2">
                        <BiChevronDown />
                    </span>
                </div>

                {isOpen && (
                    <div className="bg-white border rounded-lg mt-1 overflow-auto" ref={divRef} style={{ position: 'absolute', width: '250px', zIndex: '9999' }}>
                        <div className="p-3 pb-0">
                            <div className="position-relative d-flex align-items-center">
                                <input
                                    type="text"
                                    className="form-control shadow-none border rounded w-100 py-2 px-3 pl-8"
                                    placeholder="Rechercher"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <SearchIco className="position-absolute top-50 translate-middle-y start-1" />
                            </div>
                        </div>
                        <div className="overflow-auto p-2" style={{ height: '200px' }}>
                            {add &&
                                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: '5px', gap: '5px' }}>
                                    <span>Add</span><input type='checkbox' checked={check} className='cursor-pointer' onChange={(e) => { handleCheckboxChange(e.target.checked) }} />
                                </div>
                            }
                            {filteredOptions.map((option, index) => (
                                <div
                                    key={option[id]}
                                    className="form-control shadow-none border rounded w-100 py-2 px-3 mb-3 cursor-pointer w-100"
                                    onClick={() => { handleOptionClick(option); if (!isMultiple) { setIsOpen(false) } }}
                                >
                                    <span>{(check && index === 0) ? <input value={filteredOptions.filter((x) => x.po_id === 0)[0]?.po_name} onChange={(e) => { updateFirstRowName(e.target.value); }} style={{ border: '1px' }} placeholder='new poste ...' autoFocus /> : option[val]}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomSelect;
