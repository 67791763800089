import { useLayoutEffect, useRef, useState } from "react";
import { Fiche } from "../../component/File/Fiche";
import MenuTestPage from "../../../header_mobile";
import { Header } from "./component/Header";
import { useLocation } from "react-router-dom";
import moment from "moment";

export default function AddRecrut() {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [jobDescData, setJobDescData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [action, setAction] = useState("");
  const { state } = useLocation();

  useLayoutEffect(() => {
    if (state) {
      setAction(state.action);
      if (state.action === "Hr") {
        const row = state.row;
        setData((current) => {
          return {
            ...current,
            ...row,
            id: row.rec_id,
            nomPrenom: row.e_name,
            poste: row.po_man,
            branch: row.branch_man,
            dept: row.dep_man,
            AskPoste: row.po_name,
            AskBranch: row.branch_name,
            AskDept: row.dep_name,
            AskDate: moment(row.rec_askDate).format("YYYY-MM-DD"),
            AskUrgence: row.rec_askUrgence,
            AskNbre: row.rec_askNbreAgent,
            AskExistingPoste: row.rec_existingPoste,
            AskOrgQuota: row.rec_orgAuota,
            AskQuotaEcart: row.rec_orgEcart,
            AskRaisonRecrut: row.rec_raisonRecr,
          };
        });
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MenuTestPage />

      <Header
        action={action}
        data={data}
        jobDescData={jobDescData}
        setJobDescData={setJobDescData}
        setDataError={setErrorData}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "40px",
        }}
      >
        <Fiche
          jobDescData={jobDescData}
          setJobDescData={setJobDescData}
          componentRef={componentRef}
          data={data}
          setData={setData}
          action={action}
          errorData={errorData}
        />
      </div>
    </div>
  );
}
