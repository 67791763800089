import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { useFetch } from '../components/hooks/useFetch'


const DataContext = createContext({})

export const DataProvider = ({ children }) => {
    const { fetchData } = useFetch()
    const [perms, setPerms] = useState([])

    const getPerm = async () => {
        try {
            const response = await fetchData('POST', 'user/getall_empl_permisssions')
            if (response.status === 200) {
                const list = {};
                await Promise.all(await response.data.result.map(perm => {
                    return list[perm.user_perm_perm_code] = parseInt(perm.user_perm_value) === 1;
                }));
                setPerms(list)
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    const getPermValue = async (perm) => {
        const permissionValue = await perms?.filter(x => x.id_perm === perm)[0]?.perm_value
        const hasAccess = permissionValue && parseInt(permissionValue) > 0 ? true : false
        return hasAccess
    }

    // useEffect(() => {
    //     if (window.location.pathname !== '/Login' && window.location.pathname !== '/home')
    //         getPerm()
    // }, [])

    return (
        <DataContext.Provider value={{
            perms, setPerms,
            getPerm, getPermValue,
        }}>
            {children}
        </DataContext.Provider>
    )
};

export default DataContext