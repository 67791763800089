import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useFetch } from "../../../components/hooks/useFetch"
import HttpStatus from "../utils/HttpStatus";
import DataContext from "../../../context/DataProvider";
import AddQuota from "./Add/addQuota";
import Button from "react-bootstrap/esm/Button";
import MenuTestPage from "../../header_mobile";


export default function Quota() {
    const { fetchData } = useFetch();
    const { perms, getPerm } = useContext(DataContext)
    const [showAdd, setShowAdd] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [list, setList] = useState([]);


    const getQuota = async () => {
        const response = await fetchData("POST", "recrut/getQuota");
        if (response.status === HttpStatus.OK) {
            setList(response.data.result.rows)
        } else {
            notifyError("Something went wrong");
        }
    };

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    useEffect(() => {
        getQuota();
        getPerm();
    }, []);

    useEffect(() => {
        getQuota();
    }, [refresh]);
    return (
        <>
            <MenuTestPage />
            <div
                className="col-xl-12 center-block"
                align="center"
                style={{ zIndex: 1 }}
            >
                <div
                    className="col-xl-10 bg-white mt-50 center-block p-5"
                    align="center"
                    style={{ zIndex: 1 }}
                >
                    <div className="d-flex">{showAdd ? <AddQuota setShowAdd={setShowAdd} setrefresh={setrefresh} refresh={refresh} data={list} /> : <Button variant="info" className="h-10 mt-8 mb-2" onClick={() => setShowAdd(true)}> Ajouter un quota </Button>} </div>
                    {/* <Header perms={perms} /> */}
                    <div className="h-550px overflow-auto bg-tbl">
                        <table id="customers" className="table ">
                            <thead className="position-fixe">
                                <tr className="bg-primary text-white">
                                    <th scope="col" className="w-100px ">
                                        <div className=" d-flex">
                                            #<i className="la la-filter" onClick={() => { }}></i>
                                        </div>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Branch
                                        <i onClick={() => { }} className="la la-filter"></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Depatment
                                        <i onClick={() => { }} className="la la-filter"></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Unit
                                    </th>

                                    <th scope="col" className="text-left">
                                        Job title
                                    </th>

                                    <th scope="col" className="text-left">
                                        Quota
                                    </th>
                                </tr>
                            </thead>

                            <tbody id="resultdata">
                                {
                                    list ? (
                                        list.map((d, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {index + 1}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.branch_name}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.dep_name}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {"-"}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.po_name}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.quota_value}
                                                    </td>

                                                </tr>
                                            );
                                            // else return null;
                                        })
                                    ) :
                                        (
                                            <tr>
                                                <td colSpan={11} className="text-center pt-20 fs-16">
                                                    No data found
                                                </td>
                                            </tr>
                                        )}
                            </tbody>
                        </table>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}
