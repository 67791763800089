import Empty from "../assets/img/empty.gif";
import MenuTestPage from "./header_mobile";
import Footer from "./footer";
import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    ChevronDown,
    PlusCircle,
    Printer,
    Search,
} from "react-bootstrap-icons";
import { SiMicrosoftexcel } from "react-icons/si";
import { FcClearFilters } from "react-icons/fc";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import Moment from "moment";
import { downloadExcel } from "react-export-table-to-excel";
import logo from "../assets/img/auto-logo.jpg";
import signature from "../assets/img/signature.png";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";
import Loader from "react-js-loader";
import { AiFillPrinter } from "react-icons/ai";
import { ImDownload3 } from "react-icons/im";
import { RiSendPlaneFill } from "react-icons/ri";
import FilterModal from "./Filter";
import { useOutsideFinder } from "./useOutsideFinder";
import moment from "moment";
import Note from "./Note";
import { useFetch } from "./hooks/useFetch";
import HttpStatus from "./utils/HttpStatus";
import { jwtDecode } from "jwt-decode";
import { AiFillDelete } from "react-icons/ai";
import constant from "./utils/constant";
import axios from "axios";
import DataContext from "../context/DataProvider";


function Home() {
    const { fetchData } = useFetch();
    const { perms, getPerm } = useContext(DataContext)
    const menuRef = useRef();
    useOutsideFinder(() => setDropdown(null), menuRef);
    //Variable part
    const [allData, setallData] = useState(false);
    const [employee, setemployee] = useState("");
    const [nature, setnature] = useState("");
    const [description, setdescription] = useState("");
    const [descriptionHR, setdescriptionHR] = useState("");
    const [datecomplaint, setdatecomplaint] = useState("");
    const [datecomplaintsubmited, setdatecomplaintsubmited] = useState("");
    const [sanction_proposed, setsanction_proposed] = useState("");
    const [sanction_real, setsanction_real] = useState("");
    const [allsanction, setallsanction] = useState([]);
    const [allnature, setallnature] = useState([]);
    const [allcomplaint, setallcomplaint] = useState([]);
    const [all_employee, setallemployee] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(10);
    const [showAdd_complaint, setshowAdd_complaint] = useState(false);
    const [showaccepte, setshowaccepte] = useState(false);
    const [message_employee, setmessage_employee] = useState("");
    const [messageNature, setMessageNature] = useState("");
    const navigate = useNavigate();

    const [natureMessage, setnatureMessage] = useState("");
    const [datecomplaintMessage, setdatecomplaintMessage] = useState("");
    const [datecomplaintsubmitedMessage, setdatecomplaintsubmitedMessage] =
        useState("");
    const [status_comp, setstatus_comp] = useState(0);
    const [sanction_proposedMessage, setsanction_proposedMessage] = useState("");
    const [show_complaint, setshow_complaint] = useState(false);
    const [show_update_complaint, setshow_update_complaint] = useState(false);
    const [actionType, setactionType] = useState(0);
    const [compl_id, setcompl_id] = useState(0);
    const [ShowModalView, setShowModalView] = useState(false);
    const [ShowModalexplication, setShowModalexplication] = useState(false);
    const [ShowModalfichedisc, setShowModalfichedisc] = useState(false);
    const [showclosemodale, setshowclosemodale] = useState(false);
    const [complaintData, setComplaintData] = useState(false);
    const [actionTitle, setactionTitle] = useState("Add a complaint");
    const [documentComp_id, setdocumentComp_id] = useState(0);
    const [documentSignedPath, setdocumentSignedPath] = useState("");
    const [sanctionshow, setsanctionshow] = useState("");
    const [documentId_statusDownload, setdocumentId_statusDownload] =
        useState(null);
    const [accusedPositionID, setaccusedPositionID] = useState();

    const [refresh, setrefresh] = useState(0);
    const [actualdate, setactualdate] = useState();

    const [print_name, setprint_name] = useState();
    const [printmatric, setprintmatric] = useState();
    const [printfonction, setprintfonction] = useState();
    const [printdate_infraction, setprintdate_infraction] = useState();
    const [print_nature, setprint_nature] = useState();
    const [hrExplication, sethrExplication] = useState();
    const [telechargement, settelechargement] = useState(0);
    const [disabledButone, setdisabledButone] = useState(false);
    const [fichepath, setfichepath] = useState("");
    // const [mailcc, setmailcc] = useState();
    const header = [
        "#",
        "Nom de l'accusé",
        "Nom du plaignant",
        "Nature",
        "Description",
        "Status",
        "Sanction proposée",
        "Sanction appliquée",
        "Date de l'infraction",
        "Date de la plainte",
        "Date d'acceptation",
        "Date de demande d'explication",
        "Date d'application de sanction",
        "Date signature fiche",
        "Date de cloture",
        "Time (Infraction/plainte) /hr",
        "Time (plainte/accept) /hr",
        "Time (accept/demande) /hr",
        "Time (demande/application) /hr",
        "Time (application/sign) /hr",
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [allSubNature, setallSubNature] = useState([]); // Remplacez cette variable avec votre tableau de positions
    const [allNatureSanction, setAllNatureSanction] = useState([]);
    const [showMore, setShowMore] = useState(null);
    const [dropdown, setDropdown] = useState(null);
    const [buttonAction, setbuttonAction] = useState("");
    // const [pos, setpos] = useState(null);
    // const dropdownRef = useRef(null);

    const divStyle = {
        height: "450px",
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toogleDropdown = (index, position) => {
        // setpos(position);
        if (dropdown === index) {
            setDropdown(null);
        } else {
            setDropdown(index);
        }
    };

    //SHAMS
    const filteredSubNature = allSubNature.filter((x) => {
        const val = searchTerm?.toLowerCase();
        if (!searchTerm) {
            return true;
        } else {
            return x.comp_nature_value.toLowerCase().includes(val);
        }
    });

    const handleOptionClick = (option) => {
        if (selectedValue.some((x) => x.comp_nature_id === option.comp_nature_id)) {
            setSelectedValue((prev) =>
                prev.filter((x) => x.comp_nature_id !== option.comp_nature_id)
            );
        } else {
            setSelectedValue((prev) => [...prev, option]);
            setallSubNature(
                allSubNature.map((x) => {
                    if (x.comp_nature_id === option.comp_nature_id) {
                        return { ...x, print: 0 };
                    }
                    return x;
                })
            );
        }
    };

    const handleRemoveClick = (e, option) => {
        e.stopPropagation();
        setSelectedValue((prev) =>
            prev.filter((x) => x.comp_nature_id !== option.comp_nature_id)
        );

        setallSubNature(
            allSubNature.map((x) => {
                if (x.comp_nature_id === option.comp_nature_id) {
                    return { ...x, print: 1 };
                }
                return x;
            })
        );
    };

    const deleteFile = async (type) => {
        const response = await fetchData("POST", "complaint/deleteFile", {
            compl_id: documentComp_id,
            type: type
        });
        if (response.status === HttpStatus.OK)
            handleTypeClose();

    }

    function filterGroupe(groupeType, jobtitleId) {
        const all = allnature;
        if (groupeType === "-1") {
            setallSubNature(
                all.filter((customer) => {
                    return customer.comp_groupe === -1;
                })
            );
        } else {
            setallSubNature(
                all.filter((customer) => {
                    return customer.comp_groupe === jobtitleId;
                })
            );
        }
    }

    const getAllCacheData = async () => {
        const cachedData = localStorage.getItem("cachedData");
        if (cachedData) {
        }
    };
    function refreshing() {
        setrefresh(refresh + 1);
    }
    function getallchooseNature(id) { }

    const [permissions, setPermissions] = useState({
        permission: {
            perm100: false,
            perm101: false,
            perm102: false,
            perm103: false,
            perm104: false,
            perm105: false,
            perm106: false,
        },
    });

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const [showupload, setshowupload] = useState(0);

    const uploadFile = async ({ target: { files } }) => {
        settelechargement(1);
        let data = new FormData();
        data.append("comp_id", documentComp_id);
        data.append("type", "explication");
        data.append("file", files[0]);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    // setPErcent(percent);
                } else {
                    notifySuccess("Document envoyé");
                    change_statuss(5, documentComp_id);
                    settelechargement(0);
                    refreshing();
                    handleTypeClose();
                }
            },
        };

        fetchData(
            "POST",
            "complaint/upload",
            data,
            options,
        )
            .then((res) => {
                notifySuccess("Document envoyé");
                // change_statuss(5, documentComp_id);
                settelechargement(0);
                refreshing();
                handleTypeClose();
            })
            .catch((error) => {
                // Gérez les erreurs ici
                console.error("Erreur lors de la requête:", error);
            });

        const response = fetchData("POST", "complaint/changedate", {
            compl_id: documentComp_id,
            type: 0,
        });
        if (response.status === HttpStatus.OK) {
        }
    };

    const uploadFile1 = async ({ target: { files } }) => {
        settelechargement(1);
        let data = new FormData();
        data.append("comp_id", documentComp_id);
        data.append("type", "discipline");
        data.append("file", files[0]);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);

                if (percent < 100) {
                    // setPErcent(percent);
                } else {
                    notifySuccess("Document envoyé");
                    change_statuss(5, documentComp_id);
                    settelechargement(0);
                    refreshing();
                    handleTypeClose();
                }
            },
        };

        fetchData(
            "POST",
            "complaint/upload",
            data,
            options,
        )
            .then((res) => {
                notifySuccess("Document envoyé");
                settelechargement(0);
                refreshing();
                handleTypeClose();
            })
            .catch((error) => {
                console.error("Erreur lors de la requête:", error);
            });

        const response = fetchData("POST", "complaint/changedate", {
            compl_id: documentComp_id,
            type: 0,
        });
        if (response.status === HttpStatus.OK) {
        }
    };

    async function closecomplaint(complaint_id) {
        const closeDate = new Date();

        const response = await fetchData("POST", "complaint/closecomplaint", {
            comp_id: complaint_id,
            closeDate: closeDate,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess(response.data.message);
        }

        // axios
        //   .post(baseurl + "/?p=closecomplaint", {
        //     comp_id: complaint_id,
        //     closeDate: closeDate,
        //   })
        //   .then((response) => {
        //     if (response.data.result) {
        //       notifySuccess(response.data.message);
        //       //handleTypeClose();
        //     } else notifyError(response.data);
        //   });
    }

    async function addNote() {
        setLoaderNote(-1);
        const a = jwtDecode(sessionStorage.getItem("accessToken"));

        const response = await fetchData("POST", "complaint/addNote", {
            note: note,
            typeNote: noteType,
            by: a.id,
            comp: compl_id,
            sanctFor: sanct_status,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("Note ajoutée");
            // setListNote(response.data.listNote);

            setnote("");
            setNoteType("");
            setSanct_stat("");
            setLoaderNote(null);
        }
    }

    async function modifyNote(id) {
        setLoaderNote(id);

        const response = await fetchData("POST", "complaint/updateNote", {
            note: note,
            typeNote: noteType,
            id: id,
            comp: compl_id,
            sanctFor: sanct_status,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("Note ajoutée");
            setListNote(response.data.listNote);

            setnote("");
            setNoteType("");
            setSanct_stat("");
            setLoaderNote(null);
        }
    }

    async function change_statuss(new_status, complaint_id) {
        const response = await fetchData("POST", "complaint/change_status1", {
            compl_id: complaint_id,
            status: new_status,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("le statut de la plainte a été modifiée");
            setrefresh(refresh + 1);
            refreshing();
        } else notifyError(response.data.err);
    }

    const [list, setList] = useState({});
    const [showFilter, setShowFilter] = useState(false);

    const [search, setSearch] = useState(null);
    const [filt_col, setFilt_col] = useState("");
    const [title, setTitle] = useState(null);

    const filteredList = allcomplaint.filter((x) => {
        const val = search?.toLowerCase();
        if (!search) {
            return true;
        } else {
            const columnName = filt_col;
            if (filt_col === "comp_nature_value") {
                const nat = allNatureSanction.filter(
                    (d) => d.complaint_id === x.comp_id
                );
                if (nat.length <= 0) {
                    return x["compl_nature_manager"].toLowerCase().includes(val);
                } else {
                    const filtNat = nat.filter((f) =>
                        f.comp_nature_value.toLowerCase().includes(val)
                    );
                    if (filtNat.length > 0) return x;
                    else return null;
                }
            } else {
                const columnValue =
                    !x[columnName] && columnName === "comp_nature_value"
                        ? x["compl_nature_manager"]
                        : x[columnName];
                if (columnValue || columnValue === 0) {
                    if (typeof columnValue === "string") {
                        if (
                            columnName === "comp_complaint_date" ||
                            columnName === "comp_fact_date"
                        ) {
                            return Moment(columnValue).isSame(Moment(val));
                        }
                        return columnValue.toLowerCase().includes(val);
                    } else if (typeof columnValue === "number") {
                        return columnValue === parseInt(val, 10);
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        }
    });
    const finalList = !allData
        ? filteredList.filter((x) => {
            return x.status !== "Close" && x.status !== "Cancel";
        })
        : filteredList;

    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = finalList.slice(indexOfFirstPost, indexOfLastPost);

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage === totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <div className="w-100">
                <nav className="d-flex justify-content-center align-items-center">
                    <ul className="pagination" style={ { cursor: "pointer" } }>
                        <li className="page-item">
                            {
                                <span onClick={ () => paginate(1) } className="p-1 pl-2 pr-2">
                                    { "«" }
                                </span>
                            }
                        </li>

                        <li className="page-item">
                            { currentPage === 1 ? (
                                <span className="p-1 pl-2 pr-2" disabled>
                                    { "<" }
                                </span>
                            ) : (
                                <span onClick={ () => paginate(currentPage - 1) } className="p-1 pl-2 pr-2">
                                    { "<" }
                                </span>
                            ) }
                        </li>

                        { pageNumbers.map((number) => (
                            <li key={ number } className="page-item">
                                { currentPage === number ? (
                                    <span onClick={ () => paginate(number) } className="bg-red p-1 pl-2 pr-2">
                                        { number }
                                    </span>
                                ) : (
                                    <span onClick={ () => paginate(number) } className="p-1 pl-2 pr-2">
                                        { number }
                                    </span>
                                ) }
                            </li>
                        )) }

                        <li className="page-item">
                            { currentPage === totalPages ? (
                                <span className="p-1 pl-2 pr-2" disabled>
                                    { ">" }
                                </span>
                            ) : (
                                <span onClick={ () => paginate(currentPage + 1) } className="p-1 pl-2 pr-2">
                                    { ">" }
                                </span>
                            ) }
                        </li>

                        <li className="page-item">
                            {
                                <span onClick={ () => paginate(totalPages) } className="p-1 pl-2 pr-2">
                                    { "»" }
                                </span>
                            }
                        </li>
                    </ul>
                </nav>
            </div>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const tableRef = useRef(null);

    function handleTypeClose() {
        setshowAdd_complaint(false);
        setshow_complaint(false);
        setshow_update_complaint(false);
        setShowModalView(false);
        setShowModalexplication(false);
        setShowModalexplication(false);
        setshowaccepte(false);
        setShowModalfichedisc(false);
        setshowclosemodale(false);
        setshownote(false);
        setshowupload(1);

        setSelectedValue([]);
        setSearchTerm("");
        setIsOpen(false);
        refreshing();
        setDetFiche1("");
        setShowFiche(null);
    }

    async function updateComplaint() {
        if (employee === "") {
            setmessage_employee("Choose a employee");
        }
        if (datecomplaint === "") {
            setdatecomplaintMessage("Choose a date of complaint");
        }
        if (datecomplaintsubmited === "") {
            setdatecomplaintsubmitedMessage("Choose a date");
        }
        if (nature === "") {
            setnatureMessage("Choose a nature");
        }
        if (sanction_proposed === "") {
            setsanction_proposedMessage("Choose a sanction");
        }
        if (
            employee !== "" &&
            datecomplaint !== "" &&
            datecomplaintsubmited !== "" &&
            nature !== "" &&
            sanction_proposed !== ""
        ) {
            const a = jwtDecode(sessionStorage.getItem("accessToken"));

            const response = await fetchData("POST", "complaint/update_complaint", {
                compl_id: compl_id,
                employee: employee,
                date_complaint: datecomplaint,
                date_complaintApply: datecomplaintsubmited,
                nature: nature,
                sanction_proposed: sanction_proposed,
                description: description,
                manager_id: a.id,
                username: a.username,
                per106: perms['106'] ? 1 : 0,
                per100: perms['100'] ? 1 : 0,
            });
            if (response.status === HttpStatus.OK) {
                notifySuccess("La plainte a été modifiée");
                setdisabledButone(false);
                settelechargement(0);
                handleTypeClose();
            } else if (
                response.response.data.result.status ===
                HttpStatus.NON_AUTHORITATIVE_INFORMATION
            ) {
            } else {
            }
        }
    }

    async function applysanction() {
        if (sanction_real === "") {
            setsanction_proposedMessage("Choisir une sanction");
        }

        const response = await fetchData("POST", "complaint/applysanction", {
            sanction: sanction_real,
            compl_id: compl_id,
            explication: hrExplication,
        });
        if (response.status === HttpStatus.OK) {
            change_statuss(6, compl_id);
            notifySuccess("La sanction a étée modifié");
            sethrExplication("");
            handleTypeClose();
        } else if (
            response.response.data.result.status ===
            HttpStatus.NON_AUTHORITATIVE_INFORMATION
        ) {
            //   setMessage("Check your ID and your password");
        } else {
        }
    }
    function handleAccepte(id) {
        setshowaccepte(true);
        setSelectedValue(
            allNatureSanction
                .filter((x) => x.complaint_id === id)
                .map((x) => ({
                    comp_nature_id: x.nature_id,
                    comp_nature_value: x.comp_nature_value,
                }))
        );
    }

    const [p_nom_plaignant, setp_nom_plaignant] = useState("");
    const [p_department, setp_department] = useState("");
    const [p_id_plaint, setp_id_plaint] = useState(-1);
    const [p_fonction, setp_fonction] = useState("");
    const [p_date_plainte, setp_date_plainte] = useState("");
    const [p_branch, setp_branch] = useState("");
    const [p_nom_accused, setp_nom_accused] = useState("");
    const [p_acussed_id, setp_acussed_id] = useState("");
    const [p_category, setp_category] = useState("");
    const [p_sanction, setp_sanction] = useState("");
    const [p_hrExplication, setp_hrExplication] = useState("");
    const [p_description, setp_description] = useState("");
    const [complaintForm, setcomplaintForm] = useState("print-container");
    const [plaintif_id, setplaintif_id] = useState("");
    const [complaintForm1, setcomplaintForm1] = useState("print-container");
    const [shownote, setshownote] = useState(false);
    const [noteAdd, setNoteAdd] = useState(false);
    const [note, setnote] = useState("");
    const [noteType, setNoteType] = useState("");
    const [sanct_status, setSanct_stat] = useState("");
    const [listNote, setListNote] = useState([]);
    const [listNoteType, setListNoteType] = useState([]);
    const [loaderNote, setLoaderNote] = useState(null);
    const [updateNote, setUpdateNote] = useState(null);
    const [hrP_description, sethrP_description] = useState("");
    const [hrP_sanction, sethrP_sanction] = useState("");

    const getSignedFile = async (type) => {
        try {
            const response = await axios.get(`/api/complaint/getFile?id=${documentComp_id}&type=${type}`, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            });
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            window.open(url, '_blank')
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    const componentRef = useRef();
    const componentRef1 = useRef();
    const ficheRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Fiche-HR",
    });

    const handlePrintFiche = useReactToPrint({
        content: () => ficheRef.current,
        documentTitle: "FICHE DISCIPLINAIRE",
    });

    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
        documentTitle: "Fiche-Demande d'explication",
    });

    async function acceptecomplait() {
        if (selectedValue.length > 0) {
            setMessageNature("");
            change_statuss(2, compl_id);

            const a = jwtDecode(sessionStorage.getItem("accessToken"));
            const response = await fetchData("POST", "complaint/add_hrdescription", {
                hr_description: descriptionHR,
                comp_id: compl_id,
                type: buttonAction,
                nature: selectedValue,
                username: a.username,
            });
            if (response.status === 200) {
                handleTypeClose();
            }
        } else {
            setMessageNature("Choose a nature");
        }
    }

    async function addComplaint() {
        // await getallcc();

        if (employee === "") {
            setmessage_employee("Choose a employee");
        } else {
            setmessage_employee("");
        }
        if (datecomplaint === "") {
            setdatecomplaintMessage("Choose a date of complaint");
        } else {
            setdatecomplaintMessage("");
        }
        if (datecomplaintsubmited === "") {
            setdatecomplaintsubmitedMessage("Choose a date");
        } else {
            setdatecomplaintsubmitedMessage("");
        }
        if (nature === "") {
            setnatureMessage("Choose a nature");
        } else {
            setnatureMessage("");
        }
        if (sanction_proposed === "") {
            setsanction_proposedMessage("Choose a sanction");
        } else {
            setsanction_proposedMessage("");
        }
        if (
            employee !== "" &&
            datecomplaint !== "" &&
            datecomplaintsubmited !== "" &&
            nature !== "" &&
            sanction_proposed !== ""
        ) {
            setdisabledButone(true);
            settelechargement(1);
            const a = jwtDecode(sessionStorage.getItem("accessToken"));

            const response = await fetchData("POST", "complaint/add_complaint", {
                employee: employee,
                date_complaint: datecomplaint,
                date_complaintApply: datecomplaintsubmited,
                nature: nature,
                sanction_proposed: sanction_proposed,
                description: description,
                manager_id: a.id,
                username: a.username,
                per106: perms['106'] ? 1 : 0,
                per100: perms['100'] ? 1 : 0,
            });
            if (response.status === HttpStatus.OK) {
                notifySuccess("La plainte a été ajouté");
                setdisabledButone(false);
                settelechargement(0);
                handleTypeClose();
            } else if (
                response.response.data.result.status ===
                HttpStatus.NON_AUTHORITATIVE_INFORMATION
            ) {
                //   setMessage("Check your ID and your password");
            }
        }
    }

    function handleAplysanction(d) {
        setplaintif_id(d.plaintif_id);
        setsanctionshow(d.sanction_value1);

        setcompl_id(d.comp_id);
        setemployee(d.accused_id);
        setsanction_proposed(d.sanct_id1);
        setshow_complaint(true);
    }

    function handleupdatesanction(d) {
        setplaintif_id(d.plaintif_id);
        setsanctionshow(d.sanction_value1);

        setcompl_id(d.comp_id);
        setemployee(d.accused_id);
        setsanction_proposed(d.sanct_id1);
        setshow_update_complaint(true);
    }

    const handleViewModal = (d) => {
        setShowModalView(true);
        setComplaintData(d);
        setp_nom_plaignant(d.plaintiff_name);
        setp_department(d.dep_name);
        setp_date_plainte(d.comp_complaint_date);
        setp_branch(d.branch_name);
        setp_nom_accused(d.accused_name);
        setp_acussed_id(d.accused_id);
        setp_category(d.comp_nature_value);
        setp_sanction(d.sanct_value);
        setp_hrExplication(d.hr_explication);
        sethrP_description(d.comp_hr_description);
        sethrP_sanction(d.sanction_value1);

        setp_description(d.comp_description);
        setcomplaintForm("print-container");
        setcomplaintForm1("print-container");
    };

    const getNote = async (id) => {
        const response = await fetchData("POST", "complaint/get_notes", {
            comp: id,
        });

        if (response.status === HttpStatus.OK) {
            setListNoteType(response.data.result.rows);
            setListNote(response.data.result.rows);
        } else if (
            response.response.data.result.status ===
            HttpStatus.NON_AUTHORITATIVE_INFORMATION
        ) {
            //   setMessage("Check your ID and your password");
        }
    };

    const handleNoteModal = (d) => {
        getNote(d.comp_id);
        setshownote(true);
        setcompl_id(d.comp_id);
        setnote(d.note);
    };

    const handleViewModal1 = (d) => {
        setComplaintData(d);
        setp_id_plaint(d.comp_id);
        setp_fonction(d.po_name);
        setp_nom_plaignant(d.plaintiff_name);
        setp_department(d.dep_name);
        setp_date_plainte(d.comp_complaint_date);
        setp_branch(d.branch_name);
        setp_nom_accused(d.accused_name);
        setp_acussed_id(d.accused_id);
        setp_category(d.comp_nature_value);
        setp_sanction(d.sanct_value);
        setp_hrExplication(d.hr_explication);
        sethrP_description(d.comp_hr_description);
        sethrP_sanction(d.sanction_value1);

        setp_description(d.comp_description);
        setcomplaintForm("print-container");
        setcomplaintForm1("print-container");
    };

    async function handleAdd() {
        const a = jwtDecode(sessionStorage.getItem("accessToken"));

        const response = await fetchData(
            "POST",
            "employee/get_all_employee_dep",
            {
                id: a.id,
                type: 114
            }
        );

        if (response.status === HttpStatus.OK) {
            setallemployee(response.data.result.data);
        } else if (
            response.response.data.result.status ===
            HttpStatus.NON_AUTHORITATIVE_INFORMATION
        ) {
            //   setMessage("Check your ID and your password");
        }

        setactionType(0);
        setemployee("");
        setdatecomplaint("");
        setdatecomplaintsubmited("");
        setnature("");
        setsanction_proposed("");
        setdescription("");
        setactionTitle("Nouvelle plainte");

        setshowAdd_complaint(true);
    }

    // Change page

    const loadDoc = async () => {
        let { allPlaint, allStatus } = [];

        const queryParameters = new URLSearchParams(window.location.search);
        if (queryParameters.get("id")) {
        }
        await setshowupload(1);

        const f_nature = await fetchData("POST", "nature/get_nature");
        if (f_nature.status === HttpStatus.OK) {
            setallnature(f_nature.data.result.data);
        } else if (
            f_nature.response.data.result.status ===
            HttpStatus.NON_AUTHORITATIVE_INFORMATION
        ) {
            //   setMessage("Check your ID and your password");
        }
        const f_sanction = await fetchData("POST", "nature/get_nature_sanction");
        if (f_sanction.status === HttpStatus.OK) {
            setallnature(f_sanction.data.result.data);
            setallnature(f_sanction.data.result.data);
        } else if (
            f_sanction.response.data.result.status ===
            HttpStatus.NON_AUTHORITATIVE_INFORMATION
        ) {
            //   setMessage("Check your ID and your password");
        }
        setList({
            ...list,
            listStatus: allStatus,
            listSanctions: allPlaint,
        });

        const all_nature = await fetchData("POST", "nature/get_nature");
        if (all_nature.status === HttpStatus.OK) {
            setallSubNature(all_nature.data.result.data);
        }

        const all_nature_sanctions = await fetchData(
            "POST",
            "nature/get_nature_sanction"
        );
        if (all_nature_sanctions.status === HttpStatus.OK) {
            setAllNatureSanction(all_nature_sanctions.data.result.data);
        }

        const all_sanctions = await fetchData("POST", "sanction/get_sanction");
        if (all_sanctions.status === HttpStatus.OK) {
            setallsanction(all_sanctions.data.result.data);
        }

        const v = jwtDecode(sessionStorage.getItem('accessToken'))
        const all_complaints = await fetchData(
            "POST",
            "complaint/get_all_complaints",
            { type: 100, id: v.id }

        );
        if (all_complaints.status === HttpStatus.OK) {
            setallcomplaint(all_complaints.data.result.data);
        }
        let newDate = new Date();
        await setactualdate(format(new Date(newDate), "dd-MM-yyyy"));
        await getAllCacheData();
    };

    function getDif(date1, date2) {
        if (
            date1 !== undefined &&
            date2 !== undefined &&
            moment(date1).isValid() &&
            moment(date2).isValid()
        ) {
            return moment(date2).diff(moment(date1), "hour");
        } else {
            return "";
        }
    }

    function getSanction(id) {
        let natures = "";
        const nat = allNatureSanction.filter((x) => x.complaint_id === id);
        if (nat.length > 0) {
            // eslint-disable-next-line
            nat.map((x, i) => {
                natures += `${i === 0 ? "" : ","} ${x.comp_nature_value}`;
            });
            return natures;
        } else {
            return nat;
        }
    }

    function exportd() {
        var v1, v2;
        const newArray = allcomplaint.map((item) => {
            if (
                Moment(item.explication_sendHr).diff(
                    Moment(item.comp_complaint_date),
                    "hours",
                    true
                ) > 24
            ) {
                v1 = "Yes";
            } else {
                v1 = "No";
            }

            if (
                Moment(item.explication_recivedHr).diff(
                    Moment(item.explication_sendHr),
                    "hours",
                    true
                ) > 24
            ) {
                v2 = "Yes";
            } else {
                v2 = "No";
            }

            return {
                ...item,
                LATE_Admin: v1,
                LATE_HR: v2,
            };
        });
        const exportData = [
            {
                id: "",
                non_accuse: "",
                nomPl: "",
                nature: "",
                desc: "",
                status: "",
                sanction_prop: " ",
                sanctionApl: " ",
                dateinf: "",
                dateplainte: "",
                dateAccept: " ",
                dateexpli: "",
                datesanction: "",
                signDate: "",
                closeDate: "",
                lateHR: "",
                lateAdmin: "",
            },
        ];
        for (let i = 0; i < newArray.length; i++) {
            exportData.push({
                id: newArray[i].comp_id,
                non_accuse: newArray[i].accused_name,
                nomPl: newArray[i].plaintiff_name,
                nature:
                    getSanction(newArray[i].comp_id).length > 0
                        ? getSanction(newArray[i].comp_id)
                        : newArray[i].compl_nature_manager,
                desc: newArray[i].comp_description,
                status: newArray[i].status,
                sanction_prop: newArray[i].sanction_value1,
                sanctionApl: newArray[i].sanct_value ? newArray[i].sanct_value : "",
                dateinf: moment(newArray[i].comp_fact_date).format("YYYY-MM-DD HH:mm"),
                dateplainte: moment(newArray[i].comp_complaint_date).format(
                    "YYYY-MM-DD HH:mm"
                ),
                dateAccept: newArray[i].explication_sendHr
                    ? moment(newArray[i].explication_sendHr).format("YYYY-MM-DD HH:mm")
                    : "",
                dateexpli: newArray[i].explication_recivedHr
                    ? moment(newArray[i].explication_recivedHr).format("YYYY-MM-DD HH:mm")
                    : "",
                datesanction: newArray[i].fiche_disc_sendHr
                    ? moment(newArray[i].fiche_disc_sendHr).format("YYYY-MM-DD HH:mm")
                    : "",
                signDate: newArray[i].fiche_disc_receved_Hr
                    ? moment(newArray[i].fiche_disc_receved_Hr).format("YYYY-MM-DD HH:mm")
                    : "",
                closeDate: newArray[i].closeDate
                    ? moment(newArray[i].closeDate).format("YYYY-MM-DD HH:mm")
                    : "",
                dif1: getDif(
                    newArray[i].comp_complaint_date,
                    newArray[i].comp_fact_date
                ),
                dif2: getDif(
                    newArray[i].comp_complaint_date,
                    newArray[i].explication_sendHr
                ),
                dif3: getDif(
                    newArray[i].explication_sendHr,
                    newArray[i].explication_recivedHr
                ),
                dif4: getDif(
                    newArray[i].explication_recivedHr,
                    newArray[i].fiche_disc_sendHr
                ),
                dif5: getDif(
                    newArray[i].fiche_disc_sendHr,
                    newArray[i].fiche_disc_receved_Hr
                ),
            });
        }

        downloadExcel({
            fileName: "Repport",
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header,
                // info: accept two different data structures
                body: exportData,
            },
        });
    }

    function truncateText(text, maxLength = 35) {
        if (text?.length > maxLength) {
            return text.slice(0, maxLength - 3) + "...";
        } else {
            return text;
        }
    }

    useEffect(() => {
        if (refresh > 0)
            loadDoc();
    }, [refresh, updateNote]);

    useEffect(() => {
        const load = async () => {
            await getPerm()
            setrefresh(c => refresh + 1)
        }
        load()
        // eslint-disable-next-line
    }, []);

    const [showFiche, setShowFiche] = useState(null);
    const [detFiche1, setDetFiche1] = useState("");

    const completeDetail = (id) => {
        setShowFiche(id);
        let detail = "";
        if (id === "FR35-S1") {
            detail = `De suite de ………………………………vous avez causé un dommage à l'entreprise qui équivaut à la somme de……… ; ce qui est assimilable à une faute intentionnelle qui demande une indemnisation à l'entreprise par une imputation sur salaire aux conditions ci-dessous :

            •	……à déduire de votre salaire pendant ……… 
            •

                    Par cette Lettre d'avertissement nous osons croire que vous allez vous amender et vous allez augmenter votre …..

                    Nous vous promettons une sanction plus sévère en cas d'une quelconque répétition. 

                    Salutations.
            `;
        } else if (id === "FR36-S1") {
            detail = `Nous sommes au regret de vous signifier la résiliation du Contrat de Travail qui nous lie avec vous après vous être rendu coupable ... .
            Le contrat de travail sera resilié (avec préavis ou sans préavis) selon les prescrit de l'article 72 du code du travail. Nous vous demandons de passer à la Direction des Ressources Humaines pour les dernières formalités administratives dans les prochaines quarante-huit heures.
            `;
        } else if (id === "FR24-S1") {
            detail = `Suite à la plainte qui nous est parvenue vous reprochant de ………………………………………………………. Nous sommes au regret de vous notifier cet avertissement après avoir examiné vos explications à leur juste valeur.
            Nous tenons, cependant à vous informer qu'en cas de répétition, la sanction deviendra de plus en plus sévère.

            Salutations.


        `;
        } else {
            detail = `Suite à la plainte qui vous accuse ${hrP_description}, nous sommes au regret de vous sanctionner par une mise à pied…….. Jours qui prendra cours entre le …/${moment().month() + 1
                }/${moment().year()} et le ../${moment().month() + 1
                }/${moment().year()} inclus.
            A cet effet, nous vous mettons en garde qu'en cas d'une quelconque répétition, nous n'hésiterons pas de vous sanctionner plus sévèrement.
            Franche collaboration

`;
        }
        setDetFiche1(detail);
    };

    const getColors = (id) => {
        if (id === 1) {
            return "#F3D7AD";
        } else if (id === 2) {
            return "#D8BBD4";
        } else {
            return "#EDD4D8";
        }
    };

    return (
        <>
            <MenuTestPage />
            <div style={ { zIndex: 1 } }>
                <div style={ { zIndex: 1 } }>
                    {/* Add task modal */ }

                    <Modal
                        show={ ShowModalexplication }
                        onHide={ handleTypeClose }
                        centered
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                FICHE DE DEMANDE D'EXPLICATION{ " " }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row d-print-none">
                                <div className="col-lg-12 border-right">
                                    <div className="d-flex align-middle">
                                        <button
                                            className="btn btn-defaut text-white bg-red font-weight-bold float-right m-1 mr-10"
                                            onClick={ () => {
                                                handlePrint1();
                                                refreshing();
                                            } }
                                        >
                                            <AiFillPrinter />
                                            <span className="ml-1 mb-1">Imprimer</span>
                                        </button>

                                        { documentSignedPath === null ||
                                            documentId_statusDownload === 2 ? (
                                            <button
                                                onClick={ () => {
                                                    setshowupload(0);
                                                } }
                                                className="btn btn-defaut text-white bg-success font-weight-bold float-right m-1 mr-10"
                                            >
                                                <RiSendPlaneFill />
                                                <span className="ml-1 mb-1">Envoyer</span>
                                            </button>
                                        ) : null }
                                        { documentId_statusDownload === 5 ||
                                            documentId_statusDownload === 6 ||
                                            documentId_statusDownload === 7 ||
                                            documentId_statusDownload === 4 ? (
                                            <>
                                                <button
                                                    onClick={ () => getSignedFile('explication') }
                                                    className=" canceled btn btn-defaut text-white font-weight-bold float-right m-1 mr-10"
                                                >
                                                    <ImDownload3 />
                                                    <span className="ml-1 mb-1">Ouvrir</span>
                                                </button>
                                                {
                                                    perms['113'] === true ? <button
                                                        onClick={ () => { deleteFile("explication") } }
                                                        className=" done btn btn-defaut text-white font-weight-bold float-right m-1 mr-5"
                                                    >
                                                        <AiFillDelete />
                                                        <span className="ml-1 mb-1 p-2">Supprimer le fichier</span>
                                                    </button> : null
                                                }

                                            </>
                                        ) : null }
                                    </div>
                                    <div className="d-flex h-10">
                                        <input
                                            type="file"
                                            onChange={ uploadFile }
                                            className={
                                                showupload === 1
                                                    ? " form-control profile-pic-uploader mt-10 mb-5 d-none"
                                                    : "form-control profile-pic-uploader  mb-5  mt-10"
                                            }
                                        />
                                    </div>
                                    { telechargement === 1 ? (
                                        <div className={ "item" }>
                                            <Loader
                                                type="spinner-default"
                                                bgColor={ "#357E06" }
                                                color={ "#357E06" }
                                                size={ 40 }
                                            />
                                            <p className="text-center">Telechargement</p>
                                        </div>
                                    ) : null }

                                    {/* <span className="text-success">{textmessage}</span> */ }
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button ref={ null } onClick={ handleTypeClose } variant="default">
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={ shownote }
                        onHide={ handleTypeClose }
                        centered
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                NOTE SUR UNE PLAINTE{ " " }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="form-group mb-3 col-xl-12 pl-3 pr-3 pt-2 ">
                                    <div className="d-flex justify-content-end align-items-center w-100 cursor-pointer">
                                        <label className="mt-2 cursor-pointer" htmlFor="add">
                                            Ajouter
                                        </label>
                                        <input
                                            id="add"
                                            type="checkbox"
                                            className="ml-2"
                                            checked={ noteAdd }
                                            onChange={ (e) => {
                                                setNoteAdd(e.target.checked);
                                                setUpdateNote(null);
                                                setnote("");
                                                setNoteType("");
                                                setSanct_stat("");
                                            } }
                                        />
                                    </div>

                                    <div
                                        style={ {
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                        } }
                                        className="h-400px overflow-auto"
                                    >
                                        { noteAdd && (
                                            <Note
                                                listNoteType={ listNoteType }
                                                note={ note }
                                                setNote={ setnote }
                                                view={ false }
                                                noteType={ noteType }
                                                setNoteType={ setNoteType }
                                                sanctStatus={ sanct_status }
                                                setSancStatus={ setSanct_stat }
                                                add={ addNote }
                                                loader={ loaderNote }
                                                color={ "#B1D3EF" }
                                            />
                                        ) }
                                        { listNote.length > 0 ? (
                                            listNote.map((e, index) => {
                                                return (
                                                    <Note
                                                        key={ index }
                                                        data={ e }
                                                        view={ true }
                                                        listNoteType={ listNoteType }
                                                        note={ note }
                                                        setNote={ setnote }
                                                        noteType={ noteType }
                                                        update={ modifyNote }
                                                        setNoteType={ setNoteType }
                                                        sanctStatus={ sanct_status }
                                                        setSancStatus={ setSanct_stat }
                                                        color={ getColors(e.note_type) }
                                                        setUpdateNote={ setUpdateNote }
                                                        updateNote={ updateNote }
                                                    />
                                                );
                                            })
                                        ) : (
                                            <img
                                                alt="Empty"
                                                src={ Empty }
                                                className="logo-default"
                                                style={ { height: "300px", width: "300px" } }
                                            />
                                        ) }
                                    </div>

                                    {/* <Note /> */ }
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button ref={ null } onClick={ handleTypeClose } variant="primary">
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={ ShowModalfichedisc }
                        onHide={ handleTypeClose }
                        centered
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                FICHE DISCIPLINAIRE{ " " }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row d-print-none">
                                <div className="col-lg-12 border-right">
                                    <div className="d-flex flex-wrap justify-content-center align-middle">
                                        <button
                                            onClick={ () => handlePrint() }
                                            className="canceled btn btn-default text-white font-weight-bold float-right m-1 mr-5"
                                        >
                                            <AiFillPrinter />
                                            <span className="ml-1 mb-2">Fiche Disciplinaire </span>
                                        </button>
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-default text-black font-weight-bold float-right m-1 mr-5 dropdown-toggle "
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <AiFillPrinter size={ 20 } />
                                                <span className="ml-1 mb-2">
                                                    Fiches <ChevronDown size={ 20 } />
                                                </span>
                                            </button>
                                            <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <div
                                                    className="dropdown-item cursor-pointer border"
                                                    onClick={ () => completeDetail("FR35-S1") }
                                                >
                                                    Fiche d'imputation dommageable
                                                </div>
                                                <div
                                                    className="dropdown-item cursor-pointer border"
                                                    onClick={ () => completeDetail("FR36-S1") }
                                                >
                                                    Fiche de licenciement{ " " }
                                                </div>
                                                <div
                                                    className="dropdown-item cursor-pointer border"
                                                    onClick={ () => completeDetail("FR24-S1") }
                                                >
                                                    Fiche d'avertissement{ " " }
                                                </div>
                                                <div
                                                    className="dropdown-item cursor-pointer border"
                                                    onClick={ () => completeDetail("FR23-S1") }
                                                >
                                                    Fiche de mise à pied
                                                </div>
                                            </div>
                                        </div>
                                        { status_comp === 7 || fichepath == "" ? null : (
                                            <button
                                                onClick={ () => {
                                                    setshowupload(0);
                                                } }
                                                className="btn btn-defaut text-white bg-success font-weight-bold float-right m-1 mr-5"
                                            >
                                                <RiSendPlaneFill />
                                                <span className="ml-1 mb-1">Envoyer</span>
                                            </button>
                                        ) }

                                        { fichepath !== null ? (
                                            <div>
                                                {
                                                    perms['113'] === true ?
                                                        <button
                                                            onClick={ () => { deleteFile("discipline") } }
                                                            className=" done btn btn-defaut text-white font-weight-bold float-right m-1 mr-5"
                                                        >
                                                            <AiFillDelete />
                                                            <span className="ml-1 mb-1"> Supprimer le Fichier</span>
                                                        </button> : null
                                                }


                                                <button
                                                    onClick={ () => getSignedFile('discipline') }
                                                    className=" canceled btn btn-defaut text-white font-weight-bold float-right m-1 mr-5"
                                                >
                                                    <ImDownload3 />
                                                    <span className="ml-1 mb-1">Ouvrir</span>
                                                </button>
                                            </div>
                                        ) : null }
                                    </div>
                                    <div className="d-flex h-10">
                                        <input
                                            type="file"
                                            onChange={ uploadFile1 }
                                            className={
                                                showupload === 1
                                                    ? " form-control profile-pic-uploader mt-10 mb-5 d-none"
                                                    : "form-control profile-pic-uploader  mb-5  mt-10"
                                            }
                                        />
                                    </div>
                                    { telechargement === 1 ? (
                                        <div className={ "item" }>
                                            <Loader
                                                type="spinner-default"
                                                bgColor={ "#357E06" }
                                                color={ "#357E06" }
                                                size={ 40 }
                                            />
                                            <p className="text-center">Telechargement</p>
                                        </div>
                                    ) : null }

                                    {/* <span className="text-success">{textmessage}</span> */ }
                                </div>

                                { showFiche ? (
                                    <div className="container mt-5 " ref={ ficheRef }>
                                        <div className="row">
                                            <div className="col border d-flex justify-content-center align-items-center">
                                                <img
                                                    alt="Logo"
                                                    src={ logo }
                                                    className="logo-default max-h-60px"
                                                />
                                            </div>
                                            <div className="col border d-flex justify-content-center align-items-center text-center">
                                                { showFiche === "FR35-S1"
                                                    ? `Fiche d'avertissement avec imputation sur salaire`
                                                    : showFiche === "FR24-S1"
                                                        ? `Fiche Avertissement `
                                                        : showFiche === "FR23-S1"
                                                            ? `Fiche de mise à pied `
                                                            : "Fiche de licenciement" }
                                            </div>
                                            <div className="col border d-flex flex-column p-0">
                                                <div className="border-bottom d-flex flex-column justify-content-center align-items-center flex-grow-1">
                                                    <div>Code : { showFiche }</div>
                                                    <div>
                                                        Version : { showFiche === "FR24-S1" ? "002" : "001" }
                                                    </div>
                                                </div>
                                                <div className="border-top d-flex flex-column justify-content-center align-items-center flex-grow-1">
                                                    <div>Date d'application :</div>
                                                    <div>
                                                        { showFiche === "FR24-S1"
                                                            ? "07/01/2023"
                                                            : "08 Sept 2021" }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-20 px-20">
                                            <div className="">
                                                <div className="text-right">
                                                    Lubumbashi, le { moment().format("DD/MM/YYYY") }
                                                </div>
                                                <div className="text-left">
                                                    N.Réf. : { p_id_plaint }/G.A. L/G. K/J. K/
                                                    { moment().year() }
                                                </div>
                                            </div>

                                            <div className="mt-10 row">
                                                <div className="col">
                                                    concerne :{ " " }
                                                    { showFiche === "FR35-S1"
                                                        ? `Lettre d'avertissement`
                                                        : showFiche === "FR24-S1"
                                                            ? `Avertissement`
                                                            : showFiche === "FR23-S1"
                                                                ? `Lettre de mise à pied`
                                                                : `Licenciement` }
                                                </div>
                                                <div className="col"> </div>
                                                <div className="col text-left d-flex flex-column">
                                                    <div>A Mr/Mme { p_nom_accused }</div>
                                                    <div>Mat. : { p_acussed_id }</div>
                                                    <div>Fonction : { p_fonction } </div>
                                                    <div>
                                                        À{ " " }
                                                        { p_branch.includes("Likasi") ||
                                                            p_branch.includes("Kolwezi")
                                                            ? p_branch
                                                            : "Lubumbashi" }
                                                    </div>
                                                </div>
                                            </div>

                                            <span>Monsieur/Madame</span>

                                            <div className="mt-5">
                                                <textarea
                                                    style={ { border: "none", resize: "none" } }
                                                    className="form-control w-100 "
                                                    rows={ 20 }
                                                    onChange={ (e) => {
                                                        setDetFiche1(e.target.value);
                                                    } }
                                                    value={ detFiche1 }
                                                />
                                            </div>

                                            <div className="mt-10 row">
                                                <div className="col col-2">L'employé </div>
                                                <div className="col col-4"> </div>
                                                <div className="col col-6 w-100 text-center">
                                                    <div className="mt-2">La Direction</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                ) }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button ref={ null } onClick={ handleTypeClose } variant="default">
                                Fermer
                            </Button>
                            { showFiche && (
                                <Button ref={ null } onClick={ handlePrintFiche } variant="primary">
                                    <Printer size={ 20 } />
                                </Button>
                            ) }
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={ showclosemodale }
                        onHide={ handleTypeClose }
                        centered
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Cloture de la plainte{ " " }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row d-print-none">
                                <p>Voulez-vous cloturer la plainte?</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                ref={ null }
                                onClick={ () => {
                                    change_statuss(4, compl_id);
                                    handleTypeClose();
                                } }
                                variant="default"
                            >
                                Oui
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={ ShowModalView }
                        onHide={ handleTypeClose }
                        centered
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                DETAILS DE LA PLAINTE
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row d-print-none">
                                <div className="col-lg-12 border-right">
                                    <div
                                        className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 d-flex justify-content-between"
                                        id="print-btn"
                                    >
                                        <div className="pt-2">Voir la plainte </div>
                                        <button
                                            onClick={ () => {
                                                handlePrint();
                                            } }
                                            className="btn btn-light border btn-sm font-weight-bold rounded-0"
                                        >
                                            <i aria-hidden="true" className="la la-print"></i>{ " " }
                                            Imprimer
                                        </button>
                                    </div>

                                    <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Numero de la plaint:{ " " }
                                        </label>
                                        <p id="ticketid-v" className="pl-2">
                                            { complaintData.comp_id }
                                        </p>
                                        <input
                                            type="text"
                                            id="ticketid-print"
                                            className="form-control"
                                            hidden
                                        />
                                    </div>

                                    <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Nom de l'accusé :{ " " }
                                        </label>
                                        <p id="ticketsubject-v" className="pb-2">
                                            { complaintData.accused_name }
                                        </p>
                                    </div>

                                    <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Nom du plaignant :{ " " }
                                        </label>
                                        <p id="ticketdesc-v" className="pb-2">
                                            { complaintData.plaintiff_name }
                                        </p>
                                    </div>

                                    <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Description de la plainte :{ " " }
                                        </label>
                                        <p id="ticketpriority-v" className="pl-2">
                                            { complaintData.comp_description }
                                        </p>
                                    </div>

                                    <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Date de la plainte
                                        </label>
                                        <p id="equipementsite-v" className="pb-2">
                                            { complaintData.comp_complaint_date }
                                        </p>
                                    </div>

                                    <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Sanctionproposée :{ " " }
                                        </label>
                                        <p id="status-v" className="pl-2">
                                            { complaintData.sanction_value1 }
                                        </p>
                                    </div>

                                    <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">
                                            Sanction appliquée :{ " " }
                                        </label>
                                        <p id="createddate-v" className="pb-2">
                                            { complaintData.sanct_value }
                                        </p>
                                    </div>
                                    <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">Explication : </label>
                                        <p id="createddate-v" className="pb-2">
                                            { complaintData.hr_explication }
                                        </p>
                                    </div>
                                    <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                                        <label className="font-weight-bolder">Explication : </label>
                                        <p id="createddate-v" className="pb-2">
                                            { complaintData.note }
                                        </p>
                                    </div>
                                    <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                                        <div className="flex d-flex">
                                            <div className="col-xl-6">
                                                <label className="font-weight-bolder">
                                                    Date d’envoie de DM par RH :{ " " }
                                                </label>
                                                <p id="createddate-v" className="pb-2">
                                                    { complaintData.explication_sendHr
                                                        ? format(
                                                            new Date(complaintData.explication_sendHr),
                                                            "dd-MM-yyyy hh:mm"
                                                        )
                                                        : null }
                                                </p>
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="font-weight-bolder ">
                                                    Date d’envoie de DM par Manager :{ " " }
                                                </label>
                                                <p id="createddate-v" className="pb-2">
                                                    { complaintData.explication_recivedHr
                                                        ? format(
                                                            new Date(complaintData.explication_recivedHr),
                                                            "dd-MM-yyyy hh:mm"
                                                        )
                                                        : null }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group bg-light2 pl-3 pr-3 pt-2">
                                        <div className="flex d-flex">
                                            <div className="col-xl-6">
                                                <label className="font-weight-bolder">
                                                    Date d’envoie de la fiche disciplinaire par RH :{ " " }
                                                </label>
                                                <p id="createddate-v" className="pb-2">
                                                    { complaintData.fiche_disc_sendHr
                                                        ? format(
                                                            new Date(complaintData.explication_sendHr),
                                                            "dd-MM-yyyy hh:mm"
                                                        )
                                                        : null }
                                                </p>
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="font-weight-bolder">
                                                    Date d’envoie de la fiche disciplinaire par Manager :{ " " }
                                                </label>
                                                <p id="createddate-v" className="pb-2">
                                                    { complaintData.fiche_disc_receved_Hr
                                                        ? format(
                                                            new Date(complaintData.fiche_disc_receved_Hr),
                                                            "dd-MM-yyyy hh:mm"
                                                        )
                                                        : null }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button ref={ null } onClick={ handleTypeClose } variant="default">
                                Fermer
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ showAdd_complaint } onHide={ handleTypeClose } centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{ actionTitle }</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group mb-4 mt-3">
                                    <label className="font-weight-bold">
                                        Employée<span className="text-danger pl-3">*</span>
                                    </label>
                                    <select
                                        id="task-cat"
                                        onChange={ (e) => {
                                            setemployee(e.target.value);
                                        } }
                                        value={ employee }
                                        className="form-control rounded-0 cursor-pointer"
                                    >
                                        <option key={ 0 } value={ 0 }></option>
                                        { all_employee ? (
                                            all_employee.map((d, index) => (
                                                <option key={ d.e_id } value={ d.e_id }>
                                                    { " " }
                                                    { d.e_id + " - " + d.e_name }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { message_employee }
                                    </div>

                                    <label className="font-weight-bold">
                                        Date de la plainte
                                        <span className="text-danger pl-3">*</span>
                                    </label>
                                    <input
                                        type={ "date" }
                                        onChange={ (e) => {
                                            setdatecomplaint(e.target.value);
                                        } }
                                        value={ datecomplaint }
                                        className="form-control rounded-0"
                                    />

                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { datecomplaintMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Date de la faute
                                            <span className="text-danger pl-3">*</span>
                                        </label>
                                    </div>
                                    <input
                                        onChange={ (e) => {
                                            setdatecomplaintsubmited(e.target.value);
                                        } }
                                        value={ datecomplaintsubmited }
                                        type="date"
                                        className="form-control rounded-0 cursor-pointer"
                                    />

                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        { datecomplaintsubmitedMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Nature
                                            <span className="text-danger pl-3">*</span>
                                        </label>
                                    </div>
                                    <input
                                        onChange={ (e) => {
                                            setnature(e.target.value);
                                        } }
                                        value={ nature }
                                        type="text"
                                        className="form-control rounded-0 cursor-pointer"
                                    />

                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        { natureMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Sanction proposée
                                            <span className="text-danger pl-3">*</span>
                                        </label>
                                    </div>
                                    <select
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            setsanction_proposed(e.target.value);
                                        } }
                                        value={ sanction_proposed }
                                        className="form-control rounded-0 cursor-pointer"
                                    >
                                        <option key={ 0 } value={ "" }></option>
                                        { allsanction ? (
                                            allsanction.map((d, index) => (
                                                <option key={ index } value={ d.sanct_id }>
                                                    { " " }
                                                    { d.sanct_value }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        { sanction_proposedMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <label className="font-weight-bold">
                                        Description
                                        <span className="text-danger pl-3"></span>
                                    </label>
                                    <textarea
                                        type="text"
                                        onChange={ (e) => {
                                            setdescription(e.target.value);
                                        } }
                                        value={ description }
                                        name="subject"
                                        className="form-control rounded-0 cursor-pointer h-30"
                                    />

                                    <div id="site-error" className="text-danger pt-2">
                                        {/* {descriptionMessage} */ }
                                    </div>
                                </div>
                            </form>
                            <div className="h-10">
                                { telechargement === 1 ? (
                                    <div className={ "item" }>
                                        <Loader
                                            type="spinner-default"
                                            bgColor={ "#357E06" }
                                            color={ "#357E06" }
                                            size={ 40 }
                                        />
                                    </div>
                                ) : null }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleTypeClose }>
                                Fermer
                            </Button>

                            { actionType === 0 ? (
                                <Button
                                    variant="primary"
                                    onClick={ addComplaint }
                                    disabled={ disabledButone }
                                    className="h-10"
                                >
                                    Ajouter une plainte
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={ updateComplaint }
                                    disabled={ disabledButone }
                                >
                                    Modifier la plainte
                                </Button>
                            ) }
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ showaccepte } onHide={ handleTypeClose } centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Accepter la plainte</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group mb-4">
                                    <label className="font-weight-bold mt-2">
                                        Type nature
                                        <span className="text-danger pl-1"></span>
                                    </label>
                                    <select
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            filterGroupe(e.target.value, accusedPositionID);
                                        } }
                                        className="form-control rounded-0 cursor-pointer"
                                    >
                                        <option key={ -1 } value={ -1 }>
                                            General
                                        </option>
                                        <option key={ 1 } value={ 1 }>
                                            Approprié
                                        </option>
                                    </select>

                                    <label className="font-weight-bold">
                                        Nature
                                        <span className="text-danger pl-3">*</span>
                                    </label>

                                    <div className="dropdown w-100">
                                        <div
                                            className="btn border dropdown-toggle w-100 text-left"
                                            type="button"
                                            onClick={ toggleDropdown }
                                        >
                                            <div className="flex d-flex justify-content-between align-items-center">
                                                <div className="d-flex flex-nowrap align-items-center overflow-auto">
                                                    { [...selectedValue].reverse().map((option, index) => (
                                                        <div
                                                            key={ index }
                                                            className="badge rounded-pill bg-light border mr-1 mb-1 d-flex align-items-center"
                                                        >
                                                            <span className="mr-2">
                                                                { option.comp_nature_value }
                                                            </span>
                                                            <button
                                                                type="button"
                                                                className="btn-close btn-close-white p-0"
                                                                aria-label="Close"
                                                                onClick={ (e) => handleRemoveClick(e, option) }
                                                            ></button>
                                                        </div>
                                                    )) }
                                                </div>
                                                <span className="ml-2">
                                                    <ChevronDown />
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            className={ `w-100 dropdown-menu overflow-auto rounded-none  ${isOpen ? "show" : ""
                                                }` }
                                            style={ divStyle }
                                        >
                                            <div className="input-group px-3 py-2">
                                                <span className="input-group-text">
                                                    <Search />
                                                </span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Rechercher"
                                                    value={ searchTerm ? searchTerm : "" }
                                                    onChange={ (e) => {
                                                        setSearchTerm(e.target.value);
                                                    } }
                                                />
                                            </div>
                                            <div className="dropdown-menu-scrollable w-100 cursor-pointer">
                                                { filteredSubNature
                                                    .sort((a, b) =>
                                                        a.comp_nature_value.localeCompare(
                                                            b.comp_nature_value
                                                        )
                                                    )
                                                    .map((option, index) => {
                                                        if (option.print === 1)
                                                            return (
                                                                <div
                                                                    key={ index }
                                                                    className="dropdown-item w-100 text-wrap"
                                                                    onClick={ () => handleOptionClick(option) }
                                                                >
                                                                    <span className="">
                                                                        { option.comp_nature_value }
                                                                    </span>
                                                                </div>
                                                            );
                                                        else return null;
                                                    }) }
                                            </div>
                                        </div>
                                    </div>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { messageNature }
                                    </div>

                                    <label className="font-weight-bold mt-2">
                                        Description HR
                                        <span className="text-danger pl-3"></span>
                                    </label>
                                    <textarea
                                        type="text"
                                        onChange={ (e) => {
                                            setdescriptionHR(e.target.value);
                                        } }
                                        value={ descriptionHR }
                                        name="subject"
                                        className="form-control rounded-0 cursor-pointer h-30"
                                    />
                                </div>
                            </form>
                            <div className="h-10">
                                { telechargement === 1 ? (
                                    <div className={ "item" }>
                                        <Loader
                                            type="spinner-default"
                                            bgColor={ "#357E06" }
                                            color={ "#357E06" }
                                            size={ 40 }
                                        />
                                    </div>
                                ) : null }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleTypeClose }>
                                Close
                            </Button>

                            <Button
                                variant="primary"
                                onClick={ acceptecomplait }
                                disabled={ disabledButone }
                                className="h-10"
                            >
                                { buttonAction }
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ show_complaint } onHide={ handleTypeClose } centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Appliquer une sanction</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group mb-4 mt-3">
                                    <label className="font-weight-bold">
                                        Nom du plaignant <span className="text-danger pl-3"></span>
                                    </label>
                                    <select
                                        id="task-cat"
                                        onChange={ (e) => {
                                            setemployee(e.target.value);
                                        } }
                                        value={ plaintif_id }
                                        className="form-control rounded-0 cursor-pointer"
                                        disabled
                                    >
                                        <option key={ 0 } value={ 0 }></option>
                                        { all_employee ? (
                                            all_employee.map((d, index) => (
                                                <option key={ index } value={ d.e_id }>
                                                    { " " }
                                                    { d.e_id + " - " + d.e_name }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { message_employee }
                                    </div>

                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { datecomplaintMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4 mt-3">
                                    <label className="font-weight-bold">
                                        Employée (Accusé(e))
                                        <span className="text-danger pl-3"></span>
                                    </label>
                                    <select
                                        id="task-cat"
                                        onChange={ (e) => {
                                            setemployee(e.target.value);
                                        } }
                                        value={ employee }
                                        className="form-control rounded-0 cursor-pointer"
                                        disabled
                                    >
                                        <option key={ 0 } value={ 0 }></option>
                                        { all_employee ? (
                                            all_employee.map((d, index) => (
                                                <option key={ index } value={ d.e_id }>
                                                    { " " }
                                                    { d.e_id + " - " + d.e_name }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { message_employee }
                                    </div>

                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { datecomplaintMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Sanction
                                            <span className="text-danger pl-3">*</span>
                                        </label>
                                    </div>
                                    <label className="font-weight-bold text-danger ml-0">
                                        Sanction Proposée par le manager
                                        <span className="text-danger pl-3"> { sanctionshow }</span>
                                    </label>
                                    <select
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            setsanction_real(e.target.value);
                                        } }
                                        value={ sanction_real }
                                        className="form-control rounded-0 cursor-pointer"
                                    >
                                        <option key={ 0 } value={ "" }></option>
                                        { allsanction ? (
                                            allsanction
                                                .sort((a, b) =>
                                                    a.sanct_value.localeCompare(b.sanct_value)
                                                )
                                                .map((d, index) => (
                                                    <option key={ index } value={ d.sanct_id }>
                                                        { d.sanct_value }
                                                    </option>
                                                ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        {/* {sanction_realMessage} */ }
                                    </div>
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Explication
                                            <span className="text-danger pl-3"></span>
                                        </label>
                                    </div>

                                    <textarea
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            sethrExplication(e.target.value);
                                        } }
                                        value={ hrExplication }
                                        className="form-control rounded-0 cursor-pointer"
                                    />
                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        { null }
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleTypeClose }>
                                Annuler
                            </Button>
                            <Button variant="primary" onClick={ applysanction }>
                                Appliquer
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={ show_update_complaint } onHide={ handleTypeClose } centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Modifier la sanction</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group mb-4 mt-3">
                                    <label className="font-weight-bold">
                                        Nom du plaignant <span className="text-danger pl-3"></span>
                                    </label>
                                    <select
                                        id="task-cat"
                                        onChange={ (e) => {
                                            setemployee(e.target.value);
                                        } }
                                        value={ plaintif_id }
                                        className="form-control rounded-0 cursor-pointer"
                                        disabled
                                    >
                                        <option key={ 0 } value={ 0 }></option>
                                        { all_employee ? (
                                            all_employee.map((d, index) => (
                                                <option key={ index } value={ d.e_id }>
                                                    { " " }
                                                    { d.e_id + " - " + d.e_name }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { message_employee }
                                    </div>

                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { datecomplaintMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4 mt-3">
                                    <label className="font-weight-bold">
                                        Employée (Accusé(e))
                                        <span className="text-danger pl-3"></span>
                                    </label>
                                    <select
                                        id="task-cat"
                                        onChange={ (e) => {
                                            setemployee(e.target.value);
                                        } }
                                        value={ employee }
                                        className="form-control rounded-0 cursor-pointer"
                                        disabled
                                    >
                                        <option key={ 0 } value={ 0 }></option>
                                        { all_employee ? (
                                            all_employee.map((d, index) => (
                                                <option key={ index } value={ d.e_id }>
                                                    { " " }
                                                    { d.e_id + " - " + d.e_name }{ " " }
                                                </option>
                                            ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { message_employee }
                                    </div>

                                    <div id="ticketprio-error" className="text-danger pt-2">
                                        { datecomplaintMessage }
                                    </div>
                                </div>

                                <div className="form-group mb-4">
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Sanction
                                            <span className="text-danger pl-3">*</span>
                                        </label>
                                    </div>
                                    <label className="font-weight-bold text-danger ml-0">
                                        Sanction Proposée par le manager
                                        <span className="text-danger pl-3"> { sanctionshow }</span>
                                    </label>
                                    <select
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            setsanction_real(e.target.value);
                                        } }
                                        value={ sanction_real }
                                        className="form-control rounded-0 cursor-pointer"
                                    >
                                        <option key={ 0 } value={ "" }></option>
                                        { allsanction ? (
                                            allsanction
                                                .sort((a, b) =>
                                                    a.sanct_value.localeCompare(b.sanct_value)
                                                )
                                                .map((d, index) => (
                                                    <option key={ index } value={ d.sanct_id }>
                                                        { d.sanct_value }
                                                    </option>
                                                ))
                                        ) : (
                                            <option key={ 0 } value={ "" }></option>
                                        ) }
                                    </select>
                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        {/* {sanction_realMessage} */ }
                                    </div>
                                    <div className="" align="left">
                                        <label className="font-weight-bold">
                                            Explication
                                            <span className="text-danger pl-3"></span>
                                        </label>
                                    </div>

                                    <textarea
                                        id="cstm-contact"
                                        onChange={ (e) => {
                                            sethrExplication(e.target.value);
                                        } }
                                        value={ hrExplication }
                                        className="form-control rounded-0 cursor-pointer"
                                    />
                                    <div id="cstm-contact-error" className="text-danger pt-3">
                                        { null }
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ handleTypeClose }>
                                Annuler
                            </Button>
                            <Button variant="primary" onClick={ applysanction }>
                                Appliquer
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <FilterModal
                        open={ showFilter }
                        setOpen={ setShowFilter }
                        setVal={ setSearch }
                        type={ filt_col }
                        paginate={ paginate }
                        list={ list }
                        title={ title }
                    />
                </div>

                <div
                    className="col-xl-12 center-block"
                    align="center"
                    style={ { zIndex: 1 } }
                >
                    <div
                        className="col-xl-10 bg-white mt-50 center-block min-h-650px max-h-650px"
                        align="center"
                        style={ { zIndex: 1 } }
                    >
                        <div
                            className="pt-10 w-100 h-80px"
                            style={ { marginLeft: "0.8rem" } }
                        >
                            <h3 className="float-left pt-2 pr-3" onClick={ () => { } }>
                                Liste de plaintes
                            </h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={ () => {
                                        handleTypeClose();
                                    } }
                                    className="cursor-pointer display-4"
                                />
                            </span>
                            <div className="float-left  pt-2 pr-3 mx-2 align-middle">
                                <input
                                    type="checkbox"
                                    className="align-middle"
                                    id="all"
                                    checked={ allData }
                                    onChange={ (e) => {
                                        setallData(e.target.checked);
                                    } }
                                />
                                <label
                                    className="mx-2 mb-0 align-middle cursor-pointer"
                                    htmlFor="all"
                                >
                                    Tout Afficher
                                </label>
                            </div>

                            { perms['102'] === true ? (
                                <button
                                    onClick={ handleAdd }
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10"
                                    data-toggle="modal"
                                    data-target="#addticketModal"
                                >
                                    <PlusCircle size={ 25 } />
                                </button>
                            ) : null }
                            <button
                                onClick={ () => {
                                    exportd();
                                } }
                                id="crticket-btn"
                                className="btn btn-defaut text-white bg-secondary font-weight-bold float-right mr-2"
                                data-toggle="modal"
                                data-target="#addticketModal"
                            >
                                <SiMicrosoftexcel size={ 25 } className="text-success" />
                            </button>
                            <button
                                onClick={ () => {
                                    setSearch(null);
                                } }
                                className="btn btn-defaut text-white bg-primary font-weight-bold float-right mr-2"
                            >
                                <FcClearFilters size={ 25 } />
                            </button>
                        </div>

                        <div className="h-550px table-responsive" style={ { zIndex: 1 } }>
                            <table
                                id="customers"
                                className="table"
                                ref={ tableRef }
                                style={ { zIndex: 1 } }
                            >
                                <thead className="sticky-top" style={ { background: "#f8f9fa" } }>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="w-25px "
                                            style={ { left: 0, background: "#f8f9fa" } }
                                        >
                                            #
                                        </th>

                                        <th
                                            scope="col"
                                            className="text-left min-w-180px "
                                            style={ { left: 33, background: "#f8f9fa" } }
                                        >
                                            Nom de l'accusé
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("accused_name");
                                                    setTitle("Nom de l'accusé");
                                                } }
                                            ></i>
                                        </th>
                                        <th
                                            scope="col"
                                            className="text-left min-w-180px "
                                            style={ { left: 200, background: "#f8f9fa" } }
                                        >
                                            Nom du plaignant
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("plaintiff_name");
                                                    setTitle("Nom du plaignant");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-250px">
                                            Nature
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("comp_nature_value");
                                                    setTitle("Nature");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-250px">
                                            Description
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("comp_description");
                                                    setTitle("Description");
                                                } }
                                            ></i>
                                        </th>
                                        <th scope="col" className="text-left min-w-100px">
                                            Status
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("id_status");
                                                    setTitle("Status");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-180px">
                                            Sanction proposée
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("sanct_id1");
                                                    setTitle("Sanction proposée");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-180px">
                                            Sanction appliquée
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("sanct_id");
                                                    setTitle("Sanction appliquée");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-180px">
                                            Date de l'infraction
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("comp_fact_date");
                                                    setTitle("Date de l'infraction");
                                                } }
                                            ></i>
                                        </th>

                                        <th scope="col" className="text-left min-w-180px">
                                            Date de la plainte
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("comp_complaint_date");
                                                    setTitle("Date de la plainte");
                                                } }
                                            ></i>
                                        </th>
                                        <th scope="col" className="text-left min-w-180px">
                                            Date de cloture
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => { } }
                                            ></i>
                                        </th>
                                        <th scope="col" className="text-left min-w-180px">
                                            Note
                                            <i
                                                className="la la-filter cursor-pointer"
                                                onClick={ () => {
                                                    setShowFilter(true);
                                                    setFilt_col("note");
                                                    setTitle("Note");
                                                } }
                                            ></i>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody id="resultdata" style={ { zIndex: 1 } }>
                                    { currentPosts ? (
                                        currentPosts.map((d, index) => (
                                            <tr
                                                key={ index }
                                                className={
                                                    " " +
                                                    ((Moment().diff(Moment(d.explication_sendHr), "hours", true) > 24 && d.explication_recivedHr === null) || (Moment().diff(Moment(d.fiche_disc_sendHr), "hours", true) > 24 && d.fiche_disc_receved_Hr === null) ? "text-warning" : (Moment().diff(Moment(d.explication_recivedHr), "hours", true) > 24 && d.fiche_disc_sendHr === null) || (Moment().diff(Moment(d.fiche_disc_receved_Hr), "hours", true) > 24 && d.id_status !== 4) ? "text-danger  " : "")
                                                }
                                                style={ { zIndex: 1 } }
                                            >
                                                <td
                                                    id="tb-assigned-to"
                                                    className="text-left align-middle  "
                                                    style={ { left: 0, background: "#f8f9fa" } }
                                                >
                                                    { d.comp_id }
                                                </td>
                                                <td
                                                    id="tb-assigned-to"
                                                    className="text-left align-middle"
                                                    style={ {
                                                        left: 33,
                                                        background: "#f8f9fa",
                                                        zIndex: 1,
                                                    } }
                                                >
                                                    <div
                                                        className=""
                                                        onClick={ (e) => {
                                                            toogleDropdown(index, {
                                                                mousex: e.clientY,
                                                                mousey: e.clientX,
                                                            });
                                                        } }
                                                        style={ { zIndex: 1 } }
                                                    >
                                                        <span
                                                            className="hover_underlined cursor-pointer"
                                                            style={ { zIndex: 1 } }
                                                        >
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="secondary"
                                                                    id="dropdown-basic"
                                                                    style={ {
                                                                        border: "none",
                                                                        background: "#f8f9fa",
                                                                    } }
                                                                    className="bg-light rounded-0"
                                                                >
                                                                    <span>{ d.accused_name }</span>
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    {
                                                                        d.id_status === 5 ? (
                                                                            <Dropdown.Item
                                                                                href="#"
                                                                                onClick={ () => {
                                                                                    handleAplysanction(d);
                                                                                } }
                                                                                className="dropdown-item border-bottom border-top"
                                                                            >

                                                                                <span className="pl-3">
                                                                                    Appliquer une sanction
                                                                                </span>
                                                                            </Dropdown.Item>
                                                                        ) : d.id_status !== 1 && d.id_status !== 3 && d.id_status !== 4 && d.id_status !== 2 && perms['114'] ? (
                                                                            <Dropdown.Item href="#" onClick={ () => { handleupdatesanction(d); } } className="dropdown-item border-bottom border-top">

                                                                                <span className="pl-3">
                                                                                    Modifier la sanction
                                                                                </span>
                                                                            </Dropdown.Item>
                                                                        ) : null
                                                                    }

                                                                    { d.id_status !== 1 &&
                                                                        d.id_status !== 3 &&
                                                                        d.id_status !== 4 && perms['103'] ? (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ async () => {
                                                                                await handleAccepte(d.comp_id);
                                                                                await getallchooseNature(d.comp_id);
                                                                                await setcompl_id(d.comp_id);
                                                                                await setdescriptionHR(
                                                                                    d.comp_hr_description
                                                                                );
                                                                                await setnature(d.comp_nature_id);
                                                                                setbuttonAction("Modifier les natures");
                                                                            } }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >

                                                                            <span className="pl-3">
                                                                                Modifier les natures
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    ) : null }

                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        onClick={ () => handleViewModal(d) }
                                                                        className="dropdown-item border-bottom border-top"
                                                                    >

                                                                        <span className="pl-3">
                                                                            Voir la plainte
                                                                        </span>
                                                                    </Dropdown.Item>

                                                                    { perms['105'] === true ? (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ () => handleNoteModal(d) }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >

                                                                            <span className="pl-3">
                                                                                Ajouter une note
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    ) : null }

                                                                    { d.id_status !== 1 ||
                                                                        perms['114'] ===
                                                                        false ? null : (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ () => {
                                                                                setbuttonAction("Accepter la plainte");
                                                                                handleAccepte(d.comp_id);
                                                                                setcompl_id(d.comp_id);
                                                                                setdescriptionHR(d.comp_description);
                                                                                setnature(d.comp_nature_id);
                                                                                setaccusedPositionID(d.emp_position_id);
                                                                            } }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >
                                                                            <span className="pl-3">Accepter</span>
                                                                        </Dropdown.Item>
                                                                    ) }

                                                                    { d.id_status !== 1 || d.id_status === 3 ? (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ () => {
                                                                                setdocumentComp_id(d.comp_id);
                                                                                setdocumentSignedPath(d.docuument_path);
                                                                                setdocumentId_statusDownload(
                                                                                    d.id_status
                                                                                );
                                                                                setprint_name(d.accused_name);
                                                                                setprintmatric(d.accused_id);
                                                                                setprintfonction(d.po_name);
                                                                                setprint_nature(d.comp_hr_description);
                                                                                setaccusedPositionID(d.emp_position_id);
                                                                                setprintdate_infraction(
                                                                                    format(
                                                                                        new Date(d.comp_complaint_date),
                                                                                        "dd-MM-yyyy"
                                                                                    )
                                                                                );
                                                                                setShowModalexplication(true);
                                                                            } }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >

                                                                            <span className="pl-3">
                                                                                Demande d'explication
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    ) : null }
                                                                    { d.id_status === 6 || d.id_status === 7 ? (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ () => {
                                                                                setShowModalfichedisc(true);
                                                                                setstatus_comp(d.id_status);
                                                                                setdocumentComp_id(d.comp_id);
                                                                                setfichepath(d.fiche_path);
                                                                                handleViewModal1(d);
                                                                            } }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >

                                                                            <span className="pl-3">
                                                                                Fiche disciplinaire
                                                                            </span>
                                                                        </Dropdown.Item>
                                                                    ) : null }
                                                                    { d.id_status === 3 ||
                                                                        d.id_status === 4 || !perms['114'] ? null : (
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            onClick={ () =>
                                                                                change_statuss(3, d.comp_id)
                                                                            }
                                                                            className="dropdown-item border-bottom border-top"
                                                                        >

                                                                            <span className="pl-3">Annuler</span>
                                                                        </Dropdown.Item>
                                                                    ) }
                                                                    {
                                                                        d.id_status === 1 || d.id_status === 3 ? null : d.id_status === 7
                                                                            ? perms['104'] === true
                                                                                ? <Dropdown.Item
                                                                                    onClick={ () => {
                                                                                        setcompl_id(d.comp_id);
                                                                                        setshowclosemodale(true);
                                                                                    } }
                                                                                    className="dropdown-item border-bottom border-top bg-danger text-white"
                                                                                >
                                                                                    Fermer la plainte
                                                                                </Dropdown.Item>
                                                                                : null

                                                                            : null
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td
                                                    id="tb-assigned-to"
                                                    className="text-left align-middle "
                                                    style={ { left: 200, background: "#f8f9fa" } }
                                                >
                                                    { d.plaintiff_name }
                                                </td>
                                                { allNatureSanction.filter(
                                                    (x) => x.complaint_id === d.comp_id
                                                ).length > 0 ? (
                                                    <td
                                                        id="tb-created-date"
                                                        className="text-left align-middle"
                                                    >
                                                        { allNatureSanction
                                                            .filter((x) => x.complaint_id === d.comp_id)
                                                            .slice(0, 2)
                                                            .map((x, i) => {
                                                                return (
                                                                    <div
                                                                        key={ i }
                                                                        className="badge rounded-pill border mr-1 mb-1 align-items-center"
                                                                        style={ { backgroundColor: "#E6F8E5" } }
                                                                    >
                                                                        { truncateText(x.comp_nature_value) }
                                                                    </div>
                                                                );
                                                            }) }
                                                        { allNatureSanction.filter(
                                                            (x) => x.complaint_id === d.comp_id
                                                        ).length > 2 && (
                                                                <div
                                                                    onMouseEnter={ () => setShowMore(index) }
                                                                    onMouseLeave={ () => setShowMore(null) }
                                                                >
                                                                    <div className="badge rounded-pill bg-light border mr-1 mb-1 align-items-center cursor-pointer">
                                                                        +
                                                                        { allNatureSanction.filter(
                                                                            (x) => x.complaint_id === d.comp_id
                                                                        ).length - 2 }
                                                                    </div>
                                                                    { showMore === index && (
                                                                        <div className="floating-div border-light-info rounded">
                                                                            { allNatureSanction
                                                                                .filter(
                                                                                    (x) => x.complaint_id === d.comp_id
                                                                                )
                                                                                .slice(2)
                                                                                .map((x, i) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={ i }
                                                                                            className="badge rounded-pill border mr-1 mb-1 d-flex align-items-center"
                                                                                            style={ {
                                                                                                backgroundColor: "#E6F8E5",
                                                                                            } }
                                                                                        >
                                                                                            { truncateText(x.comp_nature_value) }
                                                                                        </div>
                                                                                    );
                                                                                }) }
                                                                        </div>
                                                                    ) }
                                                                </div>
                                                            ) }
                                                    </td>
                                                ) : (
                                                    <td className="text-left align-middle">
                                                        <div className="badge rounded-pill border mr-1 mb-1 align-items-center">
                                                            { d.compl_nature_manager }
                                                        </div>
                                                    </td>
                                                ) }
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                >
                                                    { d.comp_hr_description ? truncateText(d.comp_hr_description, 150) : truncateText(d.comp_description, 150) }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className={
                                                        d.id_status === 1
                                                            ? " py-1 px-2 text-success rounded align-middle"
                                                            : d.id_status === 2
                                                                ? "py-1 px-2 text-info rounded align-middle"
                                                                : d.id_status === 3
                                                                    ? " py-1 px-2 text-primary rounded align-middle"
                                                                    : d.id_status === 4
                                                                        ? " py-1 px-2 text-danger rounded align-middle"
                                                                        : d.id_status === 7
                                                                            ? " py-1 px-2 text-danger rounded align-middle"
                                                                            : " py-1 px-2 text-primary rounded align-middle"
                                                    }
                                                >
                                                    { d.status }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle "
                                                >
                                                    { d.sanction_value1 }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                >
                                                    { d.sanct_value }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                    onClick={ () => {
                                                        getDif(d.comp_complaint_date, d.closeDate);
                                                    } }
                                                >
                                                    { format(new Date(d.comp_fact_date), "dd-MM-yyyy") }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                >
                                                    { format(
                                                        new Date(d.comp_complaint_date),
                                                        "dd-MM-yyyy"
                                                    ) }
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                >
                                                    { d.closeDate
                                                        ? format(new Date(d.closeDate), "dd-MM-yyyy")
                                                        : null }
                                                </td>

                                                <td
                                                    id="tb-created-date"
                                                    className="text-left align-middle"
                                                >
                                                    { d.note }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={ 11 } className="text-center pt-20 fs-16">
                                                No data found
                                            </td>
                                        </tr>
                                    ) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer pt-2 pb-2 col-12 col-sm-12 col-lg-12 col-sm-12 col-xl-10">
                        <div className="d-flex justify-content-left align-items-center relative">
                            <select
                                className="border-1 position-absolute"
                                style={ {
                                    borderColor: "#a9acb0",
                                    outline: "none",
                                    height: "3rem",
                                    maxHeight: "4rem",
                                } }
                                value={ linePerPage }
                                onChange={ (e) => setlinePerPage(e.target.value) }
                            >
                                <option value={ 10 }>10</option>
                                <option value={ 20 }>20</option>
                                <option value={ 50 }>50</option>
                                <option value={ finalList.length }>All</option>
                            </select>
                            <Pagination
                                postsPerPage={ linePerPage }
                                totalPosts={ finalList.length }
                                paginate={ paginate }
                            />
                        </div>
                    </div>
                </div>

                <div className={ "d-none" }>
                    <div ref={ componentRef } className={ complaintForm }>
                        <div className="mt-20 d-print-none"></div>
                        {/* 123456 */ }
                        <div className="row">
                            <div id="printticketrep" className={ complaintForm }>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table id="" className="table" style={ { width: "1200px" } }>
                                            <tbody id="" className="border">
                                                <tr>
                                                    <td rowSpan={ 3 } colSpan={ 2 } className="border">
                                                        <div className="d-flex">
                                                            <div className="w-150px">
                                                                <img
                                                                    alt="Logo"
                                                                    src={ logo }
                                                                    className="logo-default max-h-80px"
                                                                />
                                                            </div>
                                                            <div className="text-center font-weight-bolder fs-24 pt-7 border-left-0 pl-10">
                                                                FICHE DISCIPLINAIRE
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center border fs-16">
                                                        Code : <span id="trep-code">Test</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center border fs-16">
                                                        Version : 001
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center border fs-16">
                                                        Date de mise en application: 13 Sept 2021
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border border-right-0"></td>
                                                    <td className="border border-left-0 fs-16 text-right">
                                                        Date de la plaint
                                                    </td>
                                                    <td
                                                        id="trep-date"
                                                        className="border text-center fs-16"
                                                    >
                                                        { p_date_plainte }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border border-right-0"></td>
                                                    <td className="border border-left-0 fs-16 text-right">
                                                        Date de la faute
                                                    </td>
                                                    <td
                                                        id="trep-date"
                                                        className="border text-center fs-16"
                                                    >
                                                        { p_date_plainte }
                                                    </td>
                                                </tr>
                                                <tr className="text-center bg-light border">
                                                    <td
                                                        colSpan={ 3 }
                                                        className="font-weight-bolder border fs-16"
                                                    >
                                                        Informations sur le plaignant
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left w-400px fs-16">
                                                        Nom du plaignant
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-user"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_nom_plaignant }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Departement
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-eqcode"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_department }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Branch
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-eqsite"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_branch }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        position
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-eqsite"
                                                        className="border text-left fs-16"
                                                    >
                                                        Manager - { p_department }
                                                    </td>
                                                </tr>
                                                <tr className="text-center bg-light border">
                                                    <td
                                                        colSpan={ 3 }
                                                        className="font-weight-bolder border fs-16"
                                                    >
                                                        Informations sur l'accusé
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Nom de l'accusé
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_nom_accused }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        ID de l'accusé
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_acussed_id }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Departement
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_department }
                                                    </td>
                                                </tr>

                                                <tr className="text-center bg-light border">
                                                    <td
                                                        colSpan={ 3 }
                                                        className="font-weight-bolder border fs-16"
                                                    >
                                                        Informations sur le plainte
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Nature
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { allNatureSanction
                                                            .filter((x) => x.complaint_id === p_id_plaint)
                                                            .map((x, index) => {
                                                                return (
                                                                    <div key={ index }>
                                                                        <span className="p-1 mr-1">
                                                                            { index + 1 }.
                                                                        </span>
                                                                        { x.comp_nature_value }
                                                                    </div>
                                                                );
                                                            }) }
                                                        {/* {p_category} */ }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Description
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_description }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Sanction
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { hrP_sanction }
                                                    </td>
                                                </tr>

                                                <tr className="text-center bg-light border">
                                                    <td
                                                        colSpan={ 3 }
                                                        className="font-weight-bolder border fs-16"
                                                    >
                                                        Informations sur la plainte et la décision du RH
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Nature
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_category }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Description
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { hrP_description }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Sanction
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_sanction }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Explication
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        { p_hrExplication }
                                                    </td>
                                                </tr>

                                                <tr className="border">
                                                    <td
                                                        rowSpan={ 2 }
                                                        className="border font-weight-bolder text-left fs-16"
                                                        style={ { verticalAlign: "middle" } }
                                                    >
                                                        Signature syndicat
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-user"
                                                        className="border text-left fs-16 font-weight-bolder font-italic"
                                                    >
                                                        Nom et Signature du syndicat
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={ 3 }
                                                        className="d-flex align-items-stretch font-weight-bolder text-left font-italic fs-16 h-70px"
                                                    ></td>
                                                </tr>
                                                <tr className="border">
                                                    <td
                                                        rowSpan={ 2 }
                                                        className="border font-weight-bolder text-left fs-16"
                                                        style={ { verticalAlign: "middle" } }
                                                    >
                                                        Signature accusé
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-user"
                                                        className="border text-left fs-16 font-weight-bolder font-italic "
                                                    >
                                                        Nom et Signature de l'accusé
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colSpan={ 3 }
                                                        className="border v-50px font-weight-bolder text-left font-italic fs-16 h-70px"
                                                    ></td>
                                                </tr>

                                                <tr>
                                                    <td className="border font-weight-bolder text-left fs-16">
                                                        Signature HR
                                                    </td>
                                                    <td
                                                        colSpan={ 2 }
                                                        id="trep-priority"
                                                        className="border text-left fs-16"
                                                    >
                                                        <div>
                                                            <img
                                                                alt="Signature"
                                                                src={ signature }
                                                                className="logo-default max-h-300px float-right mr-2 "
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={ "d-none" }>
                    <div ref={ componentRef1 } className={ complaintForm1 }>
                        <div className="mt-20 d-print-none"></div>
                        {/* 123456 */ }
                        <div className="row w-100">
                            <div id="printticketrep" className={ null }>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table
                                            id=""
                                            className="table mt-10"
                                            style={ { width: "1200px" } }
                                        >
                                            <tbody id="" className="border">
                                                <tr>
                                                    <td rowSpan={ 2 } colSpan={ 2 } className="border">
                                                        <div className="d-flex">
                                                            <div className="w-150px">
                                                                <img
                                                                    alt="Logo"
                                                                    src={ logo }
                                                                    className="logo-default max-h-80px"
                                                                />
                                                            </div>
                                                            <div className="text-center font-weight-bolder fs-24 pt-7 border-left-0 pl-10">
                                                                Fiche de demande d'explication
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center border fs-22">
                                                        Code : <span id="trep-code">FR19-S1</span> <br></br>
                                                        Version : <span id="trep-code ">002</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center border fs-22">
                                                        Date d'application <br></br>
                                                        21 Déc 2022
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="d-flex">
                                            <div className="col-lg-8"></div>
                                            <div className="col-lg-4">
                                                <p className="text-left mr-10 mt-30 fs-22">
                                                    Lubumbashi, le { actualdate }
                                                </p>
                                            </div>
                                        </div>

                                        <br></br>
                                        <br></br>
                                        <p className="text-left ml-20 fs-22">
                                            N/Réf. { documentComp_id }/G.A. L/G. K/J. K/
                                            { Moment().year() }
                                        </p>
                                        <br></br>
                                        <div className="d-flex">
                                            <div className="col-lg-8 d-flex fs-22">
                                                <h4 className="text-left ml-15 fs-22 font-weight-bolder">
                                                    Concerne :{ " " }
                                                </h4>{ " " }
                                                <span className="ml-3 fs-22">
                                                    { " " }
                                                    Demande d’explication
                                                </span>
                                            </div>
                                            <div className="col-lg-4">
                                                <p className="text-left fs-1 fs-22">
                                                    <span className="font-weight-bolder">A : </span>{ " " }
                                                    { print_name }
                                                </p>{ " " }
                                                <p className="text-left fs-22 fs-22">
                                                    <span className="font-weight-bolder">Mat : </span>{ " " }
                                                    { printmatric }
                                                </p>
                                                <p className="text-left fs-22 fs-22">
                                                    <span className="font-weight-bolder">Fonction :</span>{ " " }
                                                    { printfonction }
                                                </p>{ " " }
                                                <p className="text-left fs-22 fs-1 8">A Lubumbashi: </p>
                                            </div>
                                        </div>

                                        <p className="ml-30 fs-22 fs-22">Monsieur/Madame,</p>
                                        <br></br>
                                        <p className="ml-30 fs-22 fs-22">
                                            En date du { printdate_infraction } une plainte nous est
                                            parvenue vous reprochant { print_nature }
                                        </p>
                                        <p className="ml-30 fs-22 fs-22">
                                            Veuillez présenter vos explications aux ressources
                                            humaines endéans 48h.
                                        </p>
                                        <br></br>
                                        <p className="text-left ml-30 fs-22">Cordialement..</p>
                                        <p className="text-right mr-30 fs-22">La Direction</p>
                                        <div>
                                            <img
                                                alt="Signature"
                                                src={ signature }
                                                className="logo-default max-h-300px float-right mr-20 pb-10"
                                            />
                                        </div>

                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <div className="h-100 border border-dark mt-20 ml-20 mr-20 fs-22 ">
                                            <p className=" fs-22 font-weight-bolder d-block h-250px ml-5 mt-5">
                                                { " " }
                                                Vos explications :{ " " }
                                            </p>
                                        </div>
                                        <div className="d-flex">
                                            <div className="col-lg-8">
                                                <p className=" mt-1 text-left  ml-20 font-italic fs-22">
                                                    C.I. : Délégation syndicale
                                                </p>
                                            </div>

                                            <div className="col-lg-4">
                                                <p className="mt-1 text-right mr-20 font-weight-bold fs-22 font-weight-bolder">
                                                    Date et signature de l'agent
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Home;
