import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import DropDownSearch from "../../component/dropdownSearach";
import { useFetch } from "../../../../components/hooks/useFetch"
import HttpStatus from "../../utils/HttpStatus";
import { useEffect, useState } from "react";

const Container = ({ children, direction = 'column', padding = 'p-2' }) => {
    return <div className={`d-flex ${direction === 'row' ? 'flex-row align-items-end' : 'flex-column align-items-start justify-content-end'} ${padding} w-100 h-100`} style={{ flexGrow: 1 }}>
        {children}
    </div>
}

const initialQuota = {
    branch: {},
    department: {},
    position: {},
    quota: {
        value: 1
    }
}
export default function AddQuota({ setShowAdd, setrefresh, refresh, data }) {
    const { fetchData } = useFetch();
    const [department, setDepartment] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenPos, setIsOpenPos] = useState(false)
    const [isOpenDep, setIsOpenDep] = useState(false)
    const [isOpenunit, setIsOpenunit] = useState(false)
    const [branch, setBranch] = useState([])
    const [errorMess, seterrorMess] = useState("")
    const [position, setPostion] = useState([])
    const [depQuoa, setDepQuota] = useState(initialQuota)

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const reset = () => {
        setDepQuota(initialQuota)
    }

    const changeValue = (key, value) => {
        setDepQuota((prevDepQuota) => ({
            ...prevDepQuota,
            [key]: value,
        }))
    };


    const checkIfExist = async () => {
        return data.filter((xx) =>
            xx.id_branch === depQuoa?.branch?.value &&
            xx.dep_id === depQuoa?.department?.value &&
            xx.po_id === depQuoa?.position?.value
        )
    }

    const getAllData = async () => {
        const response = await fetchData("POST", "recrut/getAllOrganisation");
        if (response.status === HttpStatus.OK) {

            const renamedBranches = response.data.result.branch.map((branch) => {
                const { branch_id: value, branch_name: designation } = branch;
                return { value, designation };
            });

            const renamedDepartment = response.data.result.department.map((department) => {
                const { dep_id: value, dep_name: designation } = department;
                return { value, designation };
            });

            const renamedPosition = response.data.result.jobtitle.map((position) => {
                const { po_id: value, po_name: designation } = position;
                return { value, designation };
            });


            setBranch(renamedBranches)
            setDepartment(renamedDepartment)
            setPostion(renamedPosition)
        } else {
            // notifyError("Something went wrong");
        }
    }

    const addQuota = async () => {
        const a = await checkIfExist();
        if (a.length > 0)
            return seterrorMess('Un quota est deja enregistré avec ces information')
        if (!depQuoa.branch.value || !depQuoa.department.value || !depQuoa.position.value || !depQuoa.quota.value)
            return seterrorMess('Completer les informations obligatoires')

        const response = await fetchData("POST", "recrut/addQuota", {
            depQuota: { id_branch: depQuoa.branch.value, id_department: depQuoa.department.value, id_jobtitle: depQuoa.position.value, quota_value: depQuoa.quota.value }
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("Quota successfully added");
            setrefresh(!refresh)
            setShowAdd(false)
        } else {
            notifyError("Something went wrong");
        }
    }

    useEffect(() => {
        getAllData();
    }, [])
    useEffect(() => {
        reset();
    }, [refresh])

    return (
        <form className="d-flex justify-content-between align-content-center w-100" style={{ height: 100 }}>
            <Container>
                <label className="font-weight-bold text-left" align="left">
                    Branch
                    <span className="text-danger pl-3">*</span>
                </label>
                <DropDownSearch
                    data={branch}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    text={(depQuoa?.branch?.designation ? depQuoa.branch.designation : 'Choose branch')}
                    changeValue={changeValue}
                    keyType={'branch'}
                />
            </Container>
            <Container>
                <div className="" align="left">
                    <label className="font-weight-bold">
                        Depatment
                        <span className="text-danger pl-3">*</span>
                    </label>
                </div>
                <DropDownSearch
                    data={department}
                    isOpen={isOpenDep}
                    setIsOpen={setIsOpenDep}
                    text={depQuoa?.department?.designation ? depQuoa.department.designation : 'Choose department'}
                    changeValue={changeValue}
                    keyType={'department'}
                />
            </Container>
            <Container>
                <div className="" align="left">
                    <label className="font-weight-bold">
                        Job Title
                        <span className="text-danger pl-3">*</span>
                    </label>
                </div>
                <DropDownSearch
                    data={position}
                    isOpen={isOpenPos}
                    setIsOpen={setIsOpenPos}
                    text={depQuoa?.position?.designation ? depQuoa.position.designation : 'Choose Job title'}
                    changeValue={changeValue}
                    keyType={'position'}
                />
            </Container>
            <Container>
                <div className="" align="left">
                    <label className="font-weight-bold">
                        Unit
                        {/* <span className="text-danger pl-3">*</span> */}
                    </label>
                </div>
                <DropDownSearch
                    data={[]}
                    isOpen={isOpenunit}
                    setIsOpen={setIsOpenunit}
                    text={depQuoa?.unit?.designation ? depQuoa.unit.designation : 'Choose unit'}
                    changeValue={changeValue}
                    keyType={'unit'}
                />
            </Container>
            <Container>
                <div className="" align="left">
                    <label className="font-weight-bold">
                        Quota
                        <span className="text-danger pl-3">*</span>
                    </label>
                </div>
                <input type={"number"} min={1} value={depQuoa?.quota?.value} id="cstm-contact" className="form-control rounded-0 cursor-pointer" onChange={(e) => changeValue('quota', { value: e.target.value })} />
            </Container>
            <Container>
                <Container direction="column" padding="p-0">
                    <span className="text-danger px-2 font-italic w-100 text-left">{errorMess}</span>
                </Container>
                <Container direction={'row'} padding={'p-0'}>
                    <button type="button" className="btn btn-success mx-1" style={{ height: 35 }} onClick={addQuota}>Ajouter</button>
                    <button type="button" className="btn btn-danger mx-1" style={{ height: 35 }} onClick={() => setShowAdd(false)}>Annuler</button>
                </Container>
            </Container>
        </form>
    )
}