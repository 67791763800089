/* eslint-disable no-unused-expressions */
import MenuTestPage from "./header_mobile";
import Footer from "./footer";
import * as Axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import toast from "react-hot-toast";
import baseurl from "../globals";
import { BiRefresh } from "react-icons/bi";
import Dropdown from "react-bootstrap/Dropdown";
import { useFetch } from "./hooks/useFetch";
import HttpStatus from "./utils/HttpStatus";
import DataContext from "../context/DataProvider";
import { useNavigate } from "react-router-dom";

function Nature(props) {
  const { fetchData } = useFetch();
  const navigate = useNavigate();
  const { perms, getPerm } = useContext(DataContext)

  //Variable part
  const [nature, setnature] = useState("");
  const [natureID, setnatureID] = useState("");
  const [allnature, setallnature] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const linePerPage = 10;
  const [showAdd_complaint, setshowAdd_complaint] = useState(false);
  const [showDelete_complaint, setshowDelete_complaint] = useState(false);

  const [natureMessage, setnatureMessage] = useState("");
  const [actionType, setactionType] = useState(0);
  const [actionTitle, setactionTitle] = useState("Add a complaint");

  const [refresh, setrefresh] = useState(0);

  function refreshing() {
    setrefresh(refresh + 1);
  }

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const getData = () => { };
  const sortedPosts = allnature.sort((a, b) =>
    a.comp_nature_value.localeCompare(b.comp_nature_value)
  );
  const indexOfLastPost = currentPage * linePerPage;
  const indexOfFirstPost = indexOfLastPost - linePerPage;
  const currentPosts = sortedPosts.slice(indexOfFirstPost, indexOfLastPost);

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 6;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage === totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav className="d-flex">
          <ul className="pagination">
            <li className="page-item">
              {
                <span onClick={() => paginate(1)} className="p-1">
                  {"«"}
                </span>
              }
            </li>

            <li className="page-item">
              {currentPage === 1 ? (
                <span className="p-1" disabled>
                  {"<"}
                </span>
              ) : (
                <span onClick={() => paginate(currentPage - 1)} className="p-1">
                  {"<"}
                </span>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage === number ? (
                  <span onClick={() => paginate(number)} className="bg-red p-1">
                    {number}
                  </span>
                ) : (
                  <span onClick={() => paginate(number)} className="p-1">
                    {number}
                  </span>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage === totalPages ? (
                <span className="p-1" disabled>
                  {">"}
                </span>
              ) : (
                <span onClick={() => paginate(currentPage + 1)} className="p-1">
                  {">"}
                </span>
              )}
            </li>

            <li className="page-item">
              {
                <span onClick={() => paginate(totalPages)} className="p-1">
                  {"»"}
                </span>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  const tableRef = useRef(null);

  function handleTypeClose() {
    setshowAdd_complaint(false);
    setshowDelete_complaint(false);

    refreshing();
  }

  async function addNature() {
    if (nature === "") {
      setnatureMessage("Inserer une nature");
    }

    if (nature !== "") {
      const response = await fetchData("POST", "nature/add_nature", {
        nature: nature,
      });
      if (response.status === HttpStatus.OK) {
        setshowAdd_complaint(false);
      } else {
      }
    }
  }
  async function updateNature() {
    if (nature === "") {
      setnatureMessage("Inserer une nature");
    }

    if (nature !== "") {
      const response = await fetchData("POST", "nature/update_nature", {
        nature: nature,
        natureID: natureID,
      });
      if (response.status === HttpStatus.OK) {
        notifySuccess("La nature a été modifiée");
        handleTypeClose();
      } else {
        notifyError(response.data.err);
      }
    }
  }
  async function deleteNature() {
    if (nature !== "") {
      const response = await fetchData("POST", "nature/delete_nature", {
        nature: nature,
        natureID: natureID,
      });
      if (response.status === HttpStatus.OK) {
        notifySuccess("La nature a été modifiée");
        handleTypeClose();
      } else {
        notifyError(response.data.err);
      }
    }
  }
  function handleUpdate(d) {
    setnatureID(d.comp_nature_id);
    setnature(d.comp_nature_value);
    setactionType(1);
    setactionTitle("Modifier la nature");

    setshowAdd_complaint(true);
  }

  function handleDelete(d) {
    setnatureID(d.comp_nature_id);

    setshowDelete_complaint(true);
  }

  function handleAplysanction(d) { }
  function handleAdd() {
    setactionType(0);
    setnature("");
    setactionTitle("Nouvelle nature");

    setshowAdd_complaint(true);
  }

  // Change page
  const paginate = async (pageNumber) => setCurrentPage(pageNumber);

  const getAllNature = async () => {
    if (!perms['101']) return navigate('/home')
    const response = await fetchData("POST", "nature/get_nature");
    if (response.status === HttpStatus.OK) {
      setallnature(response.data.result.data);
    } else if (
      response.response.data.result.status ===
      HttpStatus.NON_AUTHORITATIVE_INFORMATION
    ) {
      //   setMessage("Check your ID and your password");
    }
  };

  useEffect(() => {
    const load = async () => {
      await getPerm()
      await refreshing()
    }
    load()
  }, []);

  useEffect(() => {
    if (refresh > 0)
      getAllNature();
  }, [refresh]);
  //Visual part
  return (
    <>
      <MenuTestPage />
      {/* Add task modal */}
      <Modal show={showAdd_complaint} onHide={handleTypeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{actionTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-4">
              <div className="" align="left">
                <label className="font-weight-bold">
                  Nature
                  <span className="text-danger pl-3">*</span>
                </label>
              </div>
              <input
                type={"text"}
                id="cstm-contact"
                onChange={(e) => {
                  setnature(e.target.value);
                }}
                value={nature}
                className="form-control rounded-0 cursor-pointer"
              />

              <div id="cstm-contact-error" className="text-danger pt-3">
                {natureMessage}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTypeClose}>
            Close
          </Button>

          {actionType === 0 ? (
            <Button variant="primary" onClick={addNature}>
              Ajouter une nature
            </Button>
          ) : (
            <Button variant="primary" onClick={updateNature}>
              Modifier la nature
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete_complaint} onHide={handleTypeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Supprimer la Nature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-4 mt-3">
              <label className="font-weight-bold">
                Voulez-vous supprimer cette nature ?
              </label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTypeClose}>
            Annuler
          </Button>
          <Button variant="primary" onClick={deleteNature}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-xl-12 center-block" align="center">
        <div
          className="col-xl-8 bg-white mt-50 center-block min-h-650px max-h-650px"
          align="center"
        >
          <div className="pt-10 w-100 h-80px" style={{ marginLeft: "0.8rem" }}>
            <h3 className="float-left pt-2 pr-3">List of complaint </h3>
            <span className="float-left">
              <BiRefresh
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  null;
                }}
                className="cursor-pointer display-4"
              />
            </span>
            <button
              onClick={handleAdd}
              id="crticket-btn"
              className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10"
              data-toggle="modal"
              data-target="#addticketModal"
            >
              Add Nature
            </button>
          </div>

          <div className="h-550px overflow-auto">
            <table id="customers" className="table " ref={tableRef}>
              <thead className="position-fixe">
                <tr>
                  <th scope="col" className="w-25px"></th>
                  <th scope="col" className="w-25px">
                    #
                  </th>

                  <th scope="col" className="text-left">
                    Nature
                    <i className="la la-filter"></i>
                  </th>

                  <th scope="col" className="text-left">
                    {/* <i className="la la-filter"></i> */}
                  </th>
                </tr>
              </thead>

              <tbody id="resultdata">
                {currentPosts ? (
                  currentPosts.map((d, index) => (
                    <tr
                      key={index}
                      id='td"+ d[i].m_id +"'
                      className={
                        "data display-block " +
                        (d.status === "Completed"
                          ? d.estimated_time < d.total
                            ? "text-danger"
                            : "text-info "
                          : "")
                      }
                    >
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="dropdown-basic"
                          className="w-60px py-0 text-white p-0 m-0"
                        >
                          <div
                            className="dropdown dropdown-inline bg-light2 py-0 rounded"
                            data-toggle="dropdown"
                          >
                            <i className="ki ki-bold-more-hor"></i>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="">
                          <Dropdown.Item
                            href="#"
                            onClick={() => handleUpdate(d)}
                            className="dropdown-item border-bottom border-top"
                          >
                            <i className="far fa-edit"></i>{" "}
                            <span className="pl-3">Modifier la nature</span>
                          </Dropdown.Item>
                          {d.sanct_value === null ? (
                            <Dropdown.Item
                              href="#"
                              onClick={() => handleAplysanction(d)}
                              className="dropdown-item border-bottom border-top"
                            >
                              <i className="far fa-edit"></i>{" "}
                              <span className="pl-3">Supprimer la nature</span>
                            </Dropdown.Item>
                          ) : null}
                          <Dropdown.Item
                            href="#"
                            onClick={() => handleDelete(d)}
                            className="dropdown-item border-bottom border-top"
                          >
                            <i className="far fa-edit"></i>{" "}
                            <span className="pl-3">Supprimer la nature</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <td id="tb-assigned-to" className="text-left">
                        {d.comp_id}
                      </td>

                      <td id="tb-created-date" className="text-left">
                        {d.comp_nature_value}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={11} className="text-center pt-20 fs-16">
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-footer pt-2 pb-2 col-8">
          <div className="d-flex justify-content-center">
            <Pagination
              postsPerPage={linePerPage}
              totalPosts={allnature.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Nature;
