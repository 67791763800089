
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/esm/Button";
import { Fiche } from "../component/File/Fiche";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

export const ViewFiche = ({ show, setShow, data, setData, jobDescData }) => {
    const componentRef = useRef()
    const [printContainer, setPrintContainer] = useState(false)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Fiche-Recruitment",
        onAfterPrint: () => { setPrintContainer(false); },
    });

    const handleTypeClose = () => {
        setShow(false)
    }

    return (
        <Modal show={show} onHide={handleTypeClose} centered size="xl" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    FICHE DE RECRUTEMENT
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ overflow: 'auto', maxHeight: '500px' }}>
                    <Fiche componentRef={componentRef} action={'view'} data={data} setData={setData} jobDescData={jobDescData} classnames={printContainer} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button ref={null} onClick={() => {
                    setPrintContainer(true); setTimeout(() => {
                        handlePrint()
                    }, 1000);
                }} variant="success">
                    Print
                </Button>
                <Button ref={null} onClick={handleTypeClose} variant="primary">
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}