import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const useFetch = () => {
    const accessToken = sessionStorage.getItem('accessToken')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    async function fetchData(type, destination, data = null, options = {}, auth = accessToken) {
        // Set the global headers
        axios.defaults.headers = {
            Authorization: `Bearer ${auth}`,
            Accept: 'application/pdf',
            responseType: 'arraybuffer',
        };
        setLoading(true);
        try {
            const response = await axios({
                method: type,
                url: `/api/${destination}`,
                data,
                options
            });
            setTimeout(() => setLoading(false), 1200);
            if (response.data.newAccessToken)
                localStorage.setItem('access', response.data.newAccessToken)

            if (response.status === 401) {
                navigate("/login")
            }

            return response;
        } catch (error) {
            setLoading(false);
            return error;
        }
    }

    return {
        fetchData,
        loading,
    };
};

export { useFetch };
