import logo from "../assets/img/auto-logo.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import Language from "./language";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Axios from "axios";
import { useContext, useEffect, useState } from "react";
import baseurl from "../globals";
import { MDBCheckbox } from "mdb-react-ui-kit";
import DataContext from "../context/DataProvider";

function Header() {
  const navigate = useNavigate();
  const { perms, getPerm } = useContext(DataContext)
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/Login");
  };

  const handleUser = () => {
    navigate("/users");
  };

  const handleNature = () => {
    navigate("/nature");
  };

  const handleSanction = () => {
    navigate("/sanction");
  };

  const handleReport = () => {
    navigate("/report");
  };
  const handleQuota = () => {
    navigate("/quota");
  };

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };

  function change_password1() {
    Axios.post(baseurl + "/?p=update_password", {
      password: newpassword,
      emp_id: sessionStorage.getItem("id"),
    }).then((response) => {
      if (response.data.affectedRows > 0) {
        notifySuccess("Mot de passe modifié");
      } else notifyError(response.data.err);
    });
  }

  const change_password = () => {
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        const url = baseurl + "/?p=loginuser";
        Axios.post(url, {
          username: sessionStorage.getItem("id"),
          password: oldpassword,
        }).then((response) => {
          if (response.data.result === true) {
            change_password1();
          } else {
            Messageconfirmpassword(response.data.errMessage);
          }
        });
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  };

  return (
    sessionStorage.getItem('accessToken')
      ? <div>
        <div
          id="kt_header"
          className={
            "header header-fixed bg-white text-dark d-print-none d-lg-flex d-none"
          }
        >
          <Modal
            show={ showChangePassword }
            onHide={ handleModelClosechangePassword }
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Change password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-group col-xl-12 ">
                  <label className="font-weight-bold">
                    Old password<span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    id="old-password"
                    type={ vuepassword ? "text" : "password" }
                    onChange={ (e) => {
                      setOldpassword(e.target.value);
                    } }
                    name="subject"
                    className="form-control rounded-0"
                  />
                  <div id="cstm-code-error" className="text-danger pt-2">
                    { Messageoldpassword }
                  </div>
                </div>

                <div className="form-group col-xl-12 ">
                  <label className="font-weight-bold">
                    New password<span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    id="new-password"
                    type={ vuepassword ? "text" : "password" }
                    onChange={ (e) => {
                      setNewpassword(e.target.value);
                    } }
                    name="subject"
                    className="form-control rounded-0"
                  />
                  <div id="cstm-code-error" className="text-danger pt-2">
                    { Messagenewpassword }
                  </div>
                </div>

                <div className="form-group col-xl-12 ">
                  <label className="font-weight-bold">
                    Confirm password<span className="text-danger pl-3">*</span>
                  </label>
                  <input
                    id="old-password"
                    type={ vuepassword ? "text" : "password" }
                    onChange={ (e) => {
                      setConfirmpassword(e.target.value);
                    } }
                    name="subject"
                    className="form-control rounded-0"
                  />
                  <div id="cstm-code-error" className="text-danger pt-2">
                    { Messageconfirmpassword }
                  </div>
                </div>
                <MDBCheckbox
                  className="ml-30"
                  name="flexCheck"
                  onChange={ check_verif }
                  value={ vuepassword }
                  id="shwpssw"
                  label="Show password"
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={ handleModelClosechangePassword }
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={ change_password }>
                Change password
              </Button>
            </Modal.Footer>
          </Modal>
          <Toaster position="top-right" reverseOrder={ false } />
          <div className="container  d-flex align-items-stretch justify-content-between py-3">
            <div className="d-flex align-items-stretch mr-3">
              <div className="header-logo">
                <a href={ "/home" }>
                  <img
                    alt="Logo"
                    src={ logo }
                    className="logo-default max-h-40px"
                  />
                </a>
              </div>

              <div
                className="header-menu-wrapper header-menu-wrapper-left"
                id="kt_header_menu_wrapper"
              >
                <div
                  id="kt_header_menu"
                  className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default "
                >
                  <ul className="menu-nav d-flex align-items-center fs-14">
                    <li
                      className="menu-item menu-item-rel mt-2"
                      style={ { height: "20px" } }
                    >
                      <a href="/home" className="menu-link">
                        <span className="text-dark-75 font-weight-bolder">
                          Discipline
                        </span>
                        <i className="menu-arrow pb-2"></i>
                      </a>
                    </li>
                    {
                      perms['112'] && <li className="menu-item menu-item-rel mt-2" style={ { height: "20px" } }>
                        <a href="/recrut" className="menu-link">
                          <span className="text-dark-75 font-weight-bolder">
                            Recruit
                          </span>
                          <i className="menu-arrow pb-2"></i>
                        </a>
                      </li>
                    }
                    {
                      perms['101'] && <li
                        className="menu-item menu-item-rel ml-5"
                        style={ { height: "20px" } }
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="dropdown-basic"
                            className="w-55px h-40px pt-0 pb-0"
                          >
                            <div className="btn btn-icon btn-dropdown btn-lg mr-1">
                              <div className="text-dark-75 font-weight-bolder">
                                Administrator
                              </div>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-250px">
                            <Dropdown.Item onClick={ () => handleUser() }>
                              <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                Users
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={ () => handleNature() }>
                              <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                Nature
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={ () => handleSanction() }>
                              <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                Sanction
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item onClick={ () => handleQuota() }>
                              <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                Quota
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={ () => handleReport() }>
                              <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                Report
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div className="topbar">
              <div className="dropdown mr-0 pt-0">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-55px h-40px text-white pt-0 pb-0"
                  >
                    <div
                      className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                      id="open-notification"
                    >
                      <i className="far fa-bell text-dark-75 mr-2"></i>
                      <span className="label label-rounded label-danger">
                        <div id="unreadnot">0</div>
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-250px">
                    <Dropdown.Item href="#">
                      <form className="p-0">
                        <div className="d-flex align-items-center pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                          <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                            Notifications
                          </div>
                        </div>

                        <div className="scroll scroll-push text-center h-100px">
                          <div id="notificationcontent" className="pt-11"></div>
                        </div>
                      </form>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="display-none">
                <Language />
              </div>
              <div className="dropdown mr-0 pt-0">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-55px h-40px pt-0 pb-0"
                  >
                    <div className="btn btn-icon btn-dropdown btn-lg mr-1">
                      <div className="text-dark opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
                        Welcome,
                        <span className="text-black text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                          { "DA" }
                        </span>
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-250px">
                    <Dropdown.Item onClick={ () => setShowChangePassword(true) }>
                      <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                        Change password
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={ () => {
                        navigate("/settings");
                      } }
                    >
                      <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                        Setting
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={ () => handleLogout() }>
                      <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                        Logout
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      : navigate('/Login')
  )
}

export default Header;
