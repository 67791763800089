import moment from "moment";
import { useState } from "react";
import { PencilFill, Send } from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { FcCancel } from "react-icons/fc";

export default function Note({ view, data, listNoteType, noteType, setNoteType, note, setNote, sanctStatus, setSancStatus, loader, add, update, color, updateNote, setUpdateNote }) {
    const [errNote, setErrNote] = useState("")
    const [errType, setErrType] = useState("")
    const [errStat, setErrStat] = useState("")


    const handleAction = () => {
        let error = false
        let text = 'Required fields'


        if (noteType === '') {
            setErrType(text)
            error = true
        } else { setErrType('') }

        if (sanctStatus === '') {
            setErrStat(text)
            error = true
        } else { setErrStat('') }

        if (note === '') {
            setErrNote(text)
            error = true
        } else {
            setErrNote('')
        }

        if (!error) {
            if (!updateNote) {
                add()
            } else {
                update(data.id)
            }
        }
    }

    return (
        <div style={{ marginTop: '10px', marginRight: '5px', display: 'flex', flexDirection: 'column', maxHeight: '330px', minHeight: '330px', minWidth: '280px', maxWidth: '280px', backgroundColor: color, borderRadius: '20px', padding: '20px' }}>
            <div style={{ flexGrow: 1, width: '100%' }}>
                <label style={{ fontWeight: 'bold', fontSize: 16, width: '100%' }}>
                    {
                        view && updateNote !== data.id
                            ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{data.name} </span>
                                <span style={{ fontSize: 13 }}>{data.note_for} </span>
                                <span style={{ fontWeight: 'normal', fontSize: 11 }}>{`(par : ${data.e_name})`}</span>
                            </div>
                            :
                            <div style={{ display: "flex", flexDirection: 'column', gap: '5px' }}>
                                <Dropdown style={{ width: '100%' }}>
                                    <Dropdown.Toggle variant="success">
                                        {noteType ? listNoteType.filter(x => x.id === noteType)[0]?.name : 'Type de note'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {listNoteType?.map((e, index) => {
                                            return (
                                                <Dropdown.Item key={index} onClick={() => { setNoteType(e.id) }}>{e.name}</Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <span style={{ fontWeight: 'normal', color: 'red', fontSize: '10px' }}>{errType}</span>

                                <Dropdown style={{ width: '100%' }}>
                                    <Dropdown.Toggle variant="info">
                                        {sanctStatus ? sanctStatus : 'Status'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item key={'index'} onClick={() => { setSancStatus('Apply Sanction') }}>Apply Sanction</Dropdown.Item>
                                        <Dropdown.Item key={'index1'} onClick={() => { setSancStatus('Sign Complaint') }}>Sign Complaint</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <span style={{ fontWeight: 'normal', color: 'red', fontSize: '10px' }}>{errStat}</span>
                            </div>
                    }
                </label>
                <div style={{}} className={`w-100 ${view && updateNote !== data.id ? 'h-100px' : ''} overflow-auto`}>
                    {!view || data.id === updateNote ?
                        <textarea rows={5} value={note} onChange={(e) => { setNote(e.target.value) }} style={{ background: color, width: '100%' }} placeholder="Entrer la note ici ..." />
                        :
                        data.note
                    }
                </div>
                <span style={{ fontWeight: 'normal', color: 'red' }}>{errNote}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ flexGrow: 1, color: 'white' }}>
                    {!view ? moment().format('lll') : moment(data.created_at).format('lll')}
                </div>

                {view && updateNote === data.id &&
                    <div
                        style={{ backgroundColor: '#E8D0CA', marginRight: '5px', borderRadius: '10px', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                        onClick={() => { setUpdateNote(null); setNote(''); setNoteType(''); setSancStatus('') }}
                    >
                        <FcCancel />
                    </div>
                }

                <div
                    style={{ backgroundColor: `${data?.id === updateNote ? 'green' : '#515151'}`, borderRadius: '10px', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                    onClick={() => {
                        if (!view) {
                            handleAction()
                        } else {
                            if (updateNote !== data.id) {
                                setUpdateNote(data.id)
                                setSancStatus(data.note_for)
                                setNote(data.note)
                                setNoteType(data.note_type)
                            } else {
                                handleAction()
                            }
                        }
                    }}
                >
                    {view ?
                        updateNote === data.id ?
                            loader === data.id ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                : <Send style={{ color: 'white' }} />
                            :
                            <PencilFill style={{ color: 'white' }} />
                        :
                        loader === -1 ?
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            : <Send style={{ color: 'white' }} />
                    }
                </div>
            </div>
        </div>
    )
}