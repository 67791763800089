import axios from "axios";
import { BiSend } from "react-icons/bi";
import baseurl from "../../../../../globals";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch";
import HttpStatus from "../../../../utils/HttpStatus";
import { jwtDecode } from "jwt-decode";

export const Header = ({
  data,
  setDataError,
  jobDescData,
  setJobDescData,
  action,
}) => {
  const { fetchData } = useFetch();
  const navigate = useNavigate();

  const sendData = async () => {
    let err = false;
    let tp,
      br,
      urg,
      dept,
      ad,
      pex,
      qor,
      qec,
      rr = false;
    let pnt,
      cor,
      qar = false;

    if (data.AskPoste === "") {
      tp = true;
      err = true;
    } else {
      tp = false;
    }
    if (data.AskBranch === "") {
      br = true;
      err = true;
    } else {
      br = false;
    }
    if (data.AskUrgence === 0) {
      urg = true;
      err = true;
    } else {
      urg = false;
    }
    if (data.AskDept === "") {
      dept = true;
      err = true;
    } else {
      dept = false;
    }
    if (data.AskNbre === 0) {
      ad = true;
      err = true;
    } else {
      ad = false;
    }
    if (data.AskExistingPoste === null) {
      pex = true;
      err = true;
    } else {
      pex = false;
    }
    if (data.AskOrgQuota === null) {
      qor = true;
      err = true;
    } else {
      qor = false;
    }
    if (data.AskQuotaEcart === 0 && data.AskOrgQuota === 0) {
      qec = true;
      err = true;
    } else {
      qec = false;
    }
    if (data.AskRaisonRecrut === "" && data.AskOrgQuota === 0) {
      rr = true;
      err = true;
    } else {
      rr = false;
    }
    if (data.AskExistingPoste === 0) {
      if (data.NewPostTask.length <= 0) {
        pnt = true;
        err = true;
      } else {
        pnt = false;
      }

      if (data.NewPostCon.length <= 0) {
        cor = true;
        err = true;
      } else {
        cor = false;
      }

      if (data.NewPostQual.length <= 0) {
        qar = true;
        err = true;
      } else {
        qar = false;
      }
    }

    setDataError((current) => {
      return {
        ...current,
        AskPoste: tp,
        AskBranch: br,
        AskDept: dept,
        AskUrgence: urg,
        AskNbre: ad,
        AskExistingPoste: pex,
        AskOrgQuota: qor,
        AskQuotaEcart: qec,
        AskRaisonRecrut: rr,
        AskNewTaches: pnt,
        AskNewCon: cor,
        AskNewQual: qar,
      };
    });

    if (!err) {

      const response = await fetchData("POST", "recrut/addRecrut", { data });

      if (response.status === HttpStatus.OK) {
        navigate("/recrut");
      }
    }
  };

  const addHr = async () => {
    const response = await fetchData("POST", "recrut/addHr", {
      data,
      jobDescData,
    });
    if (response.status === HttpStatus.OK) {
      navigate("/recrut");
    }
    // axios.post(baseurl + "?p=addHr", { data, jobDescData }).then((response) => {
    //   if (response.data.status) {
    //     navigate("/recrut");
    //   } else {
    //   }
    // });
  };

  return (
    <div
      className="pt-10 h-80px"
      // style={{ marginLeft: "0.8rem", display: 'flex', flexDirection: 'row' }}
      style={{ width: "90%", marginLeft: "5%" }}
    >
      {/* <div className="col-10 col-sm-10 col-md-10 col-lg-10 "></div> */}
      <button
        onClick={() => {
          action === "Hr" ? addHr() : sendData();
        }}
        className="btn btn-secondary text-white bg-secondary font-weight-bold float-right text-success"
      >
        Send <BiSend size={25} className="text-success" />
      </button>
    </div>
  );
};
