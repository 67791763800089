import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const MyPieChart = ({ dataset, title, onclick }) => {
    // État local pour stocker les données du graphique
    const [loader, setLoader] = useState(true)
    const [chartData, setChartData] = useState({
        labels: [""],
        datasets: [
            {
                data: [0],
                backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                label: " ",
            },
        ],
    });

    const options = {
        title: {
            display: true,
            text: '',
        },
        legend: {
            display: false, // Désactive l'affichage de la légende
        },
        plugins: {
            legend: {
                display: false, // Désactive l'affichage de la légende
            },
        },
    };

    useEffect(() => {
        if (dataset && dataset.length > 0) {
            const backgroundColors = Array.from({ length: dataset.length }, (_, index) => {
                return getRandomColor();
            });

            setChartData({
                labels: ['Late', 'On time', 'With Reason'],
                datasets: [
                    {
                        data: dataset,
                        backgroundColor: backgroundColors,
                        label: "complaint ",
                    },
                ],
            });
            setLoader(false)
            // }
        }
    }, [dataset]);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <div style={{ backgroundColor: '#EFF0F0', padding: '15px 25px', borderRadius: '5px' }} className='cursor-pointer' onClick={onclick}>
            <h2>{title}</h2>
            {!loader ?
                <Pie
                    data={chartData}
                    options={options}
                />
                :
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>

            }
        </div>
    );
};

export default MyPieChart;