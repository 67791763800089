import { useContext, useEffect, useState } from "react";
import MenuTestPage from "./header_mobile";
import toast from "react-hot-toast";
import MyPieChart from "./PieChart";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { useFetch } from "./hooks/useFetch";
import Button from "react-bootstrap/Button";
import { jwtDecode } from "jwt-decode";
import HttpStatus from "./utils/HttpStatus";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataProvider";

export default function Report() {
  const { fetchData } = useFetch();

  const { perms, getPerm } = useContext(DataContext)
  const navigate = useNavigate();
  const [allcomplaint, setallcomplaint] = useState([]);
  const [allSettings, setAllSettings] = useState([]);
  const [list, setList] = useState({});
  const [show, setShow] = useState(false);
  const [colStart, setColStart] = useState("");
  const [colEnd, setColEnd] = useState("");

  const [permissions, setPermissions] = useState({
    permission: {
      perm100: false,
      perm101: false,
      perm102: false,
      perm103: false,
      perm104: false,
      perm105: false,
      perm106: false,
    },
  });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const loadPermissionData = async () => {
    const a = jwtDecode(sessionStorage.getItem("accessToken"));
    const response = await fetchData("POST", "user/getall_empl_permisssions", {
      emp_id: a.id,
    });
    if (response.status === HttpStatus.OK) {
      // setPermissiondata(response.data);

      var prevPerm = permissions;

      var json = response.data.result;

      Object.keys(json).forEach(function (key) {
        if (json[key].user_perm_perm_code === 100) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm100 = true;
            setPermissions(prevPerm);
          }
        }

        if (json[key].user_perm_perm_code === 101) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm101 = true;
            setPermissions(prevPerm);
          }
        }
        if (json[key].user_perm_perm_code === 102) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm102 = true;
            setPermissions(prevPerm);
          }
        }
        if (json[key].user_perm_perm_code === 103) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm103 = true;
            setPermissions(prevPerm);
          }
        }
        if (json[key].user_perm_perm_code === 104) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm104 = true;
            setPermissions(prevPerm);
          }
        }
        if (json[key].user_perm_perm_code === 105) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm105 = true;
            setPermissions(prevPerm);
          }
        }
        if (json[key].user_perm_perm_code === 106) {
          if (json[key].user_perm_value === 1) {
            prevPerm.permission.perm106 = true;
            setPermissions(prevPerm);
          }
        }
      });
    }
  };

  function getValSetting(id) {
    return parseInt(allSettings.filter((x) => x.id === id)[0]?.value, 10);
  }

  const loadDoc = async () => {
    let { allPlaint, allStatus } = [];
    await loadPermissionData();

    const response = await fetchData("POST", "complaint/getStatus");
    if (response.status === HttpStatus.OK) {
      allStatus = response.data.result.rows;
    }

    setList({
      ...list,
      listStatus: allStatus,
      listSanctions: allPlaint,
    });

    const all_complaints = await fetchData(
      "POST",
      "complaint/get_all_complaints"
    );
    if (all_complaints.status === HttpStatus.OK) {
      setallcomplaint(all_complaints.data.result.data);
    }

    const response1 = await fetchData("POST", "complaint/get_all_settings");
    if (response.status === HttpStatus.OK) {
      setAllSettings(response.data.result.rows);
    }
  };

  const calculateHourDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = Math.abs(end - start) / 36e5;
    return diff;
  };

  function generateChartData(
    columnStartDate,
    columnEndDate,
    idParams,
    type = null
  ) {
    const listId = [];
    let countGreaterThanZero = 0;
    let countLessThanOrEqualZero = 0;
    let countWithReason = 0;

    const filteredData = allcomplaint.filter((data) => {
      const complaintDate = new Date(data.comp_complaint_date);
      return (
        complaintDate >= new Date(fromDate) &&
        complaintDate <= new Date(toDate) &&
        data.id_status !== 3
      );
    });

    filteredData.forEach((data) => {
      const hourDifference = calculateHourDifference(
        data[columnStartDate],
        data[columnEndDate]
      );
      if (hourDifference >= getValSetting(idParams)) {
        if (
          (columnStartDate === "explication_recivedHr" &&
            data.Reason_Apply_Sanction > 0) ||
          (columnStartDate === "fiche_disc_sendHr" &&
            data.Reason_Sign_Complaint > 0)
        ) {
          countWithReason++;
          if (type === 3) listId.push(data);
        } else {
          countGreaterThanZero++;
          if (type === 2) {
            listId.push(data);
          }
        }
      } else {
        countLessThanOrEqualZero++;
        if (type === 1) {
          listId.push(data);
        }
      }
    });

    if (type) {
      return listId;
    }
    return [countGreaterThanZero, countLessThanOrEqualZero, countWithReason];
  }

  const [fromDate, setFromDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [listModal, setListModal] = useState([]);

  function truncateText(text, maxLength = 35) {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  function handleLog(id) {
    setShow(id);
    let onTimeList = [];
    let lateList = [];
    let reasonList = [];

    if (id === 1) {
      setColStart("comp_complaint_date");
      setColEnd("explication_sendHr");

      onTimeList = generateChartData(
        "comp_complaint_date",
        "explication_sendHr",
        id,
        1
      );
      lateList = generateChartData(
        "comp_complaint_date",
        "explication_sendHr",
        id,
        2
      );
      reasonList = generateChartData(
        "comp_complaint_date",
        "explication_sendHr",
        id,
        3
      );
    } else if (id === 2) {
      setColStart("explication_sendHr");
      setColEnd("explication_recivedHr");

      onTimeList = generateChartData(
        "explication_sendHr",
        "explication_recivedHr",
        id,
        1
      );
      lateList = generateChartData(
        "explication_sendHr",
        "explication_recivedHr",
        id,
        2
      );
      reasonList = generateChartData(
        "explication_sendHr",
        "explication_recivedHr",
        id,
        3
      );
    } else if (id === 3) {
      setColStart("explication_recivedHr");
      setColEnd("fiche_disc_sendHr");

      onTimeList = generateChartData(
        "explication_recivedHr",
        "fiche_disc_sendHr",
        id,
        1
      );
      lateList = generateChartData(
        "explication_recivedHr",
        "fiche_disc_sendHr",
        id,
        2
      );
      reasonList = generateChartData(
        "explication_recivedHr",
        "fiche_disc_sendHr",
        id,
        3
      );
    } else {
      setColStart("fiche_disc_sendHr");
      setColEnd("fiche_disc_receved_Hr");

      onTimeList = generateChartData(
        "fiche_disc_sendHr",
        "fiche_disc_receved_Hr",
        id,
        1
      );
      lateList = generateChartData(
        "fiche_disc_sendHr",
        "fiche_disc_receved_Hr",
        id,
        2
      );
      reasonList = generateChartData(
        "fiche_disc_sendHr",
        "fiche_disc_receved_Hr",
        id,
        3
      );
    }

    setListModal({
      onTimeList,
      lateList,
      reasonList,
    });
  }

  const GetTable = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 21,
              padding: "10px",
              paddingBottom: "0px",
            }}
          >
            <label>
              On Time List{" "}
              <span style={{ fontSize: 10 }}>
                ({listModal.onTimeList.length})
              </span>
            </label>
          </div>
          <hr />
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Accusé</th>
                <th scope="col">Plaignant</th>
                <th scope="col">Description</th>
                <th scope="col">Difference</th>
              </tr>
            </thead>
            <tbody>
              {listModal.onTimeList.map((data, i) => (
                <tr key={i}>
                  <th scope="row">{data.comp_id}</th>
                  <td>{data.accused_name}</td>
                  <td>{data.plaintiff_name}</td>
                  <td>{truncateText(data.comp_description, 150)}</td>
                  <td>
                    {Math.round(
                      calculateHourDifference(data[colStart], data[colEnd])
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 21,
              padding: "10px",
              paddingBottom: "0px",
            }}
          >
            <label>
              Late List{" "}
              <span style={{ fontSize: 10 }}>
                ({listModal.lateList.length})
              </span>
            </label>
          </div>
          <hr />
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Accusé</th>
                <th scope="col">Plaignant</th>
                <th scope="col">Description</th>
                <th scope="col">Difference</th>
              </tr>
            </thead>
            <tbody>
              {listModal.lateList.map((data, i) => (
                <tr key={i}>
                  <th scope="row">{data.comp_id}</th>
                  <td>{data.accused_name}</td>
                  <td>{data.plaintiff_name}</td>
                  <td style={{ maxWidth: "100px" }}>
                    {truncateText(data.comp_description, 150)}
                  </td>
                  <td>
                    {Math.round(
                      calculateHourDifference(data[colStart], data[colEnd])
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {(show === 3 || show === 4) && (
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 21,
                padding: "10px",
                paddingBottom: "0px",
              }}
            >
              <label>
                On Late List With Reason{" "}
                <span style={{ fontSize: 10 }}>
                  ({listModal.reasonList.length})
                </span>
              </label>
            </div>
            <hr />
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Accusé</th>
                  <th scope="col">Plaignant</th>
                  <th scope="col">Description</th>
                  <th scope="col">Difference</th>
                </tr>
              </thead>
              <tbody>
                {listModal.reasonList.map((data, i) => (
                  <tr key={i}>
                    <th scope="row">{data.comp_id}</th>
                    <td>{data.accused_name}</td>
                    <td>{data.plaintiff_name}</td>
                    <td style={{ maxWidth: "100px" }}>
                      {truncateText(data.comp_description, 150)}
                    </td>
                    <td>
                      {Math.round(
                        calculateHourDifference(data[colStart], data[colEnd])
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const load = async () => {
      await getPerm()
    }
    load()
  }, []);

  useEffect(() => {
    loadDoc();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <MenuTestPage />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Report List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ overflow: "auto", maxHeight: "400px" }}>
            <GetTable />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button ref={null} onClick={() => setShow(false)} variant="default">
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="col-xl-12 center-block"
        align="center"
        style={{ zIndex: 1 }}
      >
        <div
          className="col-xl-10 bg-white mt-50 center-block"
          align="center"
          style={{ zIndex: 1 }}
        >
          <div
            className="pt-10 h-80px"
            style={{
              marginLeft: "0.8rem",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="col-1 col-sm-2 col-md-7 col-lg-8 "></div>
            <input
              type="date"
              name="username"
              id="username"
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              value={fromDate}
              className="form-control form-control-solid rounded-0 border py-3 px-8 fs-14-5 float-right mr-2"
              required
              autoComplete="off"
            />
            <input
              type="date"
              name="username"
              id="username"
              onChange={(e) => {
                setToDate(e.target.value);
              }}
              value={toDate}
              className="form-control form-control-solid rounded-0 border py-3 px-8 fs-14-5 float-right mr-2"
              required
              autoComplete="off"
            />
          </div>
          {/* <span style={{ fontStyle: "italic" }}>Time is in Hour</span> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              paddingBottom: "5rem",
            }}
            className=" bg-white"
          >
            {/* <MyPieChart dataset={generateChartData('comp_complaint_date', 'comp_fact_date')} title={'Infraction/plainte'} from={fromDate} to={toDate} /> */}
            <MyPieChart
              dataset={generateChartData(
                "comp_complaint_date",
                "explication_sendHr",
                1
              )}
              title={"Accept Complaint"}
              from={fromDate}
              to={toDate}
              onclick={() => handleLog(1)}
            />
            <MyPieChart
              dataset={generateChartData(
                "explication_sendHr",
                "explication_recivedHr",
                2
              )}
              title={"Demande d'explication"}
              from={fromDate}
              to={toDate}
              onclick={() => handleLog(2)}
            />
            <MyPieChart
              dataset={generateChartData(
                "explication_recivedHr",
                "fiche_disc_sendHr",
                3
              )}
              title={"Apply Sanction"}
              from={fromDate}
              to={toDate}
              onclick={() => handleLog(3)}
            />
            <MyPieChart
              dataset={generateChartData(
                "fiche_disc_sendHr",
                "fiche_disc_receved_Hr",
                4
              )}
              title={"Sign Complaint"}
              from={fromDate}
              to={toDate}
              onclick={() => handleLog(4)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
