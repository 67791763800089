import { FcAddRow } from "react-icons/fc"
import { useNavigate } from "react-router-dom";

export const Header = ({ componentRef, perms }) => {
    const navigate = useNavigate();

    return (
        <div
            ref={componentRef}
            className="pt-10 h-80px"
            style={{ marginLeft: "0.8rem", display: 'flex', flexDirection: 'row' }}
        >
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 ">
                <h3 className="float-left pt-2 pr-3" onClick={() => { }}>
                    Liste de recrutement
                </h3>
            </div>
            <div className="col-8 col-sm-8 col-md-8 col-lg-8 "></div>
            <div className="col-1 col-sm-1 col-md-1 col-lg-1 ">
                {perms['107'] &&
                    <button
                        onClick={() => {
                            navigate('add_recrut', { state: { action: 'add' } })
                        }}
                        className="btn btn-secondary text-white bg-secondary font-weight-bold float-right mr-2"
                    >
                        <FcAddRow size={25} />
                    </button>
                }
            </div>
        </div>
    )
}