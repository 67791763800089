import Modal from "react-bootstrap/Modal";
import { useFetch } from "./hooks/useFetch";
import { useEffect, useState } from "react";
import { FcNext } from "react-icons/fc";
import Button from "react-bootstrap/Button";
import HttpStatus from "./utils/HttpStatus";
import notifySuccess from "./utils/ToastNotification";

const Loading = () => {
    return (
        <Modal.Body>
            <div className="d-flex w-full justify-content-center align-items-center" style={{ minHeight: '400px' }}>
                <span className="loader"></span>
            </div>
        </Modal.Body>
    )
}

const UserPermission = ({ show, onHide, employeeInfo, handleTypeClose }) => {
    const { loading, fetchData } = useFetch()
    const [actualPage, setActualPage] = useState("COMPLAINT");
    const [allPerm, setAllPerm] = useState([])

    async function update_permissions() {
        const response = await fetchData("POST", "user/updatepermission", {
            allPerm,
            emp_id: employeeInfo.e_id,
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess("Les permissions ont étées mise à jour");
            handleTypeClose();
        } else {

        }

    }

    const getPermissions = async () => {
        const response = await fetchData("POST", "user/getall_permisssions", { id: employeeInfo?.e_id, });
        setAllPerm(response.data.listPerm)
    }

    const modifyListPermission = (id, value) => {
        setAllPerm(
            allPerm.map((x) => {
                if (x.user_perm_perm_code === id)
                    return { ...x, user_perm_value: value ? 1 : 0 };
                return x;
            })
        );
    };

    useEffect(() => {
        getPermissions()
    }, [employeeInfo])

    return (
        <Modal show={show} size="xl" onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>User permissions</Modal.Title>
            </Modal.Header>
            {
                !loading ?
                    <Modal.Body>
                        <form>
                            <div className="form-group mb-4 mt-3">
                                <span className="font-weight-bolder p-3 text-white bg-primary mb-4">
                                    <span className="font-weight-bolder fs-17"> Employée : </span> {employeeInfo?.e_name}
                                </span>

                                <div className="row">
                                    <div className="col-lg-5 border-right">
                                        <div className="border-bottom fs-14 font-weight-bolder mb-3 pb-2 d-flex text-center">
                                            <div className="pt-2 fs-20 text-center">ACCESS TO PAGE</div>
                                        </div>

                                        <div
                                            className={
                                                actualPage === "COMPLAINT"
                                                    ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1"
                                                    : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"
                                            }
                                            onClick={() => {
                                                setActualPage("COMPLAINT");
                                            }}
                                        >
                                            <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer">
                                                COMPLAINT{" "}
                                            </label>

                                            <FcNext
                                                size={20}
                                                className="col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer"
                                            />
                                        </div>
                                        <div
                                            className={
                                                actualPage === "RECRUT"
                                                    ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1"
                                                    : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"
                                            }
                                            onClick={() => {
                                                setActualPage("RECRUT");
                                            }}
                                        >
                                            <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer">
                                                RECRUT{" "}
                                            </label>

                                            <FcNext
                                                size={20}
                                                className="col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer"
                                                onClick={() => { }}
                                            />
                                        </div>
                                        <div
                                            className={
                                                actualPage === "ADMINISTRATOR"
                                                    ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1"
                                                    : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"
                                            }
                                            onClick={() => {
                                                setActualPage("ADMINISTRATOR");
                                            }}
                                        >
                                            <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer">
                                                ADMINISTRATOR{" "}
                                            </label>

                                            <FcNext
                                                size={20}
                                                className="col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer"
                                                onClick={() => { }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-7">
                                        <div className="border-bottom fs-14 font-weight-bolder pb-4 text-center mb-3 ">
                                            PERMISSIONS FOR PAGE {actualPage}
                                        </div>
                                        <div
                                            style={{
                                                height: "300px",
                                                overflow: "auto",
                                            }}
                                        >
                                            {allPerm.map((d, index) => {
                                                if (
                                                    d.perm_name
                                                        ?.toLowerCase()
                                                        .includes(actualPage?.toLocaleLowerCase())
                                                ) {
                                                    return (
                                                        <div
                                                            className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2"
                                                            key={index}
                                                        >
                                                            <label
                                                                className="font-weight-bolder col-lg-9 col-md-9 col-sm-9 col-9"
                                                                htmlFor={d.user_perm_perm_code}
                                                            >
                                                                {d.perm_name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                id={d.user_perm_perm_code}
                                                                className="col-lg-1 col-md-1 col-sm-1 col-1"
                                                                checked={d.user_perm_value === 1 ? true : false}
                                                                onChange={(e) => {
                                                                    modifyListPermission(
                                                                        d.user_perm_perm_code,
                                                                        e.target.checked
                                                                    );
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div id="ticketprio-error" className="text-danger pt-2"></div>
                            </div>
                        </form>
                    </Modal.Body>
                    : <Loading />
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={handleTypeClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={update_permissions}>
                    Update permissions
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserPermission