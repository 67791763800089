import MenuTestPage from "../header_mobile";
import { useContext, useEffect, useState } from "react";
import { Header } from "./component/Header";
import { FcApproval, FcCancel, FcList } from "react-icons/fc";
import { toast } from "react-hot-toast";
import { PenFill } from "react-bootstrap-icons";
import { ViewFiche } from "./modal/ViewFiche";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import HttpStatus from "../utils/HttpStatus";
import { FaUserCheck } from "react-icons/fa";
import DataContext from "../../context/DataProvider";
import { ApproveRecruit } from "./modal/ApproveRecruit";



export default function Recrut() {
    const { fetchData } = useFetch();
    const { perms, getPerm } = useContext(DataContext)
    const navigate = useNavigate();
    const [showView, setShowView] = useState(false);
    const [showViewApprove, setShowViewApprove] = useState(false);
    const [listRecrut, setListRecrut] = useState([]);
    const [listChoosed, setListChoosed] = useState([]);
    const [jobDescData, setJobDescData] = useState([]);
    const [refresh, setRefresh] = useState([])
    const [allQuota, setAllQuota] = useState([])
    // const [perms, setPerms] = useState([]);

    const handleGetData = async () => {
        const response = await fetchData("POST", "recrut/getRecrut", { type: 111 });
        if (response.status === HttpStatus.OK) {
            setListRecrut(response.data.result.data);
        }
    };


    const getQuota = async () => {
        const response = await fetchData("POST", "recrut/getQuota");
        if (response.status === HttpStatus.OK) {
          setAllQuota(response.data.result.rows)
        } else {
            console.log("Something went wrong");
        }
    };

    const changeStatus = async (id, po, status, quota = null) => {
        const response = await fetchData("POST", "recrut/changeStatusRecrut", {
            id,
            po,
            status,
            quota
        });
        if (response.status === HttpStatus.OK) {
            notifySuccess(
                status === 5
                    ? "Recrut successfully approved"
                    : "Recrut successfully change status"
            );
            handleGetData();
        } else {
            notifyError("Something went wrong");
        }
    };

    const approveRec = async (row) => {
        setListChoosed(row);
        setShowViewApprove(true)
    }

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleView = async (row) => {
        const response = await fetchData('POST', 'recrut/getJobDescData', { id: row.rec_id })
        if (response?.data?.result?.rows)
            setJobDescData(response?.data?.result?.rows)
        setListChoosed(row);

        setShowView(true);
    };

    const handleEdit = (row) => {
        navigate("add_recrut", { state: { action: "Hr", row: row } });
    };

    useEffect(() => {
        handleGetData();
        getPerm();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleGetData();
        getPerm();
        getQuota()
    }, [refresh]);

    return (
        <>
            <MenuTestPage />
            <ViewFiche
                jobDescData={jobDescData}
                setJobDescData={setJobDescData}
                show={showView}
                setShow={setShowView}
                data={listChoosed}
                setData={setListChoosed}
            />

            <ApproveRecruit
                jobDescData={jobDescData}
                setJobDescData={setJobDescData}
                show={showViewApprove}
                setShow={setShowViewApprove}
                data={listChoosed}
                setData={setListChoosed}
                changeStatus={changeStatus}
                refresh={refresh}
                setRefresh={setRefresh}
                allQuota={allQuota}
            />

            <div
                className="col-xl-12 center-block"
                align="center"
                style={{ zIndex: 1 }}
            >
                <div
                    className="col-xl-10 bg-white mt-50 center-block"
                    align="center"
                    style={{ zIndex: 1 }}
                >
                    <Header perms={perms} />
                    <div className="min-h-550px max-h-600px overflow-auto">
                        <table id="customers" className="table ">
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" className="w-100px ">
                                        <div className=" d-flex">
                                            #<i className="la la-filter" onClick={() => { }}></i>
                                        </div>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Demandeur
                                        <i onClick={() => { }} className="la la-filter"></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Date de la demande
                                        <i onClick={() => { }} className="la la-filter"></i>
                                    </th>

                                    <th scope="col" className="text-left">
                                        Branch
                                    </th>

                                    <th scope="col" className="text-left">
                                        Poste
                                    </th>

                                    <th scope="col" className="text-left">
                                        Depatment
                                    </th>
                                    <th scope="col" className="text-left">
                                        Urgence
                                    </th>
                                    <th scope="col" className="text-left">
                                        Status
                                    </th>

                                    <th scope="col" className="text-left">
                                        Nombre d'agent
                                    </th>

                                    <th scope="col" className="text-left">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody id="resultdata">
                                {listRecrut ? (
                                    listRecrut.map((d, index) => {
                                        return (
                                            <tr key={index}>
                                                <td id="tb-assigned-to" className="text-left">
                                                    {d.rec_id}
                                                </td>
                                                <td id="tb-assigned-to" className="text-left">
                                                    {d.e_name}
                                                </td>

                                                <td id="tb-assigned-to" className="text-left">
                                                    {d.rec_askDate}
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    {d.branch_name}
                                                </td>
                                                <td
                                                    id="tb-created-date"
                                                    className={`text-left ${d.rec_askPoste === 0 ? "text-danger" : ""
                                                        }`}
                                                >
                                                    {d.rec_askPoste === 0
                                                        ? d.rec_askPosteText
                                                        : d.po_name}
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    {d.dep_name}
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    {d.rec_askUrgence === 1
                                                        ? "Faible"
                                                        : d.rec_askUrgence === 2
                                                            ? "Moyen"
                                                            : "Elevé"}
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                   <span className={`text-left text-white ${ d.status === 'In Progress' ? 'bg-primary' : d.status === 'Rejected' ? 'bg-danger' : d.status === 'Cancel' ? 'bg-info' : 'bg-success'} p-2 rounded`}> {d.status} </span> 
                                                </td>
                                                <td id="tb-created-date" className="text-left">
                                                    {d.rec_askNbreAgent}
                                                </td>
                                                <td id="tb-created-date" className="d-flex text-left">
                                                    {
                                                        perms['109'] && <FcList
                                                            size={20}
                                                            className="cursor-pointer ml-1"
                                                            onClick={() => {
                                                                handleView(d);
                                                            }}
                                                        />
                                                    }

                                                    {
                                                        d.rec_status === 5 && <div className="d-flex">
                                                            {perms['108'] && (
                                                                <>
                                                                    <FcApproval
                                                                        size={20}
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            // changeStatus(d.rec_id, null, 10);
                                                                            approveRec(d)
                                                                        }}
                                                                    />
                                                                    <FcCancel
                                                                        size={20}
                                                                        className="cursor-pointer"
                                                                        onClick={() => {
                                                                            changeStatus(d.rec_id, d.po_name, 3);
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    }

                                                    {
                                                        (perms['110'] && d.rec_status !== 8 && d.rec_status !== 9) && <PenFill
                                                            style={{ color: "orange" }}
                                                            className="cursor-pointer "
                                                            size={20}
                                                            onClick={() => {
                                                                handleEdit(d);
                                                            }}
                                                        />
                                                    }
                                                    {d.rec_status === 10 && perms['114'] ? (
                                                        <FaUserCheck size={19} className="ml-1 text-blue cursor-pointer" title="Filled" onClick={() => {
                                                            changeStatus(d.rec_id, d.po_name, 8);
                                                        }} />
                                                    ) : null

                                                    }
                                                    {/* {d.rec_status} */}
                                                </td>
                                            </tr>
                                        );
                                        // else return null;
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}
