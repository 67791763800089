import { useEffect, useState } from "react";
import MenuTestPage from "./header_mobile";
import axios from "axios";
import baseurl from "../globals";
import { BiEdit } from "react-icons/bi";
import { FcApproval, FcCancel } from "react-icons/fc";
import toast from "react-hot-toast";

export default function Settings() {
    const [allSettings, setAllSettings] = useState([]);
    const [settingChoosed, setSettingChoosed] = useState(null)
    const [newValue, setNewValue] = useState('')

    const [permissions, setPermissions] = useState({
        permission: {
            perm100: false,
            perm101: false,
            perm102: false,
            perm103: false,
            perm104: false,
            perm105: false,
            perm106: false,
        },
    });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const loadPermissionData = () => {
        axios({
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: baseurl + "/?p=getall_empl_permisssions",
            data: { emp_id: sessionStorage.getItem("id") },
        })
            .then((response) => {
                // setPermissiondata(response.data);

                var prevPerm = permissions;

                var json = response.data;

                Object.keys(json).forEach(function (key) {
                    if (json[key].user_perm_perm_code === 100) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm100 = true;
                            setPermissions(prevPerm);
                        }
                    }

                    if (json[key].user_perm_perm_code === 101) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm101 = true;
                            setPermissions(prevPerm);
                        }
                    }
                    if (json[key].user_perm_perm_code === 102) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm102 = true;
                            setPermissions(prevPerm);
                        }
                    }
                    if (json[key].user_perm_perm_code === 103) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm103 = true;
                            setPermissions(prevPerm);
                        }
                    }
                    if (json[key].user_perm_perm_code === 104) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm104 = true;
                            setPermissions(prevPerm);
                        }
                    }
                    if (json[key].user_perm_perm_code === 105) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm105 = true;
                            setPermissions(prevPerm);
                        }
                    }
                    if (json[key].user_perm_perm_code === 106) {
                        if (json[key].user_perm_value === 1) {
                            prevPerm.permission.perm106 = true;
                            setPermissions(prevPerm);
                        }
                    }
                });
            })
            .catch((error) => {

            });
    };

    const loadDoc = async () => {
        await loadPermissionData();

        await axios.post(baseurl + "/?p=get_all_settings").then((response) => {
            setAllSettings(response.data.list);
        });
    };

    const handleUpdate = () => {
        if (newValue > 0) {
            axios.post(baseurl + "/?p=update_Setting", {
                val: newValue,
                id: settingChoosed
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess('Operation success')
                    setAllSettings(response.data.list);
                    setSettingChoosed(null)
                    setNewValue('')
                } else {
                    notifyError('SOMETHING WENT WRONG')
                }
            });
        } else {
            notifyError('check the field')
        }
    }

    useEffect(() => {
        loadDoc();
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <MenuTestPage />

            <div
                className="col-xl-12 center-block"
                align="center"
                style={{ zIndex: 1 }}
            >
                <div
                    className="col-xl-10 bg-white mt-50 center-block"
                    align="center"
                    style={{ zIndex: 1 }}
                >
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingBottom: '5rem', paddingTop: 50 }} className=" bg-white">
                        {allSettings.map((e, index) => {
                            return (
                                <div key={index} style={{ minHeight: 50, marginRight: 5, marginTop: 5, paddingBottom: 5, minWidth: '24%', background: '#EFF0F0' }}>
                                    <div style={{ fontWeight: 'bold', fontSize: 16, textTransform: 'uppercase', paddingTop: 10 }}>
                                        {e.name}
                                    </div>
                                    <div className="cursor-pointer">
                                        Deadline : {e.value}
                                        {
                                            settingChoosed === e.id ?
                                                <div>
                                                    <form onSubmit={(e) => { e.preventDefault(); handleUpdate() }}>
                                                        <input size={4} autoFocus type="number" style={{ width: 60 }} value={newValue} onChange={(e) => { setNewValue(e.target.value) }} />
                                                        <FcCancel size={20} onClick={() => { setNewValue(''); setSettingChoosed(null) }} />
                                                        <FcApproval size={20} onClick={handleUpdate} />
                                                    </form>
                                                </div>
                                                :
                                                <BiEdit size={20} style={{ color: 'green' }} onClick={() => { setNewValue(e.value); setSettingChoosed(e.id) }} />
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        <div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}