import { useContext, useEffect } from "react";
import TodoList from "../../List.js/TodoList"
import DataContext from "../../../../../context/DataProvider";

export const Second = ({ data, jdData, setJdData, setData, action, error, desc, styleBd, styleBg }) => {
    const { perms, getPerm } = useContext(DataContext)

    const addToList = (value, cat, subcat, recr) => {
        const index = jdData?.findIndex(item => item.jd_cat === 1);
        if (index !== -1 && cat === 1) {
            const updatedData = [...jdData];
            updatedData[index] = {
                ...jdData[index],
                jd_text: value
            };
            setJdData(updatedData);
        }
        else {
            const newItems = [...jdData, { jd_recr: recr, jd_cat: cat, jd_subcat: subcat, jd_text: value }];
            setJdData(newItems);
        }
    }

    const removeFromList = (value) => {
        const { jd_cat, jd_subcat, jd_text } = value

        setJdData(jdData?.filter(x => x.jd_text !== jd_text || x.jd_cat !== jd_cat || x.jd_subcat !== jd_subcat))
    }

    useEffect(() => {
        getPerm()
    }, [])

    return (
        <>
            {data.AskExistingPoste === 0 &&
                <>
                    <tr className={`text-center ${styleBg} `} >
                        <td colSpan="6" className={` text-center font-weight-bolder ${styleBd} fs-16`}>
                            DECISION DES RESSOURCES HUMAINES (cas d'un nouveau poste):
                        </td>
                    </tr>

                    <tr className="text-left ">
                        <td colSpan="3" className={` ${styleBd} fs-14 h-20px`} >Demande d'ajout sur le quota:</td>

                        <td colSpan="2" className={`${styleBd} fs-14`}>
                            <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" className="w-15px h-25px" value={1} checked={data.HrAjoutQuota === 1} onChange={(e) => action !== 'view' && setData(current => {
                                    return ({
                                        ...current,
                                        HrAjoutQuota: parseInt(e.target.value, 10)
                                    })
                                })} /> <span>Approuvée</span>
                            </div>
                        </td>
                        <td colSpan="1" className={` ${styleBd} fs-14 h-20px text-center`}>
                            <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" className="w-15px h-25px" value={0} checked={data.HrAjoutQuota === 0} onChange={(e) => action !== 'view' && setData(current => {
                                    return ({
                                        ...current,
                                        HrAjoutQuota: parseInt(e.target.value, 10)
                                    })
                                })} /> <span>Non Approuvée</span>
                            </div>
                        </td>
                    </tr>

                    {data.HrAjoutQuota === 1 &&
                        <>
                            <tr className={`text-center ${styleBg} `}>
                                <td colSpan="6" className={`text-center font-weight-bolder ${styleBd} fs-16`}>
                                    DESCRIPTION DE POSTE:
                                </td>
                            </tr>

                            <tr className="text-left ">
                                <td colSpan="6" className={`${styleBd} fs-14 h-20px p-0`} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <div style={{ paddingLeft: '0px' }}>
                                        {

                                            desc?.descCategory
                                                ? desc?.descCategory.map((x, index) => {
                                                    const filtered = desc.descSubCategory.filter(y => { return y.sub_cat_id === x.desc_id })
                                                    return <div key={index} className="">
                                                        <div style={{ backgroundColor: '#EBECF3', padding: '10px', fontWeight: 'bold' }}>
                                                            {x.desc_cat_name}
                                                        </div>
                                                        {

                                                            filtered?.length > 0
                                                                ? filtered?.map((z, index) => {
                                                                    return <div key={index}>
                                                                        <div style={{ backgroundColor: '#F5F5F5', padding: '10px', fontWeight: 'bold' }}>
                                                                            {z.sub_value}
                                                                        </div>
                                                                        <TodoList action={action} items={jdData?.filter(g => g.jd_cat === x.desc_id && g.jd_subcat === z.sub_id)} onclick={(e) => { e.action === 'add' ? addToList(e.value, x.desc_id, z.sub_id, data.rec_id) : removeFromList(e.value) }} />
                                                                    </div>
                                                                })
                                                                : x.desc_id === 1 ?
                                                                    <div className="pt-5 pl-15 pr-15">
                                                                        <textarea
                                                                            rows="4"
                                                                            className="w-100 border-0"
                                                                            placeholder="Sommaire ..."
                                                                            value={jdData?.filter(g => g.jd_cat === x.desc_id)[0]?.jd_text}
                                                                            onChange={(e) => {
                                                                                action !== 'view' && addToList(e.target.value, 1, 0, data.rec_id)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="pt-5 pl-15 pr-15">
                                                                        <TodoList action={action} items={jdData?.filter(g => g.jd_cat === x.desc_id)} onclick={(e) => { e.action === 'add' ? addToList(e.value, x.desc_id, 0, data.rec_id) : removeFromList(e.value) }} />
                                                                    </div>
                                                        }
                                                    </div>
                                                })
                                                : null
                                        }
                                    </div>
                                </td>
                            </tr>
                        </>
                    }
                </>
            }

            {(data.AskExistingPoste === 1 && data.AskOrgQuota === 0) &&
                <>
                    <tr className={`text-center ${styleBg} `}>
                        <td colSpan="6" className={`text-center font-weight-bolder ${styleBd} fs-16`}>
                            DECISION DES RESSOURCES HUMAINES (cas d'un poste existant mais hors du quota):
                        </td>
                    </tr>

                    <tr className="text-left ">
                        <td colSpan="3" className={`${styleBd} fs-14 h-20px`} >Demande d'ajout sur le quota:</td>

                        <td colSpan="2" className={`${styleBd} fs-14`}>
                            <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" className="w-15px h-25px" value={1} onChange={(e) => action !== 'view' && setData(current => {
                                    return ({
                                        ...current,
                                        HrAjoutQuotaExistPoste: parseInt(e.target.value, 10)
                                    })
                                })} /> <span>Approuvée</span>
                            </div>
                        </td>
                        <td colSpan="1" className={` ${styleBd} fs-14 h-20px text-center`}>
                            <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <input type="checkbox" className="w-15px h-25px" value={0} onChange={(e) => action !== 'view' && setData(current => {
                                    return ({
                                        ...current,
                                        HrAjoutQuotaExistPoste: parseInt(e.target.value, 10)
                                    })
                                })} /> <span>Non Approuvée</span>
                            </div>
                        </td>
                    </tr>
                </>
            }
            <>
                <tr className={`text-center ${styleBg} `}>
                    <td colSpan="6" className={`text-center font-weight-bolder ${styleBd} fs-16`}>
                        APPRECIATION GENERALE DU DIRECTEUR DES RESSOURCES HUMAINES:
                    </td>
                </tr>

                <tr className="text-left ">
                    <td colSpan="3" className={`${styleBd} fs-14 h-20px`} >Décision globale sur la demande de recrutement:</td>

                    <td colSpan="2" className={`${styleBd} fs-14`}>
                        <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <input type="checkbox" className="w-15px h-25px" value={1} checked={data.HrDecisionGlob === 1} onChange={(e) => action !== 'view' && setData(current => {
                                return ({
                                    ...current,
                                    HrDecisionGlob: parseInt(e.target.value, 10)
                                })
                            })} /> <span>Approuvée</span>
                        </div>
                    </td>
                    <td colSpan="1" className={` ${styleBd} fs-14 h-20px text-center`}>
                        <div className="col-sm" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <input type="checkbox" className="w-15px h-25px" value={0} checked={data.HrDecisionGlob === 0} onChange={(e) => action !== 'view' && setData(current => {
                                return ({
                                    ...current,
                                    HrDecisionGlob: parseInt(e.target.value, 10)
                                })
                            })} /> <span>Non Approuvée</span>
                        </div>
                    </td>
                </tr>
            </>

            <tr className="text-center ">
                <td colSpan="6" className={`${styleBd} fs-14 h-80px`} >
                    Date et Signature du Directeur des RH:
                </td>
            </tr>
        </>
    )
}